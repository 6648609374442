@import '../../../../styles/vendor';

.plans_header {
	width: 59vw; //55rem;
	margin: auto;

	display: grid;
	grid-template-columns: 2rem 1fr 1fr 1fr 1fr;
	margin-bottom: 1rem;

    @include bp(768px down){
        width: 90%;
        display: flex;
        justify-content: center;
    }

	.plan {
		display: grid;
		// grid-auto-rows: 1fr;
		justify-items: center;
        text-align: center;

		.name {
            align-self: flex-end;
			color: black;
			font-size: 1.0625rem;
			font-weight: 500;
			height: 2rem;
		}

		.amount {
			color: black;
			font-size: 0.875rem;
			font-weight: 300;
			padding-bottom: 0.75rem;

			height: 2rem;
			width: 90%;
			text-align: center;
		}
		.underlined {
			border-bottom: 1px solid #d1d1d1;
		}

		.description {
			color: black;
			font-size: 0.625rem;
			font-weight: 300;
			height: 3rem;
			padding-top: 0.8rem;
		}

		.select_button {
            align-self: flex-end;
			display: inline-block;
			width: 6.25rem;
			border-radius: 50px;
			padding: 0.7rem 0;
			border: 1px solid #a3a3a3;
			cursor: pointer;
			height: 3rem;
			text-align: center;
		}
		.active {
			background: #ffd210;
			color: #fff;
			font-weight: 500;
			border: 1px solid #ffd210 !important;
            cursor: default;
		}
        .disabled {
            color: #afafaf;
            background: #f3f3f3;
            border: 1px solid #e1e1e1 !important;
            cursor: not-allowed;
        }
	}
}
