@import "utils";

// ****************************************
// DEVICE LIST //

//## Define the breakpoints at which your layout will change, adapting to different screen sizes.
$screen-xs: 520px !default; // Extra small screen / phone
$screen-sm: 768px !default; // Small screen / tablet
$screen-md: 992px !default; // Medium screen / desktop
$screen-lg: 1200px !default; // Large screen / desktop
$screen-xl: 1440px !default; // Ultra large screen / wide desktop
$screen-xx: 1920px !default; // Ultra large screen / wide desktop
$screen-xy: 2100px !default; // Ultra large screen / wide desktop

$breakpoints: (
    ss: 0,
    xs: $screen-xs,
    sm: $screen-sm,
    md: $screen-md,
    lg: $screen-lg,
    xl: $screen-xl,
    xx: $screen-xx,
    xy: $screen-xy
);

$breakpoint-names: (sm, md, lg);

@function breakpoint($val) {
    $bp: nth($val, 1);
    $bp-max: 0;
    $dir: if(length($val) > 1, nth($val, 2), up);
    $str: '';
    $named: false;

    @if $bp == 'landscape' or $bp == 'portrait' {
        @return '(orientation: #{$bp})';
    }

    @if type-of($bp) == 'string' {
        @if map-has-key($breakpoints, $bp) {
            @if $dir == 'only' or $dir == 'down' {
                $bp-max: map-next($breakpoints, $bp);
            }

            $bp: map-get($breakpoints, $bp);
            $named: true;
        }
        @else {
            $bp: 0;
            @warn 'breakpoint(): "#{$val}" is not defined in your $breakpoints setting.';
        }
    }

    //$bp: bp-to-em($bp);
    //@if $bp-max {
    //    $bp-max: bp-to-em($bp-max) - (1/16);
    //}

    @if $bp > 0px or $dir == 'only' or $dir == 'down' {
        @if $dir == 'only' {
            @if $named == true {
                @if $bp > 0px {
                    $str: $str + '(min-width: #{$bp})';

                    @if $bp-max != null {
                        $str: $str + ' and ';
                    }
                }

                @if $bp-max != null {
                    $str: $str + '(max-width: #{$bp-max})';
                }
            }
            @else {
                @warn 'breakpoint(): Only named media queries can have an `only` range.';
            }
        }

        @else if $dir == 'down' {
            $max: if($named, $bp-max, $bp);

            @if $named or $bp > 0px {
                @if $max != null {
                    $str: $str + '(max-width: #{$max})';
                }
            }
        }

        @else if $bp > 0px {
            $str: $str + '(min-width: #{$bp + 1})';
        }
    }

    @return $str;
}

@mixin bp($value) {
    $str: breakpoint($value);

    @if $str == '' {
        @content;
    }

    @else {
        @media screen and #{$str} {
            @content;
        }
    }
}

@mixin show-for($size) {
    $size: map-get($breakpoints, $size);
    //$size: $size - (1 / 16);

    @include bp($size down) {
        display: none !important;
    }
}

@mixin show-for-only($size) {
    $lower-bound-size: map-get($breakpoints, $size);
    $upper-bound-size: map-next($breakpoints, $size);

    $lower-bound: bp-to-em($lower-bound-size) - (1 / 16);
    @if strip-unit($lower-bound-size) == 0 {
        $lower-bound: bp-to-em($lower-bound-size);
    }

    @if $upper-bound-size == null {
        @media screen and (max-width: $lower-bound) {
            display: none !important;
        }
    }
    @else {
        $upper-bound: bp-to-em($upper-bound-size);

        @media screen and (max-width: $lower-bound), screen and (min-width: $upper-bound) {
            display: none !important;
        }
    }
}

@mixin hide-for($size) {
    @include bp($size) {
        display: none !important;
    }
}

@mixin hide-for-only($size) {
    @include bp($size only) {
        display: none !important;
    }
}

@each $size in $breakpoint-names {
    @if $size != nth($breakpoints, 1) {
        .hide-#{$size} {
            @include hide-for($size);
        }

        .show-#{$size} {
            @include show-for($size);
        }
    }

    .hide-#{$size}-only {
        @include hide-for-only($size);
    }

    .show-#{$size}-only {
        @include show-for-only($size);
    }
}

.show-for-landscape,
.hide-for-portrait {
    display: block !important;

    @include bp(landscape) {
        display: block !important;
    }

    @include bp(portrait) {
        display: none !important;
    }
}

.hide-for-landscape,
.show-for-portrait {
    display: none !important;

    @include bp(landscape) {
        display: none !important;
    }

    @include bp(portrait) {
        display: block !important;
    }
}
