@import "../../utils";
@import "../../breakpoints";

.reviews {
    &__header {
        background: #f3f3f3;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__content {
        background: white;
        border-top: 1px solid #c4c4c4;
    }

    &__headline {
        font-size: 0.875rem;
    }

    &__button {
        border-color: #ffd210;
        background: #ffd210;
        color: black;
        position: absolute;
        right: 0;
        bottom: 0;
        transform: translateY(50%);

        &:hover {
            background-color: white;
        }
    }

    &__rating {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
        border-bottom: 1px solid #c4c4c4;

        @include bp(992px down) {
            flex-direction: column;
        }
    }

    &__stars {
        width: 100%;
    }

    &__stats {
        flex: 25%;
        
        @include bp(992px up) {
            min-width: 320px;
            margin-right: 1.5rem;
        }
        

        .stats-header {
            padding: 1.5rem 0;
            display: flex;
            border-bottom: 1px solid #c4c4c4;
    
            .review_stars {
                flex-basis: 200px;
                text-align: center;
                padding: 0.5rem 0;
                p {
                    margin: 0
                }
            }
            .reviews {
                flex-basis: 110px;
                text-align: center;
                border-left: 1px solid #c4c4c4;
    
                .counter {
                    font-size: 1.75rem;
                    font-weight: 500;
                    margin: 0;
                }
                .footer {
                    font-size: 1rem;
                    margin: 0;
                }
    
            }
        }
        
        .rating-hint-text {
            font-size: 0.875rem;
            margin-top: 1rem;
            text-align: center;
            color: #c4c4c4;
        }
    }

    &__last {
        flex: 75% 1 1;
        padding: 1.5rem 0 1.5rem 1.5rem;
        border-left: 1px solid #c4c4c4;

        @include bp(992px down) {
            border-left: unset;
            border-top: 1px solid #c4c4c4;
            padding: 1.5rem 0;
        }

        .title {
            font-size: 1.75rem;
            font-weight: 500;
        }

        .article-wrapper {
            display: flex;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: flex-start;

            .user-review {
                margin: 0;
                padding-right: 20px;
                width: auto;
            }

            @include bp(520px down) {
                flex-direction: column;
                .user-review {
                    padding-bottom: 1.5rem;
                }
            }
        }
    }

    &__all {
        text-align: center;
        padding-bottom: 1rem;
        min-height: 10rem
    }

    &__type {
        text-align: left;
    }

    &__read-all {
        cursor: pointer;
        display: inline-block;
        font-size: 1rem;
        font-weight: 600;
        margin: 30px 0;
        &:hover {
            color: #dd4b39;
        }
    }

    &__copy {
        font-size: 1.75rem;
        font-weight: 500;
	}
}

.user-review {
    width: 100%;
    margin-top: 20px;

    &__rating {
        margin-bottom: 10px;
        i, .fa {
            font-size: 1rem;
            color: #ffd210;
            margin-right: 5px;
        }
    }

    &__title,
    &__content,
    &__read-more,
    &__info,
    &__description {
        font-size: 0.875rem;
        // @include tabletFontSize(1.3rem);
        // @include phoneFontSize(.7rem);

    }

    &__title {
        font-weight: 600;

        &.star-inline {
            margin-top: 3px;
            margin-left: 1rem;
        }
    }

    &__content {
        font-weight: 300;
    }

    &__read-more {
        cursor: pointer;
        color: #ffd210;
        font-weight: 500;
    }

    &__footer {
        display: flex;
        align-items: center;
        margin-top: 0.5rem;

        .reviewer-pic {
            min-width: 2.167rem !important;
            width: 2.167rem !important;
            height: 2.167rem !important;

            img {
                width: 2rem !important;
                height: 2rem !important;
            }
        }

        .footer-info {
            display: flex;
            flex-direction: column;

            .reviewer {
                font-size: 0.75rem;
            }
            .created {
                font-size: 0.75rem;
                color: #c4c4c4;
            }
        }
    }

    &__info {
        margin: 1rem 0;
    }
}
