@import "styles/vendor";

.CompareModal {
    border: none;
    button.close {
        font-size: 2.5rem;
        font-weight: 300;
        opacity: .9;
        color: #fff;
        &:hover {
            font-size: 2.5rem;
            font-weight: 300;
            opacity: 1;
        }
    }
    &.two {
        max-width: 90vw;
    }
    &.three {
        max-width: 96vw;
    }
    &.suggested-off {
        &.two {
            max-width: 90vw;
            @include bp(1200px up) {
                max-width: rem(1000);
            }
            .SelectedProfile, .suggestedProfilesList {
                width: calc(100% / 3);
            }
        }
        &.three {
            max-width: 96vw;
            @include bp(1200px up) {
                max-width: rem(1200);
            }
            .SelectedProfile, .suggestedProfilesList {
                width: calc(100% / 4);
            }
        }
    }
    .modal-content {
        background-color: transparent;
        border: none;
        .modal-header {
            //height: sr(4*16, 1.5);
            width: 100%;
            //top: sr(-5*16, 1.5);
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: sr(48, 1.5);
            font-weight: $font-regular;
            color: white;
            background-color: transparent;
            border: none;
            .title {
                display: flex;
                flex-direction: row;
                align-items: center;
                img {
                    width: 2.5rem;
                }
                h1 {
                    margin-left: 1.1rem;
                }
            }
            .close {
                top: 4rem;
                position: relative;
                z-index: 1;
            }
        }
        .modal-body {
            padding: 0;
            background-color: #ffffff;
            background-clip: padding-box;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 0.3rem;
            outline: 0;
            .compare-content {
                height: 100%;
                &-wrapper {
                    color: black;
                    .selected-profiles-block {
                        max-width: 100%;
                        display: flex;
                        flex-direction: row;
                        flex-grow: 1;
                        
                        .SelectedProfile {
                            &:last-child {
                                border: none;
                            }
                        }
                        .profile__pic-wrapper {
                            width: sr(40, 1.5);
                            height: sr(40, 1.5);
                        }
                        .about {
                            border: none;
                        }
                    }
                }
            }
        }
    }

    //TODO
    .profile__pic-wrapper {
        width: sr(40, 1.5);
        height: sr(40, 1.5);
    }
}


