// ****************************************
// COLORS //
// variables
$light-gray: #f3f3f3;
$gray: #d1d1d1;
$gray-hue-1: #c4c4c4;
$gray-hue-2: #a3a3a3;
$gray-hue-3: #929292;
$yellow: #ffd210;
$orange: #fee17c;
$blue: #3b5998;
$light-blue: #0077b5;
$red: #db3236;
$green: #47b535;
$border-color: $gray-hue-1;

// ****************************************
// TYPOGRAPHY //
// variables
$baseFontSize: 16;
$font-gray: $gray-hue-2;

//FONT FAMILY
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700');

//functions
@function rem($pixels, $context: $baseFontSize) {
	@if (unitless($pixels)) {
		$pixels: $pixels * 1px;
	}

	@if (unitless($context)) {
		$context: $context * 1px;
	}

	@return $pixels / $context * 1rem;
}

$defaultScale: 2;
@function sr($val, $sc: $defaultScale) {
	@return rem($val) / $sc;
}

@function vw($val) {
	@return ($val / 2880) * 100vw;
}

$font-thin: 100;
$font-extra-light: 200;
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semi-bold: 600;
$font-bold: 700;
$font-extra-bold: 800;
$font-black: 900;

@function map-next($map, $key) {
	$values: map-keys($map);
	$i: 0;

	@if (map-has-key($map, $key)) {
		$i: index($values, $key) + 1;
	}

	@if ($i > length($map) or $i == 0) {
		@return null;
	} @else {
		@return map-get($map, nth($values, $i));
	}
}

@function strip-unit($num) {
	@return $num / ($num * 0 + 1);
}

@function to-rem($value, $base: $baseFontSize) {
	@if type-of($value) != 'number' {
		@warn inspect($value) + ' was passed to rem-calc(), which is not a number.';
		@return $value;
	}

	@if unit($value) == 'em' {
		$value: strip-unit($value) * 1rem;
	}

	@if unit($value) != 'rem' {
		$value: strip-unit($value) / strip-unit($base) * 1rem;
	}

	@if $value == 0rem {
		$value: 0;
	}

	@return $value;
}

@function rem-calc($values, $base: null) {
	$rem-values: ();
	$count: length($values);

	@if $base == null {
		$base: $baseFontSize;
	}

	@if unit($base) == '%' {
		$base: ($base / 100%) * 16px;
	}

	@if unit($base) == 'rem' {
		$base: strip-unit($base) * 16px;
	}

	@if $count == 1 {
		@return to-rem($values, $base);
	}

	@for $i from 1 through $count {
		$rem-values: append($rem-values, to-rem(nth($values, $i), $base));
	}

	@return $rem-values;
}

@function bp-to-em($value) {
	@if unit($value) == 'px' or unitless($value) {
		$value: rem-calc($value, $base: 16px);
	}
	@return strip-unit($value) * 1em;
}

@function unpack-shorthand($shorthand) {
	@if length($shorthand) == 1 {
		@return nth($shorthand, 1) nth($shorthand, 1) nth($shorthand, 1) nth($shorthand, 1);
	} @else if length($shorthand) == 2 {
		@return nth($shorthand, 1) nth($shorthand, 2) nth($shorthand, 1) nth($shorthand, 2);
	} @else if length($shorthand) == 3 {
		@return nth($shorthand, 1) nth($shorthand, 2) nth($shorthand, 3) nth($shorthand, 2);
	} @else {
		@return $shorthand;
	}
}

@function is-length($value) {
	@return type-of($value) != 'null' and
		(
			str-slice($value + '', 1, 4) == 'calc' or index(auto inherit initial 0, $value) or
				(type-of($value) == 'number' and not(unitless($value)))
		);
}

@mixin size($width: 100%, $height: $width) {
	height: $height;
	width: $width;
}

@mixin position($pos, $box-edge-values) {
	$box-edge-values: unpack-shorthand($box-edge-values);
	$offsets: (
		top: nth($box-edge-values, 1),
		right: nth($box-edge-values, 2),
		bottom: nth($box-edge-values, 3),
		left: nth($box-edge-values, 4),
	);

	@if $pos != null {
		position: $pos;
	}

	@each $offset, $value in $offsets {
		@if is-length($value) {
			#{$offset}: $value;
		}
	}
}

@mixin pseudo($display: block, $pos: absolute, $content: '') {
	content: $content;
	display: $display;

	@if $pos != null {
		position: $pos;
	}
}

@function fontStyle() {
	// font: [font-style||font-variant||font-weight] font-size [/line-height] font-family | inherit
	$str: '';
}

@mixin font($value) {
	font: fontStyle($value);
}

@mixin tabletFontSize($fs) {
	@include bp(768px down) {
		font-size: $fs;
	}
}

@mixin phoneFontSize($fs) {
	@include bp(520px down) {
		font-size: $fs;
	}
}
