@import "../../utils";
@import "../../breakpoints";

$pic-size: 8rem;

.profile {
    min-width: 20.5rem;
    width: calc(17.311183% + 25px);
    position: relative;
    text-align: center;
    //margin-bottom: -12rem;
    left: -25px;
    z-index: 1;
    bottom: 25px;
    @include bp(sm down) {
        min-width: unset;
        width: 100%;
        left: 0;
        top: -20px;
        margin-bottom: 40px;
    }

    @media only screen
    and (min-device-width: 768px)
    and (max-device-width: 1024px)
    and (-webkit-min-device-pixel-ratio: 2) {
        top: -4vh;
        margin-bottom: -3.5vh;
    }

    @media only screen and (min-width : 1440px) {
        //top: 8.5rem;
    }

    @media only screen and (min-width: 1600px) {
        //top: -7.5rem;
    }
    .DataRangePicker {
        .rdr-DateRange {
            border-top: 0 !important;
        }
        &__bottom {
            display: none;
        }
    }
    .actions-wrapper {
        display: flex;
        height: 65px;
        flex-direction: row;
        justify-content: center;
        margin: 0 20px;
        padding: 0.7rem 0;
        border-top: 1px solid #d1d1d1;
        border-bottom: 1px solid #d1d1d1;

        .venue-right-button {
            border: none;
            background: none;
            width: 33%;
        }

        .venue-center-fee {
            border: none;
            background: none;
            width: 33%;
            border-left: 1px solid #d1d1d1;
        }

        .venue-left-button {
            border: none;
            background: none;
            width: 33%;
            border-left: 1px solid #d1d1d1;
        }
    }
    &:after {
        clear: both;
        content: "";
        display: table;
    }

    &__bg {
        padding-top: 6rem;
        background: #fff;
        // border: 1px solid $light-gray;
        border-radius: 16px;

        @include bp(sm up){
            margin-bottom: 2rem;
        }

        @include bp(768px down){
            margin-right: 0;
            margin-left: 0;
            padding-left: 2rem;
            padding-top: 3rem;
            padding-right: 2rem;
            border-bottom: 1px solid rgba(0,0,0,0);
        }
        @include bp(520px down){
            padding-left: 0rem;
            padding-right: 0rem;
        }

        .toggle-button {
            // position: absolute;
            // top: 20px;
            // right: 60px;
            width: 90%;
            margin: 0 auto;
            border: 1px solid #fed134;
            border-radius: 4px;

            span {
                padding: 8px 0;
            }
        }
    }

    &__header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transform: translateY(-50%);

        &.mobile {
            transform: translateY(-75%);
        }
    }
    .SuccessfulGigs {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 20px 20px 0;
        & > * {
            margin-right: .5rem;
        }
        .underline {
            margin-right: 0;
            margin-top: 20px;
        }
    }
    &__ribbon {
        width: 75px;
        position: absolute;
        top: 75%;
        left: 0;
        transform: translate(-2px, -2px);
        image-rendering: -webkit-optimize-contrast;
    }

    &__pic {
        @include size($pic-size);
        margin: 0 auto;
        border-radius: 50%;
        display: block;
        position: relative;
        border: 3px solid #fff;



         //@include bp(sm only) {
         //    @include size($pic-size * .8);
         //}
         @include bp(xs down) {
             @include size($pic-size * .75);
         }
         //@include bp(768px down){
         //    height: 12.8rem;
         //    width: 12.8rem;
         //    margin-left: 3.1rem;
         //}
         //@include bp(520px down){
         //    height: 6.8rem;
         //    width: 6.8rem;
         //    margin: 0px;
         //}

        &:after {
            content: " ";
            height: 150%;
            width: 150%;
            background: url(/assets/images/profile-bg.png) no-repeat center top/100% auto;
            position: absolute;
            top: -25%;
            left: -25%;
        }

        img {
            display: block;
            border-radius: 50%;
            height: 100%;
            object-fit: cover;
            -webkit-backface-visibility: hidden;

            &.profile__pic-decorator {
                position: absolute;
                left: -35%;
                top: -40%;
                height: calc(100% + 30%);
                width: calc(100% + 70%);
                max-width: none;
                object-fit: unset;
            }
        }

        &-hover:hover {
            opacity: 1;
        }

        &-circle {
            height: 100%;
            width: 100%;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.5);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0;
            transition: .22s;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            &-wrapper {
                max-width: $pic-size;
                border-radius: 50%;
                margin: 0 auto;
                position: relative;
                z-index: 9;

                &.mobile {
                    margin-left: 0.5rem;
                }
            }
            &:hover .profile__edit {
                opacity: 1;
            }
        }
    }

    &__edit {
        width: auto;
        font-size: 0.75rem;
        color: #fff;
        text-decoration: none;
        z-index: 2;
        opacity: 0;
        transition: .22s;
        &:hover {
            color: $yellow;
        }
        span {
            font-size: 0.9375rem;
        }
        i {
            font-size: 1.75rem;
            margin-bottom: 5px;
        }
    }

    &__share {
        float: right;
        margin: 20px 15px 10px 0;
        position: relative;
    }


    &-avatar {
        opacity: 0.99;
    }

    &__rating {
        clear: both;
        color: #FED134;

        @media only screen
        and (min-device-width: 768px)
        and (max-device-width: 1024px)
        and (-webkit-min-device-pixel-ratio: 2) {
            margin-top: 20px;
        }

        &.mobile {
            text-align: left;
            padding-left: 20px;
            margin-bottom: 0.5rem;
        }

        span.overall {
            color: #000;
            font-size: 12px;
            font-weight: 100;
            @include tabletFontSize(1.2rem);
            @include phoneFontSize(.9rem);
        }
    }

    &__data {
        .name {
            overflow-x: hidden;
            text-overflow: ellipsis;

            font-size: 1.5rem;
            font-weight: $font-black;
            margin: .5em 0 .2rem;
            padding: 0 20px;
        }

        .categorie-name {
            font-size: 1rem;
            font-weight: $font-medium;
            margin: 0.2em 0 0.8em;
            padding: 0 20px;
        }

        &.mobile {
            position: absolute;
            top: -5px;
            left: 11rem;
            right: 0;
            transform: translateY(-100%);

            .name {
                color: white;
                font-size: 1rem;
                text-align: left;
                padding: 0;
            }

            .categorie-name {
                color: white;
                font-size: 0.8rem;
                text-align: left;
                padding: 0;
            }
        }
    }


    &__talents, &__occassions {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        font-size: 0.75rem;
        font-weight: $font-medium;
        color: #000;
        padding: 0 rem(20);
        @include bp(520px down){

        }
        .talent, .occassion {
            margin: 0 5px;
            border: 1px solid $gray;
            border-radius: 10px;
            padding: .15rem .7rem;
        }
    }

    &__type {
        margin-top: 0.8rem;
        margin-bottom: 0.8rem;
        font-weight: $font-medium;
        padding: 0 20px;
        font-size: 0.75rem;
        i.fa-map-marker {
            font-size: 1.2rem;
            margin-right: 0.5rem;
        }
    }

    &__tags {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 1rem;

        a {
            display: inline-block;
            text-decoration: none;
            font-size: 0.625rem;
            color: rgba(0, 0, 0, 0.8);
            background: rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            padding: 4px 9px 2px;
            margin: 0 8px;

            &:hover {
                background: #e4222e;
                color: #fff;
            }
        }
    }
    &__mobile_actions {

        position: relative;
        /* display: inline-block; */
        width: 100%;
        padding: 10px 0;
        margin-bottom: 0;
        flex-wrap: inherit;
        justify-content: center;
        text-align: center;
        display: inline-flex;

        .action_button_label {
            font-size: 12px;
        }
    }
    &__actions {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        flex-wrap: wrap;
        div {
            line-height: 1rem;
        }
        b {
            font-size: 1.4rem;
        }
        a {
            margin: 0 12px;
        }
        .underline {
            border-bottom: 1px solid $gray;
            width: 100%;
        }
        .profile__actions_clap, .profile__actions_follow {
            width: 50%;
            cursor: pointer;
            margin: .7rem 0;
            display: flex;
            justify-content: space-around;
            padding: 1rem 0 1rem 1rem;
            align-items: center;
        }
        .profile__actions_clap {
            border-right: 1px solid $gray;
        }
    }
    .occassions {
        border-bottom: 1px solid #e1e1e1;
        padding: 1rem;

        p {
            font-size: 0.875rem;
            font-weight: bold;
            text-align: left;
        }
    }

    .location {
        padding: 0 20px;
        font-size: .75rem;
        @include tabletFontSize(1.3rem);
        @include phoneFontSize(.8rem);

        i.fa-map-marker {
            font-size: 1.2rem;
            margin-right: .5rem;
        }
        & > div {
            padding: 15px 10px;
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .profile__travel {
            width: 50%;
            @include tabletFontSize(1.3rem);
            @include phoneFontSize(.8rem);
        }
    }
    &__travel {
        font-size: 0.75rem;
        padding: 15px 0;
        display: flex;
        justify-content: center;
        .circle {
            flex: 0 0 auto;
            border-radius: 50%;
            border: 1px solid #000;
            width: 1.5rem;
            height: 1.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 0.5rem;
            .fa {
                margin: 0;
                width: 1rem;
                height: 1rem;
                font-size: 1rem;
                transform: translateY(3px);
            }
        }
        i {
            font-size: 200%;
            margin-left: 10px;
        }
    }

    &__prices {
        margin: 0 20px;
        p {
            width: percentage(1 / 3);
            padding: 10px 0;
            margin-bottom: 0;
            // @include tabletFontSize(1.2rem);
            // @include phoneFontSize(.6rem);
            // &:first-of-type b {
            //     font-size: 0.875rem;
            //     @include tabletFontSize(1.7rem);
            //     @include phoneFontSize(1rem);
            // }
            & + p {
                border-left: 1px solid #e1e1e1;
            }
        }
        & > div {
            border-bottom: 1px solid #e1e1e1;
            border-top: 1px solid #e1e1e1;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
        }
        .currency-text, .price-list {
            font-size: 0.875rem;
            b {
                font-weight: 700;
                font-size: 1rem;
            }
        }
        small {
            font-size: 30%;
        }
        p > a {
            width: 100%;
            display: inline-block;
            padding: 15px 0;
            justify-content: center;
            align-items: center;
            text-align: center;
            &:hover {
                cursor: pointer;
                color: #e4222e;
            }

            i {
                margin-right: 5px;
                font-size: 150%;
            }
        }
    }
    &__stats {
        margin: 0 20px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e1e1e1;
        & > div {
            width: 50%;
            b {
                font-weight: 400;
                font-size: 0.9375rem;
            }
        }
        &:first-child {
            border-right: 1px solid $gray;
        }
    }
    // .skylight-dialog {
    //   @include bp(768px down){
    //       left: 0 !important;
    //       top: 0 !important;
    //       margin-top: 69px !important;

    //       width: 100% !important;
    //       height: 100% !important;
    //       margin-left: 0 !important;
    //   }
    // }
}

