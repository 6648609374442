@import "styles/utils";
@import "styles/breakpoints";
@import "styles/placeholders";

.NavBar__dropdown {
	position: relative;
	// &:hover span {
	// 	background: $yellow;
    // }
    
    .icon-wrapper {
        padding-right: 1rem;
        display: none;

        @include bp(519px down) {    
            display: flex;
            justify-content: end;
        }
    }
    .mobile-item {
        display: none;
        @include bp(768px down){
            display: block;
        }
    }
	.NavBar__dropdown-item {
		text-align: right;
		font-weight: $font-light;
		display: block;
		width: 100%;
		padding: 0.25rem 1.5rem;
		clear: both;
		color: #fff;
		font-weight: 500;
		white-space: nowrap;
		background: none;
		border: 0;

		&:hover {
			color: $yellow;

			a {
				color: $yellow; 
			}
		}
	}
}

.Navbar__Wrapper {
	background-color: #000;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	box-shadow: 0 0 15px rgba(0,0,0,.5);
}

.Navbar {
	height: 75px;
	background-color: #000;
	
	display: flex;
	align-items: center;

	padding: 0 12px;
	
	max-width: calc(100% - 24px);
	width: 100%;
	margin: auto;

	.ButtonLinkGroup {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}

	@include bp(md down) {
		.Navbar__LogoWrapper {
			margin-right: auto;
		}

		.SearchStuffWrapper {
			display: none;
		}

		.ButtonLinkGroup {
			position: static;
			transform: none;
			margin: auto;
		}
	}

	@include bp(xs down) {
		.ButtonLinkGroup {
			display: none;
		}
	}
}

.ButtonLinkGroup {
	display: flex;
	height: 100%;
	font-size: 1rem;

	@include bp(md down) {
		//font-size: .8rem;
	}

}

.ButtonLink {
    display: flex;
    align-items: center;
    
    color: #fff;
    font-weight: 600;
    font-size: inherit;
    border-bottom: 3px solid rgba(0, 0, 0, 0);
    padding: 0 16px;
    @include bp(520px down) {
        padding: 0 8px;
    }

    &:hover, &:focus {
        color: #fed135;
    }

    &.active {
        border-bottom: 3px solid #fed135;
    }
}	



.CreateNewGigButton {
	cursor: pointer;
	transition: 0.22s;
	color: #000000;
	height: 35px;
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #fff;
	border-radius: 50px;
	padding: 0 1.3rem;
	margin-right: 12px;
	font-weight: 500;
	white-space: nowrap;
	font-size: 1rem;

	&:hover, &:focus {
		color: #fff;
		background-color: transparent;
	}
}

.IconButton {
	&__Icon {
		color: #000;

		width: 23px;
		height: 17px;

		&:hover {
			svg {
				fill: $yellow;
			}
		}
	}

	.ant-badge {
		width: 100%;
	}

	.ant-badge-count {
		box-shadow: none;
	}
	
}