.media-block{
    background-color: #ffffff;
    width: 100%;

    height: 100%;
    position: relative;

    .media-player {
        width: 100%;
    }

    .overlay{
        position: absolute;
        height: 100%;
        width: 100%;
        .overlay-wrapper {height: 100%;}
        .media-description{
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            z-index: 1;
            padding: calc(30rem / 40);
            &.dark {
                position: relative;
                //height: 100%;
                /*background: rgba(0,0,0, .5);*/
                color: white;
                &.audio {
                    background: transparent;
                }
                .media-description-close {
                    position: absolute;
                    color: white;
                    font-size: 26px;
                    top: 15px;
                    cursor: pointer;
                    right: 15px;
                }
                .description-add-comment {
                    display: flex;
                    flex-direction: column;
                    .fa {
                        font-size: 18px;
                        margin-right: 6px;
                    }
                }
                .description-add-comment-input {
                    flex-grow: 1;
                    border: none;
                    border-bottom: 1px solid white;
                    font-size: 14px;
                    color: white;
                    outline: none;
                    background: transparent;
                    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                        color: white;
                    }
                    &::-moz-placeholder { /* Firefox 19+ */
                        color: white;
                    }
                    &:-ms-input-placeholder { /* IE 10+ */
                        color: white;
                    }
                    &:-moz-placeholder { /* Firefox 18- */
                        color: white;
                    }
                }
            }
            .description-text{
                font-size: 18px;
                color: white;
                .semi-bold{
                    font-weight: 600;
                }
                .description-text-thanks {color: #bfbfbf; font-size: 11px;}
            }
        }
        .activity-wrapper{
            // z-index: 9;
            position: absolute;
            display: flex;
            flex-direction: row;
            padding:  0 0.5rem 0.5rem;
            width: 100%;
            bottom: 0;
            background: rgba(248,80,50,0);
            background: -moz-linear-gradient(top, rgba(248,80,50,0) 0%, rgba(0,0,0,1) 100%);
            background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(248,80,50,0)), color-stop(100%, rgba(0,0,0,1)));
            background: -webkit-linear-gradient(top, rgba(248,80,50,0) 0%, rgba(0,0,0,1) 100%);
            background: -o-linear-gradient(top, rgba(248,80,50,0) 0%, rgba(0,0,0,1) 100%);
            background: -ms-linear-gradient(top, rgba(248,80,50,0) 0%, rgba(0,0,0,1) 100%);
            background: linear-gradient(to bottom, rgba(248,80,50,0) 0%, rgba(0,0,0,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f85032', endColorstr='#000000', GradientType=0 );
            .activity{
                color: #ffffff;
                font-size: 13px;
                font-weight: 400;
                margin: 0 0.5rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: pointer;
                .SVGInline{
                    margin-top: 3px;
                }
            }
            .activity.share{
                margin-left: auto;
            }
            .activity.add-comment {
                display: flex;
                color: white;
                p {
                    margin: auto;
                    cursor: pointer;
                    border-bottom: 1px solid white;
                }
            }
        }

        &.audio {
            bottom: 15px;
            height: auto;
            .activity-wrapper {
                align-items: center;
            }
        }
    }

    .comments-container {
        margin-top: 10px;
        overflow-y: auto;
    }

    .input-comment-container {
        display: flex;
        flex-shrink: 0;
    }

    .comment {
        margin-top: 10px;
        display: flex;

        & > img {
            width: 30px;
            height: 30px;
            margin-right: 12px;
            border-radius: 100%;
            object-fit: cover;
        }

        .name, .date {
            font-size: 0.85rem;
        }

        .name {
            margin-right: 10px;
        }

        .date {
            color: #9b9b9b;
        }
    }
}
