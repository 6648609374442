@import '../../../../styles/vendor';

.plans_list {
	width: 59vw; //55rem;
	margin: auto;
    @include bp(768px down){
        width: 90%;
        word-break: break-all;
        font-size: .9rem;
    }
    @include bp(520px down){
        font-size: .6rem;

    }

	.group {
		min-height: 9rem;
		// width: 200px;
		// border: 1px solid #ddd;
		// background-color: #eee;
		display: flex;
	}
	.title {
		// background-color: #1976d2;
		flex: 0 0 35px;
		min-width: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 2rem;
		margin: 1px;

		&-profile {
			border-right: 4px solid #ffd210;
		}

		&-media {
			border-right: 4px solid #e31e25;
		}
		&-account {
			border-right: 4px solid #11688e;
		}
		&-secure_payment {
			border-right: 4px solid #000;
		}
	}
	.title > span {
		color: #000;
		font-size: 1.1rem;
		transform: rotate(-90deg);
		white-space: nowrap;
	}
	.values {
		flex: 1;

		.plans_row {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			height: auto;
			justify-items: center;
			align-items: center;
			border: 1px solid #ffffff;
			
			& > div {
				height: 100%;
				width: 100%;
				text-align: center;
				display: grid;
				align-items: center;
			}

			.label {
				background-color: #fff;	
				.cell {
					justify-content: flex-start;
					padding-left: 1rem;
					text-align: left;
				}
			}

			.free {
				background-color: #f3f3f3;

				.cell {
					color: #afafaf;
				}
			}

			.pro {
				background-color: #e1e1e1;

				.cell {
					color: #a2a2a2;
				}
			}

			.premium {
				background-color: #c2c2c2;

				.cell {
					color: #fefefe;
				}
			}

			.cell {
				padding-top: .75rem;
				padding-bottom: .75rem;
				border: 1px solid #ffffff;
				border-top: none;
				border-bottom: none;
				height: 100%;
				width: 100%;
				display: grid;
				justify-content: center;
				align-content: center;
                @include bp(768px down){
                    padding: 7px;
                }
			}
		}
	}
}
