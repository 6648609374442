@import "styles/vendor";

.InlinePreLoader {
    display: inline-block;
    padding: 0 rem(20);
    position: relative;
    @include bp(768px down){
        top: 0px;
        right: 5px;
    }
    @include bp(ss up){
        top: -17px;
    }
    @include bp(sm up){
        top: 0;
    }
    left: 0;
    .dotAnimation {
        display: flex;
        .dot1 {
            @extend .dot;
            left: -15px;
            float: left;
            -webkit-animation-name: scale2;
            -webkit-animation-timing-function: linear;
            -webkit-animation-iteration-count: infinite;
            -webkit-animation-duration: 0.6s;
            -webkit-animation-delay: 0.4s;
            -webkit-animation-direction: alternate;

            -moz-animation-name: scale2;
            -moz-animation-timing-function: linear;
            -moz-animation-iteration-count: infinite;
            -moz-animation-duration: 0.6s;
            -moz-animation-delay: 0.4s;
            -moz-animation-direction: alternate;

            -ms-animation-name: scale2;
            -ms-animation-timing-function: linear;
            -ms-animation-iteration-count: infinite;
            -ms-animation-duration: 0.6s;
            -ms-animation-delay: 0.4s;
            -ms-animation-direction: alternate;

            -o-animation-name: scale2;
            -o-animation-timing-function: linear;
            -o-animation-iteration-count: infinite;
            -o-animation-duration: 0.6s;
            -o-animation-delay: 0.4s;
            -o-animation-direction: alternate;

            animation-name: scale2;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-duration: 0.6s;
            animation-delay: 0.4s;
            animation-direction: alternate;
        }
        .dot2 {
            @extend .dot;
            float: left;
            -webkit-animation-name: scale2;
            -webkit-animation-timing-function: linear;
            -webkit-animation-iteration-count: infinite;
            -webkit-animation-duration: 0.6s;
            -webkit-animation-direction: alternate;

            -moz-animation-name: scale2;
            -moz-animation-timing-function: linear;
            -moz-animation-iteration-count: infinite;
            -moz-animation-duration: 0.6s;
            -moz-animation-direction: alternate;

            -ms-animation-name: scale2;
            -ms-animation-timing-function: linear;
            -ms-animation-iteration-count: infinite;
            -ms-animation-duration: 0.6s;
            -ms-animation-direction: alternate;

            -o-animation-name: scale2;
            -o-animation-timing-function: linear;
            -o-animation-iteration-count: infinite;
            -o-animation-duration: 0.6s;
            -o-animation-direction: alternate;

            animation-name: scale2;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-duration: 0.6s;
            animation-direction: alternate;

        }
        .dot3 {
            @extend .dot;
            left: 15px;
            float: right;
            -webkit-animation-name: scale2;
            -webkit-animation-timing-function: linear;
            -webkit-animation-iteration-count: infinite;
            -webkit-animation-duration: 0.6s;
            -webkit-animation-delay: 0.2s;
            -webkit-animation-direction: alternate;

            -moz-animation-name: scale2;
            -moz-animation-timing-function: linear;
            -moz-animation-iteration-count: infinite;
            -moz-animation-duration: 0.6s;
            -moz-animation-delay: 0.2s;
            -moz-animation-direction: alternate;

            -ms-animation-name: scale2;
            -ms-animation-timing-function: linear;
            -ms-animation-iteration-count: infinite;
            -ms-animation-duration: 0.6s;
            -ms-animation-delay: 0.2s;
            -ms-animation-direction: alternate;

            -o-animation-name: scale2;
            -o-animation-timing-function: linear;
            -o-animation-iteration-count: infinite;
            -o-animation-duration: 0.6s;
            -o-animation-delay: 0.2s;
            -o-animation-direction: alternate;

            animation-name: scale2;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-duration: 0.6s;
            animation-delay: 0.2s;
            animation-direction: alternate;
        }
    }
}

.dot {
    background-color: $blue;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    position: relative;

}

@-webkit-keyframes scale2 {
    from {
        -webkit-transition: all 1s ease-out;
    }
    to {
        -webkit-transform: scale(1.5);
    }
}

@-moz-keyframes scale2 {
    from {
        -moz-transition: all 1s ease-out;
    }
    to {
        -moz-transform: scale(1.5);
    }
}

@-ms-keyframes scale2 {
    from {
        -ms-transition: all 1s ease-out;
    }
    to {
        -ms-transform: scale(1.5);
    }
}

@-o-keyframes scale2 {
    from {
        -o-transition: all 1s ease-out;
    }
    to {
        -o-transform: scale(1.5);
    }
}

@keyframes scale2 {
    from {
        transition: all 1s ease-out;
    }
    to {
        transform: scale(1.5);
    }
}
