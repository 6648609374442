@import "./utils";
@import "./breakpoints";
@import "mixins-template";

.register-page {
    .register-description {
        font-size: rem(15);
        color: $gray;
        font-weight: 300;
        margin-bottom: 17px;
        text-align: center;
        padding: 0 50px;
    }
    @include alert-modal;
    .error {
        @include error;
    }
    .login-btn {
        @include main-button;
        &:disabled {
            @include main-button__disabled;
        }
    }

    .password-field {
        position: relative;
        .toggle-password {
            position: absolute;
            top: rem(25);
            right: 0;
            .password-icon{
                width: 28px;
                height: 14px;
            }
        }
    }
}

.divider {
    width: 100%;
    text-align: center;
    position: relative;
    color: $gray;
    &:before,
    &:after {
        @include pseudo();
        @include size(46%, 0);
        border-bottom: 1px solid $gray;
        top: 12px;
    }
    &:before {
        right: 0;
    }
    &:after {
        left: 0;
    }
}

.radio-group-wrapper {
    $margins: 16px;
    $sm-divider: .6;

    overflow: hidden;
    margin-left: -$margins;
    margin-right: -$margins;
    @include bp(sm down) {
        margin-left: -$margins * $sm-divider;
        margin-right: -$margins * $sm-divider;
    }
    .radio-group {
        min-width: calc(100% * (1 / 4) - (#{$margins} + 1px) * 2);
        margin: rem(13) $margins;
        text-align: center;
        cursor: pointer;
        @include bp(sm down) {
            width: auto;
            margin-left: $margins * $sm-divider;
            margin-right: $margins * $sm-divider;
        }
        span {
            display: inline-block;
            width: 100%;
            border-radius: rem(50);
            padding: rem(15) ($margins / 2);
            border: 1px solid $gray;
        }
        input[type="radio"]:checked + span {
            background: $yellow;
            border-color: $yellow;
            color: #fff;
        }
    }
}
