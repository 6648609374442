@import "styles/vendor";

.SortBar {
    @include bp(ss up){
        display: none !important;
    }
    @include bp(sm up){
        display: flex !important;
    }
    @include bp(768px down) {
        display: flex !important;
        margin-top: 0.7rem;
        /* margin-left: 20px; */
        /* margin-right: 50px; */
        border-top: none;
        border-bottom: none;

        .container {
            .information {
                font-size: 1.5rem !important;
                width: 100%;
                justify-content: flex-start !important;
                border-bottom: 1px solid #a3a3a3;
                padding-bottom: 15px;

            }
        }
    }
    height: rem(50);
    width: 100%;
    border-top: 1px solid #c4c4c4;
    border-bottom: 1px solid #c4c4c4;
    .container {
        .legend {
            .item {
                @include bp(md up){
                    text-align: center;
                }

                display: flex;
                align-items: center;
                margin-right: 6px;

                font-weight: $font-light;
                font-size: rem(10.03);
                color: #0b0b0b;

                button {
                    width: rem(30);
                }
                & > .title {
                    height: 100%;
                    display: inline-block;
                    vertical-align: super;
                }
            }
        }
        .information {
            font-weight: $font-light;
            font-size: rem(12);
            @include phoneFontSize(.8rem !important);
            color: #000000;
            .item {
                border-left: 1px solid #c4c4c4;
                padding-left: rem(16);
                padding-right: rem(8);
                &:first-child {
                    padding-left: 0;
                    border-left: none;
                }
            }
        }

    }
}
