@import "styles/vendor";

.suggestedProfilesList {
    margin-left: auto;

    .SuggestedProfile {
        padding: 1.5rem;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-bottom: 1px solid #c4c4c4;
        max-height: 15rem;
        height: 25%;
        .userPic {
            width: 3.5rem;
            height: 3.5rem;
            border-radius: 50%;
            overflow: hidden;
            &:after {
                background-image: url(../../../../assets/profile-bg-dark.png);
                background-size: 100%;
                width: 3.5rem;
                height: 3.5rem;
                position: absolute;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .name {
            text-transform: uppercase;
        }
        .compare-btn {
            position: absolute !important;
            top: 1rem;
            right: 1rem;
        }
        .rating {
            color: $yellow;
            font-size: .8rem;
        }
        .location, .type {
            font-size: .8rem;
        }
        .availability {
            position: absolute;
            right: 3.2rem;
            bottom: 1.5rem;
            font-size: .6rem;
            span.SVGInline {
                position: absolute;
                top: 0;
                right: -1.4rem;
                svg {
                    filter: invert(100%);
                }
            }
        }
    }
    .top-img {
        height: 15rem;
        position: relative;
        p {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            font-size: 1.1rem;
            font-weight: bold;
        }
        .grdnt {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            background: linear-gradient(to bottom, #000, transparent 4rem);
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
