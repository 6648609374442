@import '../vendor';

.contact-form {
    background-color: $light-gray;
    .top {
        height: 650 / 2880 * 100vw;
        margin-top: rem(75);
        padding-bottom: 1rem;
        .title {
            font-size: rem(96 / 2);
            color: #fff;
            font-weight: 500;
        }
        .subtitle {
            font-size: rem(44 / 2);
            color: #fff;
            font-weight: 300;
        }
    }
    .form-item {
        margin-top: rem(50);
    }
    h5 {
        font-size: rem(30 / 2);
        font-weight: 500;
        margin-bottom: rem(53 / 2);
    }
    .bottom-description {
        font-size: rem(30 / 2);
        p {
            font-weight: 300;
        }
    }
    .message-wrapper {
        border: 1px solid rgb(196, 196, 196);
        padding: 0.5rem 1rem;
        position: relative;
    }
    .bottom-description {
        p {
            margin-bottom: 0.5rem;
        }
    }
    .site-footer {
        width: 100%;
    }

    //TODO: refactor
    .site-footer__middle {
        display: none;
    }
    .site-footer__bottom {
        display: none;
    }
    input, textarea {
        color: #000!important;
    }
    &__wrapper {
        @include alert-modal;
        .modal__header {
            background-color: #ffd210;
        }
        .modal__main {
            text-align: center;
            background-color: #f3f3f3;
            color: #000;
            font-size: 2rem;
            font-weight: 400;
        }
        .modal__next {
            font-size: 1.5rem;
            background-color: #ffd210;
        }
    }
}

@media screen and (max-width: 1120px) {
    .contact-form {
        .top {
            margin-top: rem(65);
        }
    }

}

@media screen and (max-width: 900px) {
    html {
        font-size: 14px;
    }
}

@media screen and (max-width: 768px) {
    .contact-form {
        .top {
            margin-top: 55px;
        }
    }
}

@media screen and (max-width: 650px) {
    html {
        font-size: 12px;
    }
    .contact-form {
        .top {
            height: 800 / 2880 * 100vw;
        }
    }
}

@media screen and (max-width: 500px) {
    .contact-form {
        .top {
            height: 1000 / 2880 * 100vw;
            .title {
                font-size: 2.5rem;
            }
            .subtitle {
                font-size: 1rem;
            }
        }
    }

}