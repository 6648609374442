@import "styles/vendor";

.SpecificCategories {
    display: flex;
    flex-direction: column;
    width: 100%;
    @include bp(xs down) {
        max-width: none;
        padding: 0;
        margin-top: 70px;
    }
    @include bp(520px down){
        margin-top: 10px;
    }
    .search {
        @include bp(sm up) {
            border-bottom: 1px solid #c4c4c4;
        }
        .container {
            @include bp(768px down) {
                padding: 0;
                max-width: none;
            }
        }
    }
    .categories-list {
        max-width: 100%;
        flex-direction: column;
    }
    .top-categories {
        .top-title {
            height: rem(75);
            display: flex;
            align-items: center;
            justify-content: space-between;
            h3 {
                font-weight: bold;
                font-size: 2rem;
            }
            .button-back{
                color: #000;
                padding-right: 22px;
            }
            b {
                font-weight: $font-medium;
                font-size: rem(24);
                color: #000000;
            }
        }
        .top-categories-list {
            width: 100%;
            display: flex;
            align-content: flex-start;
            align-items: center;
            flex-wrap: nowrap;
            @include bp(768px down){
                overflow-x: auto !important;
                max-width: none;
                padding: 0;
            }
            @include bp(sm up){
                overflow-x: hidden !important;
                display: block;
            }
            .ProfileCard {
                @include bp(768px down){
                    &:nth-child(2n+1) {
                        margin-right: 1%;
                    }
                    min-width: 49.5%;
                    height: calc(50vw * 1.366);
                }
                @include bp(sm up){
                    @include adaptive-height-element(5);
                }
            }
        }
    }

    .subcategories-block {
        .header {
            margin-top: 20px;

            h3 {
                display: block;
                font-weight: bold;
                font-size: 2rem;
            }

            span {
                font-weight: 300;
                color: #a3a3a3;
            }
        }

        & > .subcategory-item {
            display: inline-block;
            margin-top: 20px;
            margin-right: 25px;
            font-weight: 300;
            cursor: pointer;
            color: #a3a3a3;

            & > span {
                color: #a3a3a3;
            }

            &.selected {
                font-weight: 600;
                padding: 5px 0;
                border-bottom: 4px solid #ffd210;
                color: black;

                span.users-count {
                    font-weight: 400;
                }
            }
        }
    }

    .nothing {
        margin: 50px auto;
        font-size: 26px;
        color: #a3a3a3;
    }
}
