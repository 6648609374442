@import "styles/vendor";

.SearchBlockMobile {
    @include bp(xs down) {
        h3 {
            font-size: 2.7rem;
            @include phoneFontSize(1.5rem);
            padding-left: 0.5%;
        }
        .switchModalSearchMenu {
            flex-basis: 140px;
            text-align: right;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            cursor: pointer;
            span {
                font-size: 1.4rem;
                @include phoneFontSize(.8rem);
            }
        }
        &__modal {
            .title {
                padding-top: 2.7rem !important;
                padding-bottom: 2.4rem;
                padding-left: 10px;
            }
            .ant-collapse-header {
                padding: 18px 0 17px 28px !important;
                color: rgba(0, 0, 0, 0.85);
                font-weight: 549;
                font-size: 2rem;
                @include phoneFontSize(1.3rem);
                background: #ececec !important;
                .arrow {
                    line-height: 3.5rem !important;
                }
            }
        }
        .ant-collapse-content-box {
            padding: 10px 18px;
            &>div {
                & > div {
                    padding: 2rem;
                    border-bottom: 1px solid #d1d1d1;
                }
            }
            svg {
                width: 35px !important;
                height: 35px !important;
            }
            label {
                font-size: 1.8rem;
                @include phoneFontSize(1.2rem);
                padding-left: 2rem;
            }
            .subcategory-item {
                display: inline-block;
                margin: 0 10px 10px;
                font-weight: 300;
                cursor: pointer;
                color: #a3a3a3;
    
                & > span {
                    color: #a3a3a3;
                }
    
                &.selected {
                    font-weight: 600;
                    padding: 0;
                    margin-bottom: 6px;
                    border-bottom: 4px solid #ffd210;
                    color: black;
    
                    span.users-count {
                        font-weight: 400;
                    }
                }
            }
        }
    }
    padding: 0 1rem;
    width: 100%;
    i[class*='fa-angle-'] {
        font-size: 1.5rem;
        margin-left: .5rem;
    }
    h3 {
        font-weight: bold;
    }
    &__modal {
        overflow-x: auto;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        z-index: 999;
        background: #fff;
        .title {
            text-align: center;
            padding-top: .5rem;
            position: relative;
            i {
                position: absolute;
                left: .3rem;
                transform: translateY(-50%);
                top: 50%;
                font-size: 1.5rem;
            }
        }
        .ant-collapse {
            width: 100%;
            border-radius: 0;
            border-right: 0;
            border-left: 0;
            .ant-collapse-header {
                background: $gray;
            }
            .arrow {
                left: 95% !important;
                font: normal normal normal 14px/1 FontAwesome;
                &:before {
                    content: '' !important;
                }
                &:after {
                    font-size: 1.5rem;
                    font-family: FontAwesome;
                    content: '\f105';
                }
            }
        }
    }
}
