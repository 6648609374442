@import "styles/vendor";

.HomeAction {
    text-align: left;
    h1 {
        letter-spacing: 5px;
    }

    p {
        letter-spacing: 3px;
        @include bp(768px down) {
            font-size: 1.8rem;
        }
        @include phoneFontSize(1.2rem);
    }

    @media (min-width: 992px) {
        padding: 0 5%;
    }

    a {
        width: 75%;
        max-width: 300px;
        padding: 14px 0;
        font-weight: 600;
        font-size: 16px;
        @include bp(768px down) {
            max-width: 500px;
            font-weight: 600;
            font-size: 1.8rem;
            padding: 1.2rem !important;
        }
        @include phoneFontSize(1rem);
    }

    &__title {
        font-family: "Cinzel", serif;
        line-height: normal;
        font-weight: bold;
        font-size: 1.5rem;

        @include bp(768px down) {
            font-size: 3rem;
        }
        @include phoneFontSize(1.5rem);
        @include bp(sm up) {
            font-size: 1.875rem;
        }

        @include bp(md up) {
            font-size: 2.3125rem;
        }
    }
    &__description {
        margin: 1.5em 0;
    }
    &__button {
        &:hover {
            border: 1px solid black;
            background-color: #fff !important;
            color: #000 !important;
        }
        &:active {
            border: 1px solid $yellow;
            background-color: $yellow !important;
            color: #fff !important;
        }
    }
}
