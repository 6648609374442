@import "styles/vendor";

.RecommendationProfile {
    height: calc(100% / 3);
    font-size: rem(12);
    border-bottom: 1px solid $gray;
    display: flex;
    justify-content: center;
    @include bp(1440 up) {
        font-size: rem(15);
    }
    &:nth-child(3) {
        border-bottom: none;
    }
    &__information {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 12px;
        padding-right: 0;
        &_rating {
            span {
                color: $yellow;
            }
        }
    }
    &__photo {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-left: 0;
        padding-right: 0;
    
        .UserPic {
            &:after {
                content: "";
                height: 100%;
                width: 100%;
                background: url(/assets/images/circles-abstract.png) no-repeat center top/100% auto;
                position: absolute;
            }
        }
    }
}
