@import "styles/vendor";

.ProfileCard {
    position: relative;
    min-width: auto;
    margin-bottom: 1%;
    border: 2px solid #ffffff;
    z-index: 0;
    &.selected {
        border: 2px solid #ffd210;
    }
    .preview-cover {
        @include size();
        position: absolute;
        top: 0;
        left: 0;
        img {
            @include size(auto, 100%);
            object-fit: cover;
        }
        &:after {
            @include pseudo();
            @include size();
            top: 0;
            background: linear-gradient(to top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
        }
        &.preview-cover-component {
            position: absolute;
            &:after {
                //display: none;
                pointer-events: none;

            }
            & + .preview-card {
                pointer-events: none;
                .UserPic {
                    pointer-events: all;
                }
            }
            & > div {
                height: 21.675rem;
                position: relative;
            }
            .info-avatar {
                height: 100%;
            }
        }
    }
    .preview-card {
        background-color: transparent;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        .profile-link-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 101;

            background-color: #000;
            opacity: 0;
            -webkit-transition: opacity .1s ease-out;
            -ms-transition: opacity .1s ease-out;;
            transition: opacity .1s ease-out;;

            &:hover {
                opacity: .2;
            }
        }

        .button-group {
            @include position(absolute, rem(8) rem(8) null null);
            width: rem(32);
            z-index: 102;
            height: 65%;
            button {
                border: none;
                background-color: transparent;
                color: #ffffff;
                display: block;
                width: rem(36) !important;
                height: calc(100% / 5) !important;
                padding: rem(2) !important;
                overflow: hidden;
                &:active {
                    path {
                        fill: $yellow;
                    }
                }
                svg {
                    width: 1rem !important;
                    height: 1rem !important;
                    path {
                        // fill: #fff;
                    }
                }
                i {
                    font-size: sr(12) !important;
                }

                .in_favorite {
                    svg {
                        path {
                            fill: #f00;
                        }
                    }
                }
                .not_favorite {
                    svg {
                        path {
                            fill: #fff;
                        }
                    }
                }
            }

        }
        .info {
            position: absolute;
            display: flex;
            flex-direction: column-reverse;
            align-content: center;
            height: 100%;
            width: 100%;
            padding-bottom: rem(12);
            .UserPic {
                left: rem(9.6);
                .fa-play {
                    color: #ffffff;
                    position: absolute;
                    line-height: rem(35.2);
                    font-size: rem(16);
                    bottom: -4px;
                    z-index: 102;
                    cursor: pointer;
                }
            }
            .description {
                color: #ffffff;
                position: relative;
                padding-left: rem(16);
                padding-right: rem(16);
                width: 100%;
                // min-height: sr(128);
                padding-top: rem(9);

                display: flex;
                flex-direction: column;
                justify-content: flex-end;

                .fee {
                    font-size: 1.1rem;
                    color: #ffffff;
                    @include bp(768px up) {
                        font-size: 0.9rem;
                    }
                    @include bp(1200px up) {
                        font-size: 1.1rem;
                    }
                }
                .name {
                    // text-transform: uppercase;
                    font-size: 1.1rem;
                    padding-bottom: rem(8);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    @include bp(768px up) {
                        font-size: 0.9rem;
                    }
                    @include bp(1200px up) {
                        font-size: 1.1rem;
                    }
                }
                .categories {
                    font-size: 0.56rem;
                    line-height: 1.2;
                    white-space: nowrap;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    @include bp(768px up) {
                        font-size: 0.5rem;
                    }
                    @include bp(1200px up) {
                        font-size: 0.56rem;
                    }
                }
                .location {
                    font-size: 0.56rem;
                    line-height: 1.2;
                    white-space: nowrap;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    @include bp(768px up) {
                        font-size: 0.5rem;
                    }
                    @include bp(1200px up) {
                        font-size: 0.56rem;
                    }
                }
                .rating {
                    color: #fece2f;
                    height: rem(13);
                    font-size: rem(13.28);

                    span {
                        font-size: rem(9.6);
                    }
                }
                .availability, .venue-place {
                    font-size: rem(10);
                    display: flex;
                    align-items: center;
                    align-content: center;
                    justify-content: flex-end;
                    position: absolute;
                    bottom: rem(-9);
                    right: rem(8);
                    width: 100%;
                    span {
                        margin-right: rem(0);
                        font-size: rem(6.12);
                        margin-left: rem(4);
                    }
                    svg {
                        width: .8rem !important;
                    }
                }
                .venue-place {
                    height: 23px;
                    span {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        font-size: .56rem;
                        .SVGInline {
                            margin-left: 0;
                        }
                        i.fa {
                            margin-left: rem(3);
                        }
                    }
                }
            }
        }
    }

    &.light {
        margin: 0;
        border: none;
        width: 22.9rem;
        height: sr(450, 1.5);

        .preview-cover {
            display: none;
        }
        .preview-card {
            .button-group {
                .like-btn,
                .share-btn,
                .review-btn {
                    display: none !important;
                }
                .compare-btn {
                    path {
                        fill: #000000;
                    }
                }
            }
            .info {
                cursor: default;

                .UserPic {
                    .fa {
                        display: none;
                    }
                }
                .description {
                    color: black;
                    .fee {
                        font-weight: 300;
                        span {
                            color: black;
                        }
                    }
                    .availability {
                        .SVGInline-svg {
                            path {
                                fill: #000000;
                            }
                        }
                    }
                }
                //.avatar {
                //    background-image: url(../../../assets/images/circles-abstract.png);
                //    .fa {
                //        display: none;
                //    }
                //}
            }
        }
    }
    &__popover {
        height: 100%;
        div {
            height: 100%;
        }
        .activity-wrapper {
            display: none;
        }
        .UserPic {
            position: absolute;
            bottom: 1rem;
            left: 1rem;
        }
    }
    &__close-popup {
        position: absolute;
        top: 1rem;
        right: 1rem;
        color: #fff !important;
        text-shadow: 0 1px 0 #000 !important;
    }
}
