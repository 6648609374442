@import '../../vendor';

$transition-delay: .3s;
$grey: #a3a3a3;

.band-contact-info {
    box-sizing: content-box;
    margin: rem(100 / 2) rem(198 / 2);
    @include bp(992px down) {
        margin: 5%;
    }
    text-align: center;
    font-size: calc(30rem / 32);
    font-weight: 300;
    @include form-input-color;
    .filled {
        color: #000;
    }
    &__content-block {
        .add-more-wrapper {
            // .add-members {
            //     background-color: transparent;
            //     -ms-flex-positive: 0;
            //     flex-grow: 0;
            //     border: none;
            //     font-size: 0.875rem;
            //     color: #a3a3a3;
            //     display: -ms-flexbox;
            //     display: flex;
            //     -ms-flex-pack: center;
            //     justify-content: center;
            //     -ms-flex-align: center;
            //     align-items: center;
            //     .fa {
            //         width: 1.3125rem;
            //         height: 1.3125rem;
            //         border: 2px solid #a3a3a3;
            //         color: #a3a3a3;
            //         border-radius: 50%;
            //         margin-right: 0.5rem;
            //     }
            //     .fa::before {
            //         position: relative;
            //         top: 0.25rem;
            //     }
            // }
        }
    }
    &__container {
        padding: 1rem 0;
    }
    &__optional {
        color: #d1d1d1;;
        font-weight: 400;
        margin-left: 0.3rem;
        margin-right: 5px;
    }
    &__fields {
        position: relative;
        text-align: left;

        .error {
            @include error;
        }

        .fa {
            &-stack {
                position: relative;
                top: 0;
                font-size: calc(20rem / 32);
            }
        }
    }

    &__info {
        @include informative-title;
        .fa {
            &-stack {
                position: relative;
                left: 0;
                top: 0;
                font-size: calc(20rem / 32);
            }
        }
        &-big-title {
            color: #000;
            font-weight: 500;
            text-align: left;
            border-left: 4px solid $yellow;
            cursor: pointer;
            padding: 0 1rem;
            cursor: pointer;
            font-size: rem(36 / 2);
        }
        &-title {
            color: #000;
            font-weight: 500;
            text-align: left;
            cursor: pointer;
            padding: 2rem 0 0;
            cursor: pointer;
            font-size: rem(32 / 2);
        }
    }

    &__input {
        width: 100%;
        border: none;
        border-bottom: 1px solid $grey;
        outline: none;
        color: #000000;
        font-size: calc(50rem / 32);
        font-weight: 300;
        transition: border-color $transition-delay;

        //&:hover,
        &:focus,
        &:active,
        &.active {
            border-bottom-color: $yellow;
        }

        &::-webkit-input-placeholder {
            color: $grey;
        }
        &::-moz-placeholder {
            color: $grey;
        }
        &:-moz-placeholder {
            color: $grey;
        }
        &:-ms-input-placeholder {
            color: $grey;
        }
    }

    &__checkbox-wrapper {
        @extend %checkbox-wrapper;
    }

    &__calendar {
        height: calc(135rem / 32);
        width: calc(600rem / 32);
        margin-left: 0;
        margin-right: auto;
        background-color: $grey;
        align-items: center;
        color: #fff;
    }

    &__date {
        position: relative;
        text-align: left;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-end;
        &-of-birth {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: calc(46rem / 32);

            & > div:not(.band-contact-info__calendar) {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: row-reverse;
            }

            [type="checkbox"] {
                visibility: hidden;
            }

        }

        &-age {
            color: #000000;
            font-size: calc(22rem / 32);
            font-weight: 300;
        }

        .fa {
            &-stack {
                position: absolute;
                left: calc(-70rem / 32);
                top: 0;
                font-size: calc(20rem / 32);
            }
        }
    }
    &__contact-information {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        & > div {
            width: 100%;
        }
    }
    &__curriculum-vitae {
        @at-root .curriculum-vitae-form {
            &__item {
                &-header {
                    @include informative-title;
                }
            }

            &__text {
                font-size: rem(14);
                color: $grey;
                font-weight: 300;
                text-align: left;
            }
        }
        .input-file {
            &__button {
                width: 9rem;
                padding: rem(10) 0;
                border-radius: rem(32);
                font-size: 0.85rem;
                font-weight: 500;
                color: #fff;
                background-color: $yellow;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    height: rem(14);
                    position: relative;
                    top: rem(3);
                    margin-right: rem(4);
                }
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    &__next {
        @include main-button;
        &:disabled {
            @include main-button__disabled;
        }
    }

    &__delete-label {
        font-size: 1rem;
        font-weight: 500;
        width: 100%;
        text-align: center;
        margin-bottom: 1rem;
    }

    // Modal
    .skylight {
        &-wrapper {
            display: flex;
        }

        &-close-button {
            right: calc(40rem / 32) !important;
            font-size: calc(130rem / 32) !important;
            line-height: .73;
        }
    }
    @include alert-modal;
}

.band-member-container {
    width: 150px;
    height: 221px;
    margin: 10px;
    box-shadow: 0px 5px 5px #e2e2e2;
    &.new-member {
        box-shadow: initial;
        cursor: pointer;
    }
    &.disabled {
        cursor: not-allowed;
    }
    &.manager {
        .band-member-wrapper {
            border-color:#ffd210;

            .member-status {
                border-color: #ffd210;
                background: #ffd210;
                color: #fff;
            }
        }
    }

    .cover-img {
        position: absolute;
        height: 70px;
        width: 150px;
        margin-left: -74px;
    }
    .buttons {
        position: absolute;
        color: $grey;
        z-index: 1;
        float: right;
        width: 142px;
        text-align: right;
        margin-top: 0.25rem;
        font-size: 1rem;
    }
}

.band-member-wrapper {
    border: 1px solid #e2e2e2;
    width: 150px;
    height: 221px;
    overflow: visible;
    text-align: center;
    display: flex;
    flex-direction: column;
    .member-avatar {
        display: block;
        border-radius: 50%;
        overflow: hidden;
        height: 55%;
        margin-top: -1rem;
        width: 43%;
        &-wrapper {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex: none;
            width: 100%;
            height: 120px;
            margin-top: 1rem;
            margin-bottom: -1rem;
        }
    }
    .member-avatar-wrapper::after {
        content: " ";
        height: 80%;
        width: 80%;
        background: url(../../../assets/black-avatar-contur.svg) no-repeat center top/75% auto;
        position: absolute;
        top: 25%;
        left: 50%;
        transform: translate(-50%, -25%);
    }
    .member-avatar img {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover;
        -webkit-backface-visibility: hidden;
    }
    .member-avatar i {
        -webkit-text-stroke: 13px #fff;
        font-size: 70px;
        color: $grey;
    }
    .member-name {
        font-weight: 500;
        word-wrap: break-word;
        margin-bottom: 0rem;
    }
    .member-description {
        font-size: 0.7rem;
        text-transform: uppercase;
        font-weight: 300;
        color: $grey;
        margin-bottom: 0rem;
    }
    .member-status {
        border-top: 1px solid #e2e2e2;
        margin-top: 1rem;
        padding: 2px;
        font-size: 0.6rem;
    }
    .waiting {
        border-top: none;
        background: #000;
        color: #fff;
        font-weight: 600;
    }
}
