@import "styles/vendor";

.SelectedProfile {
    height: 100%;
    border-right: 1px solid #c4c4c4;
    color: black;
    position: relative;
    h5 {
        width: 100%;
        font-size: 1rem !important;
    }
    .header {
        display: flex;
        height: 4.5rem;
        flex-direction: row;
        align-items: center;

        .name {
            font-size: 1rem;
            font-weight: $font-semi-bold;
            text-transform: uppercase;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow-x: hidden;
            line-height: 2.2;
            height: 4.5rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            
            p {
                margin-bottom: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                &:first-child {
                    position: relative;
                }
                .SVGInline {
                    position: absolute;
                    top: .3rem;
                    right: .2rem;
                }
            }
        }
    }
    .cover {
        height: 11rem;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    .profile {
        &__tags {

        }
        &__prices {
            margin: 0;
            border: none;
            & > div {
                border: none;
                p {
                    text-align: center;
                }
            }
            a:hover {
                color: inherit;
            }
        }
        &__header {
            width: auto;
            height: auto;
            position: relative;
            transform: none;
        }

        &__pic {
            width: auto;
            height: auto;

            &-wrapper {
                width: 3.5rem;
                height: 3.5rem;
            }

            &-circle {
                position: relative;

                &-wrapper {
                    max-width: none;
                }
            }

            img {
                height: auto;
            }
        }
    }
    
    .short-info {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0 1rem;
    }
    .line {
        padding-top: 1rem;
        width: 100%;
        border-bottom: 1px solid $gray;
    }
    .about {
        padding: 1rem;
        a {
            color: $yellow;
        }
    }
    .occasions {
        padding: 0 1rem;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1rem;
        span {
            background-color: $gray;
            border-radius: 10rem;
            margin: .4rem .4rem 0 0;
            padding: .1rem 1rem;
        }
    }
    .amenities {
        padding: 0 1rem;
        font-weight: bold;
        h5 {
            margin-bottom: 0;
            span {
                cursor: pointer;
                color: $gray;
            }
        }
        &:last-child {
            border-bottom: 0;
        }
        .amenities-item {
            padding: 1rem 0;
            border-bottom: 1px solid $gray;
            .name {
                color: $gray;
            }
            .value {
                font-weight: 400;
                margin-bottom: 0;
            }
        }
    }
    .cancellation-policy {
        padding: 1rem;
        h5 {
            &:first-child {
                font-weight: bold;
            }
            color: #000 !important;
            span {
                &:last-child {
                    color: $yellow;
                    cursor: pointer;
                }
            }
        }
        .description {
            color: $gray;
        }
    }
    .business-locations {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 rem(28);
        p {
            margin-bottom: 0;
        }
        .circle {
            margin-right: rem(10);
            width: rem(25);
            height: rem(25);
            border: 2px solid #000000;
            border-radius: 50%;
            display: inline-block;
            text-align: center;
            line-height: rem(24);
        }
    }
    .claps {
        display: flex;
        margin: 0 1rem;
        align-items: flex-end;
        div {
            border-top: 1px solid #e1e1e1;
            border-bottom: 1px solid #e1e1e1;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
        }
        p {
            font-size: 0.625rem;
            width: percentage(1 / 3);
            padding: 10px 0;
            text-align: center;
        }
        b {
            font-weight: 500;
            font-size: 1.3125rem;
        }
        small {
            font-size: 30%;
        }
        a {
            width: 100%;
            font-size: 0.75rem;
            font-weight: 600;
            display: inline-block;
            padding: 15px 0;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
        a:hover {
            color: #e4222e;
        }
        a i {
            margin-right: 5px;
            font-size: 150%;
        }
        border-bottom: 1px solid #c4c4c4;
        & > div {
            border: none;
            p {
                text-align: center;
            }
        }
        a:hover {
            color: inherit;
        }
    }
    .events {
        padding: .5rem 1rem;
        &__calendar {
            border-bottom: 1px solid $gray;
        }
        .DataRangePicker {
            &__bottom {
                display: none;
            }
            .rdr-DateRange {
                border-top: 0 !important;
                .rdr-Calendar {
                    width: 100% !important;
                }
            }
        }
        &__title {
            display: none;
        }
        &__next {
            display: none;
        }
        &__calendar {
            .rdr-DateRange {
                display: flex !important;
                align-items: center;
                justify-content: center;
                align-content: center;
                flex-direction: row;
            }
        }
    }
    .about {
        button {
            background-color: transparent !important;
            div {
                span {
                    color: #fed13c !important;
                }
            }
        }
    }
}

