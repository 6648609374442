@import "styles/vendor";

.LandingButton {
    border: 1px solid #fff;
    display: inline-block;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    font-weight: 600;
    padding: 5px;
    border-radius: 2px;
    transition: all .22s;
    background: white;
    color: black;
    //position: relative;
    //top: -0.5rem;
    text-align: center;
    @include bp(xs up) {
        padding: 12px 30px;
    }

    & i:last-child {
        margin-left: 10px;
    }

    & i:first-child {
        margin-left: 0;
        margin-right: 10px;
    }

    &:hover {
        background: transparent;
        color: white;
        border: 1px solid #fff;
    }
    &:active {
        color: white !important;
        background-color: $yellow !important;
        border: 1px solid $yellow !important;
    }
    &#{&}__transparent {
        border: 1px solid #ffffff;
        background: transparent;
        color: #ffffff;
        &:hover {
            background: #ffffff;
            color: #000000;
        }
    }

    &#{&}__large {
        //padding: 10px 50px;
        padding: 14px 0;
        @include bp(md up) {
            padding: 15px 50px;
        }
        @include bp(768px down){
            margin-top: 0rem;
        }

        @include bp(xl up) {
            padding: 18px 75px;
        }
        border: 1px solid #000000;
        //background: transparent;
        color: #000000;
        &:hover {
            background: #000000;
            color: #ffffff;
        }
    }

}
