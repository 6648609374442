@import "styles/vendor";

.HomeArticle {
    padding: 0 !important;
    justify-content: center;
    align-items: center;

    @include bp(768px down) {
        margin: 0px 0rem;
        .text-md-left {
            margin-top: 8rem;
        }
        &__button{
            font-size: 1.8rem !important;
        }
        .all-item {
            &__content {
                width: 100%;
                padding: 0px 10px;
            }
            &__header {
                width: 90%;
                margin: 1rem auto 1rem;
            }
            &__title {
                @include tabletFontSize(1.5rem)
            }
            &__subtitle {
                margin: .5em 0 1rem;
                font-size: 2rem;
            }
            &__copy {
                @include tabletFontSize(1.4rem)
            }
            &__logo {
                height: 58px;
                margin-right: 16px;
            }

        }
    }
    @include bp(520px down) {
        margin: 0;
        .text-md-left {
            margin-top: 1rem;
        }
        &__button{
            font-size: .8rem !important;
        }
        .all-item {
            &__content {
                width: 100%;
                padding: 0px 10px;
                display: block;
            }
            &__header {
                width: 90%;
                margin: 1rem auto 1rem;
            }
            &__title {
                @include phoneFontSize(.6rem)
            }
            &__subtitle {
                margin: .5em 0 1rem;
                font-size: .7rem;
            }
            &__copy {
                @include phoneFontSize(.8rem)
            }
            &__logo {
                height: 34px;
                margin-right: 16px;
            }

        }
    }
    @include bp(768px up) {
        .all-item {
            &__content {
                padding: 0 !important;
            }
            &__subtitle {
                margin: 0 !important;
            }
        }
    }
    @include bp(992px up) {
        .all-item {
            &__content {
                width: 90%;
                left: 5%;
            }
            &__subtitle {
                margin: .5em 0 1em !important;
            }
        }
    }
    @include bp(1200px up) {
        .all-item {
            &__content {
                width: 80%;
                left: 10%;
            }
        }
    }
    .LandingButton {
        font-weight: 600;
        &:active {
            border: 1px solid $yellow;
        }
    }
    a {
        width: 75%;
        max-width: 300px;
        padding: 18px 0;
        font-weight: 200;
        font-size: 16px;
    }
    h1 {
        font-weight: 200;
    }

    h2 {
        font-weight: 700;
    }

    h1, h2 {
        letter-spacing: 5px;
    }

    p {
        letter-spacing: 3px;
        font-weight: 200;
    }
}
.all-item {
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
}
.all-item:after {
    content: "";
    display: block;
    padding-bottom: 100%;
    flex-grow: 0;
    flex-shrink: 0;
}
  
.all-item__content {
    position: absolute;
    max-width: 90%;
}
