.SelectBankAccountForm {
    margin-left: 2rem;
    
    display: flex;
    flex-direction: column;
    
    .SelectBankAccountForm__Item{
        text-align: left;
        margin-bottom: 8px;
        border-radius: 5px;
    }
}