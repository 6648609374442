@import "styles/vendor";

$small-font-size: 13px;
$medium-font-size: 15px;
$big-font-size: 22px;

.PaymentsInfoRow {
    height: 40px;

    @media screen and (max-width: $screen-sm) {
        height: auto;
    }
}

.SmallText {
    font-size: $small-font-size;
}

.MediumText {
    font-size: $medium-font-size;
}

.BigText {
    font-size: $big-font-size;
}

.mw-600px {
    max-width: 600px;
}

.charging-consent {
    margin-top: 1rem;
}
