@import "styles/vendor";

.PopUpPriceList {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: #fff;
    padding: 1rem;
    .title {
        font-weight: bold;
        margin-bottom: 0;
    }
    .price-list {
        .item-price {
            display: flex;
            justify-content: space-between;
            padding: 1rem 0;
            border-bottom: 1px solid $gray;
            .val {
                display: flex;
                flex-direction: column;
            }
        }
    }
}
