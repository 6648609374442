@import "styles/vendor";

.GroupFilter {
    display: flex;
    flex-direction: column;
    min-width: sr(250, 1.5);
    width: 100%;
    max-width: sr(1420, 1.5);
    z-index: 1900;
    padding-bottom: rem(20);
    padding-right: rem(20);
    border-top: 5px solid #ffd210;
    @include bp(768px down){
        padding-right: 0;
        .FilterInput{
            padding-right: rem(20);
            input[placeholder="Search"]{
                -webkit-box-shadow: none!important;
                -moz-box-shadow: none!important;
                box-shadow: none!important;
            }
        }
    }
    .select-wrapper {
        position: relative;
        width: 100%;
        max-width: sr(1420, 1.5);
        max-height: sr(560, 1.5);
    }
}
