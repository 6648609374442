@import '../vendor';

.photo-history {
    & + & {
        .photo-history__title {margin-top: sr(30px);}
    }
    &__title {
        margin: 0 0 sr(20px) sr(15px);
        text-align: left;
        font-size: 13px;
        color: #a3a3a3;
        font-weight: 500;
    }
    &__photos {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 0 1px;
    }
    &__photo-wrapper {
        width: 100px;
        padding: 1px;
        @include bp(768px down){
            width: 33%;
        }
        @include bp(520px down){
            width: 50%;
        }
    }
    &__photo {
        display: block;
        width: 100%;
        height: 100%;
    }
    &__aspect-ratio {
        position: relative;
        padding-top: 100%;
    }
    &__aspect-ratio-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
