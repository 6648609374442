@import "styles/vendor";

.SpotlightTab {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include bp(768px down){
        padding: 0px 3rem;
    }
    @include bp(520px down){
        padding: 0 1rem;
    }
    .SortBy {
        display: none;
    }
    .container {
        @include bp(sm down){
            padding: 0;
            max-width: 100%;
        }
        .top-five-venues,
        .top-five-artists,
        .top-five-events {
            display: flex;
            flex-direction: column;
            align-items: center;
            align-content: center;
            justify-content: center;
            .title {
                align-self: flex-start;
                display: inline-block;
                font-weight: $font-light;
                color: #e0b82f;
                font-size: rem(24);
                line-height: 4rem;
                top: -0.5rem;
                position: relative;
                @include bp(768px down){
                    margin-top: 4.5rem;
                    margin-bottom: 1.5rem;
                    font-size: 2.5rem;
                }
                @include bp(520px down){
                    margin-top: 2.5rem;
                    margin-bottom: 1rem;
                    line-height: 2rem;
                }
                @include phoneFontSize(1.5rem);
                b {
                    font-weight: $font-bold;
                    color: #000000;
                }
                span {
                    font-weight: $font-medium;
                    @include bp(xs down) {
                        color: $gray-hue-2;
                    }
                }
            }
            &__wrapper {
                flex-wrap: nowrap;
                width: 100%;
                @include bp(ss up){
                    display: flex;
                    align-self: auto;
                    overflow-x: scroll;
                }
                @include bp(md up){
                    display: block;
                    align-self: baseline;
                    overflow-x: hidden;
                }
                max-width: 100%;
                .ProfileCard {
                    margin-left: 0%;
                    @include bp(sm down){
                        margin-right: 1%;
                        min-width: 49.5%;
                        height: calc(50vw * 1.366);
                        @include bp(564px up) {
                            min-width: 282px;
                            height: 390px;
                            margin-right: 5%;
                        }
                    }
                    @include bp(768px down){
                        min-width: 91%;
                        height: 50rem;
                    }

                    @include bp(520px down){
                        margin-right: 1%;
                        min-width: 90%;
                        height: calc(90vw * 1.366);
                    }
                    @include bp(md up){
                        @include adaptive-height-element-second(5);
                    }
                }
            }
        }
    }
}
