@import "styles/vendor";

.Hero {
    height: auto;
    width: 101%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    padding: 80px 0 55px;
    @include bp(768px down) {
        margin-top: 115px;
    }
    @include bp(520px down){
        margin-top: 65px;
    }
    @include bp(sm up) {
        min-height: 90vh;
        padding: 8.3rem 0 0;
    }

    &::before {
        content: " ";
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        background: url(../../../assets/images/hero-genetic-mobile.png) no-repeat center bottom/auto;
        top: 38px;
        z-index: -1;
        @include bp(xs up) {
            background: url(../../../assets/images/hero-genetic.png) no-repeat center bottom/835px auto;
            top: 35px;
        }
    }

    &__title {
        font-size: 1.875rem;
        line-height: normal;
        font-weight: 600;
        margin: 0 0 1.8em;
        color: #fff;        
        @include bp(768px down) {
            font-size: 3.5rem;
            padding-top: 20px;
            font-weight: 600;
            letter-spacing: 0.75px;
        }
        @include phoneFontSize(2rem);
        @include bp(lg up) {
            font-size: 3rem;
        }
    }

    &__subtitle {
        font-weight: 100;
        font-size: 0.9375rem;
        color: #fff;
        @include bp(md down) {
            font-weight: 400;
        }
        @include bp(sm down) {
            font-size: 1.8rem;
            font-weight: 500;
            letter-spacing: 0.756px;
        }

        @include phoneFontSize(1.2rem);
        @include bp(xs up) {
            .hero__subtitle {
                font-size: 1rem;
            }
        }
        /*media min-width: 70em*/
        @include bp(lg up) {
            font-size: 1.11rem;
        }
    }

    &__image {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 100%;
        width: 100%;
        object-fit: cover;
        transform: translate(calc(-50% - 1px), -50%);
        display: block;
        z-index: -2;
    }

    &__cta {
        font-size: 0.75rem;
        font-weight: 500;
        text-decoration: none;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        transition: all .22s;
        @include bp(xs up) {
            font-size: 0.875rem;
        }
        @include bp(lg up) {
            font-size: 1rem;
        }
        @include bp(sm down) {
            padding: 0;
        }
        & i {
            font-size: 1.125rem;
            color: black;
            position: absolute;
            bottom: -50px;
            left: 50%;
            transform: translateX(-50%);

            @include bp(md up) {
                font-size: 1.4375rem;
                bottom: -50px;
            }

            @include bp(xl up) {
                font-size: 1.75rem;
                bottom: -60px;
            }
        }
    }

    a {
        font-weight: 500;
        font-size: 1rem;
        padding: 12px 70px;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        @include bp(768px down){
            font-size: 1.3rem;
            padding: 14px 100px;
        }
        @include phoneFontSize(.8rem);
    }

    h1 {
        font-weight: 600;
    }
}
