$primary-color: #ffd210;

.popover {
    border: none;
}

.GigworksPopover {
    & > .popover {
        background: #fff;
        box-shadow: 0 -8px 38px rgba(0,0,0,0.05), 0 -5px 12px rgba(0,0,0,0.12);
        border-radius: 12px;
        min-width: 25px;
        min-height: 25px;
        //margin-top: 24px;
        //transform: translateX(-90px);
    }

    &.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
        top: 2px;
    }

    &.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
        border-bottom-color: transparent;
    }
    
    // &:before {
    //     display: none;
    //     content: '';
    //     position: absolute;
    //     bottom: 100%;
    //     left: 75%;
    //     //transform: translateX(-50%);
    //     width: 0; 
    //     height: 0; 
    //     border-left: 12px solid transparent;
    //     border-right: 12px solid transparent;
    //     
    //     border-bottom: 12px solid #fff;
    // }
}