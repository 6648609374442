// @import "vendor"


.SelectField {
    &Item {
        background-color: #f2f2f2;
        border: 6px solid #f2f2f2;
        text-align: left;
        padding: 18px 24px;
        
        &.selected {
            background-color: #fff;
            border: 6px solid #f2f2f2;
            font-weight: 600;
        }

        &:hover {
            cursor: pointer;
        }

        &:hover:not(.selected) {
            background-color: #e2e2e2;
            border: 6px solid #e2e2e2;            
        }
    }
}