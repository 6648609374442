@import "../vendor";

$grey: #a3a3a3;
$grey-light: #e1e1e1;

.service{
    &-detail-form {
        &__title {
            font-size: rem(16);
        }
        .radio-group-wrapper {
            label {
                @include bp(768px down){
                    width: 48% !important;
                    white-space: nowrap;

                }
            }
            @include bp(768px down){
                display: flex;
                justify-content: space-between;
            }
        }
    }
    &-info{
        &-form{
            margin: 4.625rem 6.1875rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            .filled {
                color: #a3a3a3;
            }
            @include bp(768px down){
                margin: 1.3125rem 1.5rem !important;
                border: 1px solid #969696;
                padding: .5rem;
            }
            @include bp(sm down) {
                margin: (4.625rem / 2) (6.1875rem / 2);
            }
            &__wrapper {
                width: 100%;
                margin: 0 0 rem(60);
                @include bp(768px down){
                    margin: 0 0 1.75rem;
                }
                div {
                    div:first-child {
                        @include tabletFontSize(1rem !important);
                        @include phoneFontSize(.8rem !important);
                    }
                    input {
                        @include tabletFontSize(1rem !important);
                        @include phoneFontSize(.8rem !important);
                    }
                }
                .info-popup-window {
                    @include bp(768px down){
                        display: none;
                    }
                }
                .number-input {
                    textarea {
                        width: 70%;
                        max-height: rem(40)!important;
                        font-size: 1.5rem;
                        @include tabletFontSize(1rem !important);
                        @include phoneFontSize(.8rem !important);
                    }
                }
                textarea {
                    width: 100%;
                    resize: none;
                    border: 0;
                    border-bottom: 1px solid $gray;
                    font-size: 1rem;
                    outline: none;
                    &:focus {
                        border-color: $yellow;
                    }
                    &::placeholder {
                        font-size: 1rem;
                        font-weight: 300;
                    }
                    @include tabletFontSize(1rem !important);
                    @include phoneFontSize(.8rem !important);
                }
                .input-file{
                    &__button {
                    width: 9rem;
                    padding: rem(10) 0;
                    border-radius: rem(32);
                    font-size:0.85rem;
                    font-weight: 500;
                    color: #fff;
                    background-color: $yellow;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                        img {
                            height: rem(14);
                            position: relative;
                            top: rem(3);
                            margin-right: rem(4);
                        }
                    &:hover {
                        cursor: pointer;
                    }
                }
                }
            }
            &__item {
                &-header {
                    @include informative-title;                    
                }
                &--large-size {
                    textarea {
                        font-size: 25px;
                        &::placeholder {
                            font-size: 1.5rem;
                            font-weight: 300;
                        }
                    }
                }
            }
            &__file-wrapper {
                display: flex;
                align-items: center;
            }
            &__text {
                font-size: rem(14);
                color: $grey;
                font-weight: 300;
                margin-top: 20px;
            }
            &__title {
                font-size: rem(16);
                span.description {
                    @include bp(768px down){
                        display: none;

                    }
                }
            }
        }
    }
}
