@import '../vendor';

@mixin rangeInputThumbStyle() {
	@include size(rem(16px));
	transform: translateY(-5px);
	border-radius: 50%;
	background-color: #000000;
}

.profile-photos-wrapper {
	margin: 1.3rem 1.5rem;
	@include bp(sm down) {
		margin: 1.3rem 1.5rem;
	}
}

.photo-editor-wrapper {
	@extend %EditMediaContent;
	border: none;
	@include bp(768px down) {
		border: 1px solid black;
		padding: 1rem 1rem 2rem 1rem;
	}

	&--photo,
	&--preview {
		margin-top: sr(147px);
		@include bp(768px down) {
			margin-top: 0.5rem;
		}
	}

	.cropper {
		&-move {
			cursor: pointer;
		}
		&-modal {
			opacity: 0.8;
			background-color: #fff;
		}
		&-canvas {
			width: 100%;
			img {
				width: 100% !important;
			}
		}
	}

	p.text-center {
		color: #fff;
		font-weight: 600;
	}

	.zoom {
		width: 60%;
		position: relative;
		margin: 0 auto;

		input {
			width: 80%;
			margin-left: 5%;
			margin-right: 5%;
			display: inline-block;
		}

		img {
			display: inline-block;
			width: 5%;

			&:last-of-type {
				margin-left: 5%;
			}
		}
		.save-btn {
			@extend %default-btn;
			@include size(80%, rem(50px));
			background: #007bff;
			color: #ffffff;
			font-size: 18px;
			font-weight: 500;
		}
		&-control {
			display: flex;
			align-items: center;
			margin-bottom: 20px;
		}
	}

	.circle {
		.cropper-view-box,
		.cropper-face {
			border-radius: 50%;
			&.cropper-view-box {
				outline: none;
			}
		}
	}

	.diamond {
		.cropper-view-box,
		.cropper-face {
			clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
		}

		&.cropper-view-box {
			display: none;
		}
	}

	.full {
		.cropper-view-box,
		.cropper-face,
		.cropper-crop-box {
			display: none;
		}

		.cropper-modal {
			background: none;
		}
	}

	canvas {
		&:hover {
			cursor: move;
		}
	}

	input[type='range'] {
		-webkit-appearance: none;
		background: transparent;

		&::-webkit-slider-thumb {
			@include rangeInputThumbStyle();
			-webkit-appearance: none;
		}

		&::-ms-thumb,
		&::-webkit-slider-thumb,
		&::-moz-range-thumb {
			@include rangeInputThumbStyle();
		}

		&:focus {
			outline: none;
		}

		&::-ms-track {
			width: 100%;
			cursor: pointer;
			background: transparent;
			border-color: transparent;
			color: transparent;
		}

		&::-webkit-slider-runnable-track {
			width: 100%;
			cursor: pointer;
			background: #000;
			border-radius: 2px;
			height: rem(4px);
			border: none;
		}
	}
}

.photo-editor-wrapper {
	%header {
		display: flex;
		justify-content: space-between;
		height: auto;
		padding: 0;
	}
	%title {
		font-size: 15px;
		font-family: Montserrat, serif;
		font-weight: 200;
		color: $font-gray;
		@include bp(768px down) {
			font-size: 1rem;
			font-weight: 500;
		}
	}
	/*PROFILE PICTURE*/
	@at-root .photo-editor-profile-picture {
		.header {
			@extend %header;
		}

		.title {
			@extend %title;
		}

		.reposition-btn {
			border: none;
			outline: none;
			font-size: 15px;
			color: $font-gray;
			background: white;
			@include bp(768px down) {
				display: none;
			}
		}

		.control {
			margin-top: sr(50px);
		}

		.default-preview {
			display: flex;
			justify-content: space-around;
			@include bp(sm down) {
				flex-direction: column;
			}
			.upload-image-preview {
				@include size(150px);
				background: #d3d3d3; // url(../../assets/plus-sign.png) no-repeat center;
				border-radius: 50%;
				margin: 30px auto;
				&::after {
					@include size(150%);
					@include pseudo($pos: relative);
					transform: translate(-38px, -38px);
					background: url(/assets/images/circles-abstract.png) center no-repeat;
					background-size: contain;
				}
			}
			.picture-preview-wrapper {
				width: to-rem(250);
				margin-top: to-rem(25px);
				@include bp(sm down) {
					width: 100%;
				}
			}
			.home-picture-preview {
				@include size(130px);

				transform: rotate(45deg);
				background: #d3d3d3;
				margin: 30px auto;
				&-title {
					color: #000;
					font-weight: 700;
					text-align: center;
					margin-top: 75px;
					margin-bottom: 15px;
					font-size: 16px;
				}
				&-desc {
					text-align: center;
					font-size: 11px;
					color: #a3a3a3;
					font-weight: 200;
				}
			}
		}

		.advanced-control {
			display: flex;
			justify-content: space-around;
			text-align: center;
			@include bp(768px down) {
				flex-direction: column;
			}
			.upload-hint {
				margin-bottom: 10px;
				&.crop {
					@include bp(768px down) {
						width: 80%;
					}
				}
			}

			&-item {
				width: rem(295);
				margin: 0 1rem;
				@include bp(768px down) {
					width: 75vw !important;
					height: 75vw !important;
					margin: 0 auto;
				}
				.upload-btn-wrapper {
					label {
						@include bp(768px down) {
							font-size: 1rem;
							font-weight: 500;
							color: $font-gray;
						}
					}
				}
				&.home-picture-preview {
					@include bp(768px down) {
						max-width: 100% !important;
						margin-bottom: 5rem;
					}
				}
				&.uploaded-config {
					@include bp(768px down) {
						width: 75vw !important;
						height: 75vw !important;
						margin-bottom: 12rem;
					}
					.canvas-wrapper {
						position: relative;
						.avatarBorder {
							pointer-events: none;
							opacity: 0.7;
							top: 0;
							left: 0;
							width: rem(295);
							height: rem(295);
							position: absolute;
							background: url(../../assets/avatar_bg-black.svg) no-repeat;
							@include bp(768px down) {
								width: 75vw !important;
								height: 75vw !important;
							}
						}
					}
				}
				.canvas-wrapper {
					position: relative;
				}
				canvas {
					height: rem(225);
					@include bp(768px down) {
						width: 75vw !important;
						height: 75vw !important;
					}
				}
			}
		}

		.d-inline-block {
			margin-bottom: 10px;
		}

		.picture-title {
			font-size: 16px;
			font-weight: 700;
			margin-bottom: 0.5rem;
			@include bp(768px down) {
				font-size: 1rem;
				font-weight: 500;
				color: $font-gray;
			}
		}

		.picture-desc {
			@extend %text-hint;
			margin: 0 auto;
			width: 70%;
			@include bp(768px down) {
				width: 80%;
			}
		}

		.upgrade-img-btn {
			margin-top: 20px;
		}

		.upgrade-btn {
			@extend %default-btn;
			@include size(80%, rem(50px));
			background: $green;
			color: #ffffff;
			font-size: 18px;
			font-weight: 500;
		}
	}

	/*COVER CONFIG*/
	@at-root .photo-editor-cover-config {
		.header {
			@extend %header;
			margin-bottom: sr(50px);
		}

		.title {
			@extend %title;
		}

		@at-root .cover-default {
			.cover-preview {
				position: relative;
				z-index: 0;
			}
			.cover-background {
				position: relative;
				width: 100%;
				background: #f0f0f0;
				.aspect-ratio {
					padding-top: calc(100% / 2.025);
				}
				.content {
					@include position(absolute, 0 null null 0);
					@include size();
					margin: 0;
					background: #f0f0f0;
				}
			}

			.cover {
				&-wrapper {
					@include position(absolute, null null 0 40px);
                    @include size(25%, 50%);
                    
                    @include bp(768px down) {
                        height: 30%;
                    }
				}
			}

			.content-image {
				@include size();
				overflow: hidden;
			}

			.d-inline-block {
				transform: translateY(-3px);
				&.full {
					@include size();
				}
			}

			.cover-avatar {
				z-index: 10;
                @include AvatarImage($width: 80px, $height: 80px, $top: -60px, $left: calc(50% - 40px));
                
                @include bp(768px down) {
                    width: 40px;
                    height: 40px;
                    top: -20px;
                    left: calc(50% - 20px);
                }
			}

			.cover-info {
				@include size();
				background: white;
			}

			.upload-control {
				width: 40%;
				margin: 20px auto 0;
				text-align: center;
				@include bp(sm down) {
					width: 80%;
				}
				.upload-btn-wrapper {
					label {
						@include bp(768px down) {
							font-size: 1rem;
							font-weight: 500;
							color: $font-gray;
						}
					}
				}
				.upload-hint {
					width: 50%;
					margin: 0 auto 10px;
					@include bp(768px down) {
						width: 80%;
					}
				}
			}

			.zoom {
				width: 60%;
				margin: auto;
			}
		}
	}

	/*CARD CONFIG*/
	@at-root .photo-editor-card-config {
		.header {
			@extend %header;
			margin-bottom: sr(50px);
		}

		.title {
			@extend %title;
		}

		.content {
			@at-root .profile-picture {
				display: flex;
				@include bp(lg up) {
					flex-wrap: wrap;
				}

				@include bp(sm down) {
					flex-direction: column;
				}

				.profile-picture-preview {
					width: 100%;
                    @include bp(768px down){
                        min-width: 100% !important;
                    }
					@include bp(lg up) {
						width: 60%;
						margin: auto auto 20px;
					}
					.width-card {
						margin: 0 auto;
						max-width: rem(290);
						min-height: rem(410);
						@include bp(768px down) {
							max-width: initial !important;
							min-height: initial !important;
						}
						.ProfileCard {
                            .info-avatar {
                                canvas {
                                    @include bp(768px down){
                                        width: 100% !important;
                                        height: 100% !important;
                                    }
                                }
                            }
							@include adaptive-height-element(1);
							.info-avatar {
								overflow: hidden;

							}
						}
					}
					@at-root .profile-picture-card {
						$ratio: 0.729166;
						position: relative;
						width: 100%;
						.aspect-ratio {
							width: 100%;
							padding-top: calc(100% / #{$ratio});
						}

						.aspect-ratio-content {
							@include position(absolute, 0 null null 0);
							@include size();
							background: #d3d3d3;
							&::after {
								@include size();
								@include pseudo($pos: null);
								background: rgba(240, 47, 23, 0);
								background: linear-gradient(to bottom, rgba(240, 47, 23, 0) 0%, rgba(0, 0, 0, 1) 100%);
							}
						}

						.controls {
							@include position(absolute, 0 0 null null);
							display: flex;
							flex-direction: column;
							padding: 12px;
							color: #ffffff;
							* {
								margin-bottom: 10px;
								&:last-child {
									margin-bottom: 0;
								}
							}

							&-icon {
								@include size(20px);
								display: inline-block;
								background: no-repeat center;
								background-size: contain !important;
								cursor: pointer;
							}
						}

						@for $i from 1 through 4 {
							.controls-icon-#{$i} {
								background-image: url('../../assets/icon-#{$i}.png');
							}
						}

						.info {
							@include position(absolute, null null 0 0);
							padding: 10px 14px;
							color: #fff;
							font-weight: 700;
							font-size: 18px;

							&-avatar {
								@include position(absolute, 0 null null 0);
								@include size();
								overflow: hidden;
							}

							&-messaging {
								@include size(70px);
								margin-bottom: 5px;
								background: url(../../assets/images/message-notification.png) center no-repeat;
								background-size: contain;
							}

							&-name {
								margin-bottom: 15px;
							}

							&-attributes {
								margin-bottom: 15px;
								font-size: 11px;
								font-weight: 200;
								color: #b5b5b5;
							}

							&-footer {
								display: flex;
								justify-content: space-between;
								align-items: center;
								width: 100%;
								bottom: 0;
								padding: 0 10px 5px;
							}

							&-rate {
								font-size: 12px;
								color: #fece2f;
								* {
									display: inline-block;
									margin-right: 4px;
									&:last-child {
										margin-right: 0;
									}
								}
							}

							&-status {
								font-size: 10px;
								font-weight: 200;
								color: #999999;
								vertical-align: middle;
								.circle {
									margin-left: 5px;
									border-radius: 50%;
									border: 1px solid #fff;
									padding: 1px 2px;
								}
								.fa {
									margin: 0;
									vertical-align: middle;
									font-size: 12px;
									color: #ffffff;
								}
							}
						}
					}
				}

				.upload-btn-wrapper {
					margin-top: 1rem;
					label {
						@include bp(768px down) {
							font-size: 1rem;
							font-weight: 500;
							color: $font-gray;
							margin-top: 1.4rem;
							margin-bottom: 1rem;
						}
					}
					@media (max-width: 1200px) {
						text-align: center;
					}
				}

				.upload-hint {
					width: 100%;
					margin: auto;
					text-align: center;
				}

				.zoom {
					width: 80%;
					margin: 10px auto 0;
					text-align: center;
					@include bp(768px down) {
						display: none;
					}
				}

				@at-root .profile-picture-upload {
					text-align: center;
                    @include bp(768px down){
                        width: 100% !important;
                    }
					@include bp(sm up) {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						.picture-upload-title {
							margin-top: -100px;
						}
					}
					.picture-upload {
						&-title {
							font-size: 16px;
							font-weight: 700;
							color: #000000;
							margin-bottom: rem(30px);
							@include bp(768px down) {
								font-size: 1rem;
								color: $font-gray;
								text-align: left;
								margin-bottom: 1rem;
							}
						}

						&-desc {
							@extend %text-hint;
							width: 70%;
							margin: 0 auto rem(60px);
							@include bp(768px down) {
								width: 100%;
								margin: 0 auto 1.75rem;
							}
						}

						&-input {
							text-align: center;
							border: none;
							border-bottom: 1px solid #d3d3d3;
							outline: none;
							font-size: 1.5rem;
							width: 70%;
							color: #000000;
							padding-bottom: 5px;
							background: #ffffff;
							@include bp(768px down) {
								font-size: 0.8rem;
								font-weight: 600;
								width: 100%;
							}
							&:active,
							&:focus {
								border-bottom: 1px solid #ffd210;
							}
							&::placeholder {
								@include bp(768px down) {
									color: #ffe100;
									text-align: left;
								}
							}
						}
					}
				}
			}
		}
	}
}
