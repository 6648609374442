.PriceList {
	.fa-minus {
		width: 1.3125rem;
		height: 1.3125rem;
		border: 2px solid #a3a3a3;
		color: #a3a3a3;
		border-radius: 50%;
		/* margin-right: 0.5rem; */
		cursor: pointer;
	}

	.fa-minus::before {
		position: relative;
		top: 0.125rem;
		left: 0.125rem;
	}
}
