@import "styles/vendor";

.GigFeedTab {
	.SortBy {
		display: none;
	}

	.nothing {
		margin: 50px auto;
		font-size: 26px;
		color: #a3a3a3;
		text-align: center;
	}
}
