@import "styles/vendor";

.ArtistsTab {
    @include bp(xs down) {
        display: flex;
        justify-content: center;
        margin-bottom: 41px;
        flex-direction: column;

    }

    .SortBy {
        display: none;
    }
    .SpecificCategories{
        .top-categories{
            .top-title {
                .SVGInline {
                    position: relative;
                    svg{
                        height: 13px;
                        position: absolute;
                        left: -20px;
                        top: 3px;
                        @include bp(sm up){
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
