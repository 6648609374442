@import "styles/utils";
@import 'breakpoints.scss';

.hasHover {
	.login-page .content .form-header .remember-me .toggle-text:hover {
		cursor: pointer;
		color: $yellow;
	}
}

.button-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;

	.gg-btn {
		height: rem(50);
		border-radius: 50px 50px 50px 50px;
		font-size: rem(18);
		font-family: 'Montserrat', sans-serif;
		font-weight: 300;
		margin: 0 0 rem(13);
		transition: width 0.2s;
		color: #fff;

		&:hover {
			width: calc(100% + 26px);
		}
		&.facebook {
			background-color: $blue;
		}
		&.linkedin {
			background-color: $light-blue;
		}
		&.google {
			background-color: $red;
		}
		&.register {
			background-color: $yellow;
		}
	}
}

.input-field {
	border: none;
	border-bottom: 1px solid $gray;
	font-size: rem(25);
	width: 100%;
	margin-bottom: rem(20);
	border-radius: 0;
	font-weight: 300;
	&::placeholder {
		color: $gray-hue-3;
	}
}
.password-field {
    @include bp(768px down) {
        margin-top: -10px;
    }
	position: relative;
	.toggle-password {
		position: absolute;
		//top: rem(25);
		right: 0;
		.password-icon {
			width: 28px;
			height: 14px;
		}
	}
}

.login-page,
.register-page {
	background-color: #ffffff;
	.header {
		height: 100px;
		display: flex;
		align-items: center;
		padding: 0 37px;

		.logo {
			width: 35px;
			display: inline-block;
			height: 36px;
			flex: 0 0 35px;
		}
		.gap {
			flex: 1 1 auto;
		}
		.back {
			width: 25px;
			height: 25px;
			display: inline-block;
			flex: 0 0 25px;
		}
	}
	.content {
		.main-text {
			font-size: rem(36);
			font-family: 'Montserrat', sans-serif;
			font-weight: 300;
            margin-bottom: 50px;
            
            @include bp(768px down) {
                margin-bottom: 25px;
            }
		}

		.oauth-wrapper {
            margin-bottom: 35px;
            @include bp(768px down) {
                margin-bottom: 18px;
            }

			&.form-filled {
				opacity: 0.5;
			}
			.description-wrapper {
				width: 100%;
				.oauth-description {
					font-size: rem(15);
					// color: $gray;
					color: #000;
					font-family: 'Montserrat', sans-serif;
					font-weight: 300;
					margin-bottom: 17px;
				}
			}
		}
		.login-form {
			.form-footer {
				.additional {
					display: flex;
					justify-content: space-between;
                    margin-bottom: rem(13);
                    flex-direction: column;
					a:hover {
						color: #000;
						cursor: pointer;
					}
				}
				p {
					// color: $gray;
					color: #000;
					font-size: rem(13);
				}
				.terms {
					text-align: center;
					font-weight: 300;
                    margin-bottom: rem(95);
                    @include bp(768px down) {
                        margin-bottom: rem(16);
                    }
				}
			}
			.form-header {
				display: flex;
				justify-content: space-between;
				flex-direction: column;

				.description {
					// color: $gray;
					color: #000;
					font-size: rem(15);
					font-family: 'Montserrat', sans-serif;
					font-weight: 300;
					margin-bottom: rem(12);
					line-height: 1;
				}
				.remember-me {
                    display: flex;
                    justify-content: end;
                    
					// span.SVGInline {
					// 	height: 16px;
					// 	width: 21px;
					// 	margin: -5px 11px 0 0;
					// }
					.toggle-text {
						font-size: 0.75rem;
						font-family: 'Montserrat', sans-serif;
						font-weight: 400;
						// color: $gray;
						color: #000;
						&.selected {
							color: #000;
						}
					}
				}
			}

			.login-btn {
				background-color: $yellow;
				color: #ffffff;
				margin-bottom: rem(95);
			}
		}
	}
}
