@import '../../vendor';

$transition-delay: 0.3s;
$grey: #a3a3a3;

.plans-and-venue-profile {
	$pm-width: to-rem(148);
	$pm-height: to-rem(198);
	box-sizing: content-box;
	margin: $pm-width / 2 1rem $pm-width / 2 $pm-height / 2;
	text-align: center;
	font-size: calc(30rem / 32);
	font-weight: 300;
	.band-genre-form__search .fa {
        @include tabletFontSize(1rem !important);
        @include phoneFontSize(.8rem !important);
        @include bp(768px down){
            top: 55% !important;
        }
        @include bp(520px down){
            top: 60% !important;
        }

    }
    .test {
        @include bp(768px down){
            overflow: hidden;
        }
        input {
            @include bp(768px down){
                border: 1px solid #969696 !important;
                padding-left: 1rem !important;
            }
        }
        div:first-child {
            @include tabletFontSize(1rem);
            @include phoneFontSize(.8rem);
            @include bp(768px down){
                padding-left: 1rem;
                padding-right: 2rem;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;
                overflow: hidden;
            }

        }
        div:nth-child(3) {
            hr {
                @include bp(768px down){
                    display: none;
                }
            }
        }
    }
    input {
        @include tabletFontSize(1rem !important);
        @include phoneFontSize(.8rem !important);
    }
    @include bp(768px down){
        margin: 2.3125rem 1.5rem 1rem !important;
        border: 1px solid #cacaca;
        padding: .5rem;
        -webkit-box-sizing: border-box !important;
        -moz-box-sizing: border-box !important;
        box-sizing: border-box !important;
    }

    h5 {
        color: #000 !important;
        font-weight: 400;
    }
    .occasions {
        .occasions-header {
            margin-top: 1rem;
            color: #000;
            font-size: 1rem;
            font-weight: 500;
            display: flex;
            position: relative;
            width: 100%;
        }
    }
    .filled {
        color: #000;
    }
    @include bp(sm down) {
        margin: $pm-width / 4 $pm-height / 4;
    }
    &-form {
        &__item {
            &-header {
                @include informative-title;
                h4 {
                    font-size: 1rem;
                    font-weight: bold;
                }
            }
        }
    }
    .plans-selected-menu {
        width: 71.7%;
        @include bp(sm down) {
            width: 100%;
        }
        .plans-selected-menu__item-wrapper {
            padding: rem(33) 0;
            margin-left: 0;
            margin-right: 0;
            display: flex;
            @include bp(sm down) {
                flex-direction: column;
            }
            .plans-selected-menu__item {
                $ms-width: to-rem(16px);
                margin: 0.8125rem $ms-width;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: stretch;
                float: none;
                width: calc(100% / 3);
                span {
                    display: inline-block;
                    width: rem(100);
                    border-radius: 50px;
                    padding: 0.9375rem 0;
                    border: 1px solid #a3a3a3;
                    cursor: pointer;
                }
                input[type='radio']:checked + span {
					background: #ffd210 !important;
					border: 1px solid #ffd210 !important;
					color: #fff;
				}
				&.top {
					justify-content: flex-end;
					.plans-item {
						&__title {
							margin-bottom: 14%;
						}
					}
				}
				&.bottom {
					justify-content: flex-start;
				}
				.plans-item {
					&__title {
						color: $gray-hue-2;
						font-size: rem(34 / 2);
						font-weight: 500;
					}
					&__price {
						color: $gray-hue-2;
						font-size: rem(28 / 2);
						font-weight: 300;
						padding-bottom: rem(12);
						border-bottom: 1px solid $gray;
					}
					&__description {
						color: $gray-hue-2;
						font-size: rem(20 / 2);
						font-weight: 300;
						padding: rem(12) 0;
					}
				}
			}
			&.bottom {
				label {
					margin-bottom: rem(10);
				}
			}
		}
		&-wrapper {
			display: flex;
			justify-content: flex-end;
			align-items: flex-start;
			position: relative;
			@include bp(sm down) {
				flex-direction: column;
			}
		}
	}
	.talent-error {
		position: relative;
		bottom: 2px;
		font-size: 12px;
		line-height: 12px;
		color: rgb(244, 67, 54);
		transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
		text-align: start;
	}
}
