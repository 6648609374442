@import "styles/vendor";

.GeneralCategories {
    @include bp(xs down) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        max-width: 100%;
        width: 100%;
        padding: 0 3%;

        .ArtistCategoryBlock {
            width: calc(50% - 0.5rem);
            position: relative;
            border-bottom: 0;
            display: block !important;
            margin: 0.5rem 0.25rem;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            cursor: pointer;
            
            .category-artists, .description, .view-all-button {
                display: none !important;
            }
            .size-block {
                padding-top: 100%;
                width: 100%;
                display: block;
                height: 0;
                & > .container {
                    display: flex;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    margin: 0;
                    padding: 0;
                    .category-description {
                        flex-direction: column;
                        justify-content: flex-end;
                        background: no-repeat center center;
                        background-size: 100%;
                        h1 {
                            text-align: center;
                            padding: 0.8rem 0.1875rem;
                            font-size: 0.9rem;
                            margin: 0;
                            font-weight: 600;
                            white-space: nowrap;
                            background: rgba(255, 255, 255, 0.6);
                        }
                    }
                }
            }
            &.full-width {
                width: calc(100% - 1rem);

                .size-block {
                    padding-top: 50%;
                }
            }
        }
    }
    @include bp(sm up) {
        .category-description {
            background-image: none !important;
        }
    }
    .ArtistCategoryBlock {
        .size-block {
            width: 100%;
        }
    }
}
