@import "styles/vendor";

.FilterRadio {
    width: sr(300, 1.5);
    label {
        max-width: sr(200, 1.5);
        overflow-x: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
    }
}
