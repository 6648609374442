@import "../utils";

.share {
    &-header {
        background-color: #000;
        height: rem(60);
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        &__button {
            border: none;
            background-color: #000;
            color: #fff;
            font-size: 0.85rem;
            font-weight: 600;
            span {
                margin-right: rem(10);
            }
            &:focus {
                outline: none;
            }
        }
    }
    &-pop-up {
        .skylight-close-button {
            font-size: 3rem !important;
            top: rem(-5) !important;
        }
        .content-title {
            width: 100%;
            color: #000;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 0.75rem;
            color: rgba(0, 0, 0, 0.5);
            margin: rem(20) 0 rem(30);
        }
        .button-wrapper {
            margin: 0 auto;
            width: 60%;
            display: flex;
            flex-direction: column;
            .SVGInline {
                width: rem(24);
                height: rem(24);
                &-svg {
                    width: rem(24);
                    height: rem(24);
                    cursor: pointer;
                }
            }
            &__row {
                width: 100%;
                margin: rem(20) 0;
                display: flex;
                justify-content: space-around;
            }
        }
        .publish-button {
            padding: rem(5) 0;
            width: 60%;
            border: 0;
            border-radius: rem(16);
            background-color: #ffd210;
            color: #fff;
            &[disabled] {
                background-color: rgb(221, 221, 221);
            }
            &-wrapper {
                width: 100%;
                margin: rem(30) 0;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        .publish {
            &-input {
                border: 0;
                background: none;
                outline: none;
                border-bottom: 1px solid $gray;
                &[placeholder] {
                    text-align: center;
                    color: #737373;
                }
            }
        }
    }
    &-button {

    }
}
