@import "styles/vendor";

.AlertPopUp__modal {
        min-width: 40%;
        .modal-content {
            border: none;
            border-radius: 0;
            .modal-header {
                border-radius: 0;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                height: rem(50);
                background-color: $red;
                padding-left: rem(10);
                padding-right: rem(10);
                color: #ffffff;
                line-height: 1.25;
                font-size: rem(30);
                font-weight: 400;
                text-transform: uppercase;
                text-align: center;
                cursor: default;
                position: relative;
                .modal-title {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    align-self: center;
                    height: 100%;
                    line-height: rem(50);
                }
                .close {
                    font-size: 4rem;
                    font-weight: 100;
                    color: #ffffff !important;
                }
            }
            .modal-body {
                padding: rem(65) rem(68);
                color: #333;
                font-size: rem(18);
                font-weight: 300;
                @include bp(sm down) {
                    padding: rem(65 * .3) rem(68 * .3);
                }
            }
            .modal-footer {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-content: center;
                align-items: center;
                justify-content: stretch;
                padding: 0;
                border: none;
                .ok-btn,
                .cancel-btn {
                    overflow: hidden;
                    box-sizing: border-box;
                    width: 100%;
                    height: rem(50);
                    padding: 0 rem(10);
                    margin: auto;
                    border: none;
                    background-color: $yellow;
                    color: #ffffff;
                    font-size: rem(18);
                    line-height: rem(50);
                    cursor: pointer;
                    font-weight: 600;
                    text-align: center;
                    border-radius: 0;
                }
                .ok-btn {
                    border-right: 1px solid #ffffff;
                }
            }
        }
    }


