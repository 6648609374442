@import "../vendor";

.price-list-popup {
  height: 100%;
  text-align: left;
  padding: 5vh 5vw;

  .title {
    font-size: rem(32);
    font-weight: 600;
  }

  .name {
    font-size: rem(22);
    font-weight: 300;
  }

  .price-items {
    margin-top: rem(30);
    display: block;
    height: 70%;
    overflow-y: auto;

    & > li {
      display: flex;
      height: rem(60);
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid lightgrey;
      font-weight: 300;
      font-size: rem(20);

      b {
        font-weight: 500;
      }
    }

    & > li:last-child {
      border: none;
    }

    & > li.no-prices {
      color: lightgrey;
      font-size: rem(26);
      justify-content: center;
    }
  }
}
