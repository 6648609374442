@import "styles/vendor";
.PopoverMain {
    .BudgetFilter {
        z-index: 1900;
        display: flex;
        flex-direction: row;
        position: relative;
        width: sr(1015, 1.5);
        max-height: sr(150, 1.5);
        border-top: 5px solid #ffd210;
        align-items: center;
        align-content: center;
        justify-content: flex-start;
        padding: rem(20);
        .currency,
        .budgetFrom,
        .budgetTo {
            width: auto !important;
            margin: 0 rem(20);
        }
        .budgetFrom,
        .budgetTo {
            min-width: 6.5rem;
        }
        .range-wrapper {
            margin: 0 sr(20, 1.5);
            width: 50rem;
        }
        @include bp(768px down){
            max-width: 100%;
            max-height: none;
            flex-direction: column;
            .currency{
                order: 1;
            }
            .budgetFrom{
                order: 2;
            }
            .budgetTo{
                order: 3;
            }
            .range-wrapper{
                order: 4;
                max-width: 100%;
            }
        }
    }
}
