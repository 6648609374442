
.square {
    position: relative;
    width: 100%;
}
  
.square:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}
  
.square-content {
    position: absolute;
    width: 100%;
    height: 100%;
}