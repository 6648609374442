@import "styles/vendor";

.LocationFilter {
    z-index: 1900;
    display: flex;
    flex-direction: column;
    position: relative;
    width: sr(700, 1.5);
    max-height: sr(680, 1.5);
    border-top: 5px solid #ffd210;
    padding: 12px;
    padding-top: 1.25rem;
    max-width: 100%;
    @include bp(768px down){
        font-size: 12px;
    }
    .location-input {
        margin: rem(20);
        position: relative;
        .fa {
            position: absolute;
            left: 16px;
            top: 8px;
            color: #000000;
            z-index: 1;
        }
        &__autocomplete {
            width: 100% !important;
            input {
                border: 1px solid rgb(196, 196, 196) !important;
                background-color: rgb(255, 255, 255) !important;
                width: 100% !important;
                height: sr(48, 1.5) !important;
                padding-left: sr(65, 1.5) !important;
                font-family: Montserr1at, serif;
                font-size: sr(24, 1.5) !important;
                transition: all .3s;
                box-shadow: 0 6px 8px 0 rgba(26, 24, 25, 0.07);
                &:focus {
                    box-shadow: 0 rem(8) rem(10) 0 rgba(26, 24, 25, 0.1);
                }
            }
        }
    }
    .location-list {
        display: flex;
        padding: 0 rem(20) rem(20);
        flex-wrap: wrap;
        & > div {
            margin-right: rem(10);
        }
    }
}
