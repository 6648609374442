.missing-fields {
	.title {
		font-size: 0.8rem;
		font-weight: 500;
		margin-top: 1rem;
	}
	.sub-title {
		font-size: 0.6rem;
		color: #727272;
		padding-bottom: 1rem;
		border-bottom: 1px solid #e1e1e1;
	}

	.missing-field-link {
		padding-top: 1rem;
		padding-bottom: 1rem;
		margin-left: 1.5rem;
		margin-right: 1.5rem;
		font-size: 0.8rem;
		color: red;
		cursor: pointer;
		border-bottom: 1px solid #e1e1e1;
	}
}
