@import '../../utils';
@import '../../breakpoints';

.splash {
	height: 750px;
	// height: 768px;
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	@include bp(sm down) {
		height: 65.9vh;
	}
	@include bp(768px down) {
		height: 65.5vh;
	}
	// &:after {
	// 	content: ' ';
	// 	height: 300px;
	// 	width: 100%;
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// }
	&__image {
		//height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		//object-fit: cover;
	}

	&__title {
		font-size: 5rem;
		font-weight: 500;
		color: #fff;
		text-align: center;
		position: relative;
		text-shadow: 1px 1px 1px #000;
	}

	&__cta {
		background: #fff;
		width: 100%;
		font-size: 0.875rem;
		position: absolute;
		top: 0;
		// @include bp(sm only) {
		// 	bottom: 0;
		// 	left: 0;
		// }
		.container {
			display: flex;
			justify-content: flex-end;
		}
	}

	&__container {
		width: 73.37925446%;
		padding: 25px 0;
		position: relative;
	}

	&__looking {
		font-size: 0.75rem;
		color: #fff;
		font-weight: 600;
		position: absolute;
		top: -20px;
		left: 0;
		width: auto;
		transform: translateY(-100%);
		i {
			font-size: 150%;
			margin-right: 10px;
			color: $yellow;
		}
	}

	&__content {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__copy {
		word-break: break-all;
		overflow: hidden;
		font-size: 0.75rem;
		color: rgba(0, 0, 0, 0.5);
		width: 38.59745997%;
	}

	&__book {
		margin: 0 15px;
		text-align: center;
		position: relative;
	}

	&__actions {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		a {
			display: block;
			font-size: 1.125rem;
			margin: 2px 0;
			&:hover {
				color: #e4222e;
			}
		}
	}

	&__nav {
		display: flex;
		justify-content: flex-end;
		align-items: flex-start;
		a {
			margin-left: 25px;
		}
	}

	&__edit {
		position: absolute;
		top: -15px;
		right: 20px;
		transform: translateY(-100%);
		text-decoration: none;
		font-size: 0.75rem;
		font-weight: 600;
		color: #fff;
		transition: all 0.22s;

		&:hover {
			color: $yellow;
		}

		i {
			margin-left: 10px;
			font-size: 150%;
		}
	}
}
