@import "styles/vendor";

.SelectWithStepsCategories {
    width: auto;
    @include bp(768px down){
        font-size: 12px;
        overflow-x: scroll;
        .parent{
            min-width: 100px;
            max-width: 140px;
        }
    }
    .SelectWithStepsCategories {
        border-left: 1px solid #c4c4c4;
        padding-left: sr(20, 1.5);
    }
    & > .parent {
        overflow-x: hidden;
        overflow-y: auto;
        max-height: rem(360);
        padding: 1rem 0;
    }
    display: flex;
    margin: 0 0 0 rem(20);
    flex-direction: row;
    .categories-group-column {
        &:first-child {
            border-left: none;
        }
        border-left: 1px solid #c4c4c4;
    }
}
