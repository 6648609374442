@import "styles/vendor";

.about {
    //padding: 1rem;
    text-align: left;
    border-bottom: 1px solid #e1e1e1;
    &__header {
        padding: 1rem 0;
    }
    &__copy {
        padding: 0 1rem;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 0.75rem;
        font-weight: 300;
        // @include tabletFontSize(1.5rem);
        // @include phoneFontSize(.8rem);
        &_style {
            padding-bottom: 1rem;
        }
        a {
            font-weight: 600;
            color: #e4222e;
            &:hover {
                color: black;
            }
        }
        &--chip span {
            white-space: normal !important;
            word-break: break-all;
            max-width: rem(270);
        }
        .question {
            font-weight: 500;
        }
    }
}
