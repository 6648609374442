@import "styles/vendor";

.SortableList {
    display: grid;
    grid-template-columns: repeat(3, calc((100% / 3) - 8px));
    grid-gap: 12px;

    @include bp(sm down) {
        grid-template-columns: 100%;
    }
}