@import '../vendor';

.errors-popup {
  @include alert-modal;
}

.position-number {
    border-radius: 50%;
    background-color: #141726;
    color: #ffffff;
    width: 1.5rem;
    height: 1.5rem;
    text-align: center;
    line-height: 1.5rem;
    position: absolute;
    right: 1.25rem;
    bottom: 1.25rem;
    //z-index: 1020;
    //z-index: auto;
}

.btn-group, .btn-group-vertical {
    position: relative;
    vertical-align: middle;
    display: block;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
    color: #ffd210;
    background-color: #545b62;
    border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: none;
}
.dropdown-toggle::after {
    margin-left: 2em;
}

.dropdown-menu.show{
    position: absolute;
    will-change: transform;
    width: 100%;
    top: 100% !important;
    left: 80% !important;
    transform: translateX(-80%) !important;
}
