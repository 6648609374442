@import "../../vendor";

.plans-selected-menu {
    &__wrapper {
        @at-root .plans-selected-menu {
            &__item {
                .checked {
                    background: $yellow;
                    color: #fff;
                    font-weight: 500;
                    border: 1px solid $yellow!important;
                    span {
                        cursor: pointer;
                    }
                }
            }
        }
    }
    &-wrapper {
        .view-button {
            @include bp(sm up) {
                @include position(absolute, rem(50) null null 0);
            }
        }
    }
}

.band-genre-form {
    &__container {
        & > div {
            display: flex;
            flex-wrap: wrap;
            h5 {
                width: 100%;
            }
        }
        margin: 1rem 0;
        h5 {
            margin-bottom: rem(23 / 2);
        }
    }
    &__item {
        .talent-tags-controller__control {
            display: flex;
            flex-direction: column;
            margin: 0;
            @include bp(768px down){
                margin: .1rem !important;
            }
        }
        h5 {
            font-size: rem(24 / 2);
            text-align: left;
            color: $gray-hue-2 !important;
        }
        h6 {
            font-size: rem(20 / 2);
            text-align: left;
            color: $gray-hue-2 !important;
        }
    }
    &__search {
        width: 100%;
        text-align: left;
        position: relative;
        .fa {
            position: absolute;
            font-size: rem(20);
            top: rem(30);
            right: rem(10);
            color: $gray-hue-2;
        }
        // .fa-search {
        //     right: rem(40);
        // }
    }
    &__add-block {
        width: auto;
        display: flex;
        position: relative;
        @include bp(sm down) {
            flex-direction: column;
        }
    }
    &__description {
        font-size: rem(20 / 2);
        color: $gray-hue-2;
    }
}
