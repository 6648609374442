@import "styles/vendor";

.SelectWithStepsLocation {
    width: auto;
    .SelectWithStepsLocation {
        border-left: 1px solid #c4c4c4;
        padding-left: sr(20, 1.5);
        .FilterRadio {
            label {
                max-width: none;
            }
        }
    }
    & > .parent {
        overflow-x: hidden;
        overflow-y: auto;
        max-height: rem(360);
    }
    display: flex;
    margin: 0 0 0 rem(20);
    flex-direction: row;
    .locations-group-column {
        &:first-child {
            border-left: none;
        }
        border-left: 1px solid #c4c4c4;
    }
}
