@import '../vendor';

$transition-delay: 0.3s;
$grey: #a3a3a3;

.skip-button {
	button {
		@include tabletFontSize(1rem);
		@include phoneFontSize(0.8rem);

		@include bp(768px down) {
			border: none !important;
			white-space: nowrap;
			text-decoration: underline;
		}
	}
}
.profile-edit__main .skip-button {
	@include bp(768px down) {
		margin: 0.625rem 5.1875rem !important;
	}
}
.profile-edit__missing {
	.skip-button {
		button {
			@include tabletFontSize(1rem !important);
			@include phoneFontSize(0.8rem !important);

			@include bp(768px down) {
				background: rgb(255, 221, 0) !important;
			}
		}
		@include bp(768px down) {
			font-size: 0.8rem;
			margin: 0px 1rem 1rem !important;
		}
	}
}
.personal-info {
	box-sizing: content-box;
	margin: rem(148 * 0.5) rem(198 * 0.5);
	text-align: center;
	font-size: calc(30rem / 32);
	font-weight: 300;
	@include bp(768px down) {
		border: 1px solid #969696;
		margin: 2.3125rem 1.5rem !important;
		padding: 1rem;
	}
	input {
		@include tabletFontSize(1rem !important);
		@include phoneFontSize(0.8rem !important);

		&::placeholder {
			@include bp(768px down) {
				font-size: 0.8rem;
				padding-bottom: 1rem;
			}
		}
	}
	&__info {
		color: #000 !important;
		font-size: calc(30rem / 32) !important;
		font-weight: 500;
		text-align: left;
		width: 100%;

		.info-popup-window {
			position: absolute;
			left: -37px;
			top: -3px;
			@include bp(768px down) {
				display: none;
			}
		}
	}
    &__address-additional-info {
        color: #dc3545 !important;
        font-size: calc(30rem / 32) !important;
        font-weight: 300;
        text-align: left;
        width: 100%;
        margin-bottom: 10px;

        .info-popup-window {
            position: absolute;
            left: -37px;
            top: -3px;
            @include bp(768px down) {
                display: none;
            }
        }
    }
	&__date-of-birth {
		.birth-date-picker {
			&__item {
				div {
					div:nth-child(2) {
						div:first-child {
							div:nth-child(2) {
								@include tabletFontSize(1rem !important);
								@include phoneFontSize(0.8rem !important);
								@include bp(768px down) {
									text-align: left;
								}
							}
						}
					}
				}
			}
			div:first-child {
				@include tabletFontSize(1rem !important);
				@include phoneFontSize(0.8rem !important);
			}
			&__error {
				position: absolute;
				bottom: -10px;
				left: 0;
				font-size: 14px;
				color: $red;
			}
		}
	}
	div {
		position: relative;
	}
	&__info-big-title {
		position: relative;
	}
	.filled {
		color: #a3a3a3;
	}
	@include bp(sm down) {
		margin: rem(148 * 0.25) rem(198 * 0.25);
	}
	@include form-input-color;

	&__fields {
		position: relative;
		text-align: left;
        margin-bottom: rem(97 / 2);

        @include bp(768px down) {
            margin-bottom: 2rem;
        }

		// div:nth-child(2),
		// div:nth-child(3) {
		// 	div:first-child {
		// 		font-size: 0.8rem;
		// 		color: rgba(0, 0, 0, 0.8);
		// 	}
		// }
		.error {
			@include error;
		}

		.fa {
			&-stack {
				position: absolute;
				left: calc(-70rem / 32);
				top: 0;
				font-size: calc(20rem / 32);
			}
		}

		.curriculum-vitae-form__item {
			&-header {
				h4 {
					font-size: 0.9375rem;
				}
			}
		}
	}

	&__info {
		color: #000;
		font-size: calc(30rem / 32);
		font-weight: 500;
		text-align: left;
		width: 100%;
		&-big-title {
			color: #000;
			font-weight: 500;
			text-align: left;
			padding-bottom: rem(95 / 4);
			font-size: rem(36 / 2);
		}
	}

	&__input {
		width: 100%;
		border: none;
		border-bottom: 1px solid $grey;
		outline: none;
		color: #000000;
		font-size: calc(50rem / 32);
		font-weight: 300;
		transition: border-color $transition-delay;

		//&:hover,
		&:focus,
		&:active,
		&.active {
			border-bottom-color: $yellow;
		}

		&::-webkit-input-placeholder {
			color: $grey;
		}
		&::-moz-placeholder {
			color: $grey;
		}
		&:-moz-placeholder {
			color: $grey;
		}
		&:-ms-input-placeholder {
			color: $grey;
		}
	}

	&__checkbox-wrapper {
		@extend %checkbox-wrapper;
		div {
			div {
				label {
					@include tabletFontSize(1rem);
					@include phoneFontSize(0.8rem);
				}
			}
		}
	}

	&__calendar {
		height: calc(135rem / 32);
		width: calc(600rem / 32);
		margin-left: 0;
		margin-right: auto;
		background-color: $grey;
		align-items: center;
		color: #fff;
	}

	&__date {
		position: relative;
		text-align: left;
		margin-top: rem((97 - 59) / 2);
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-end;
		@include bp(768px down) {
			padding: 0.5rem;
		}
		&-of-birth {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-top: calc(46rem / 32);

			& > div:not(.personal-info__calendar) {
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-direction: row-reverse;
				@include bp(sm down) {
					flex-direction: column-reverse;
				}
			}

			[type='checkbox'] {
				visibility: hidden;
			}
		}

		&-age {
			color: #000000;
			font-size: calc(22rem / 32);
			font-weight: 300;
		}

		.fa {
			&-stack {
				position: absolute;
				left: calc(-70rem / 32);
				top: 0;
				font-size: calc(20rem / 32);
			}
		}
	}
	&__contact-information {
        margin-top: rem(142 / 2);
        @include bp(768px down) {
			margin-top: 1.5rem;
		}
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		div:nth-child(3) {
			div:first-child {
				@include tabletFontSize(1rem);
				@include phoneFontSize(0.8rem);
			}
		}
		.contact-information__select-wrapper {
			div {
				div {
					div:first-child {
						@include tabletFontSize(1rem !important);
						@include phoneFontSize(0.8rem !important);
					}
				}
			}
		}
		.personal-info {
			&__info {
                margin-bottom: rem(61 / 2);
                @include bp(768px down) {
                    margin-bottom: 1rem;
                }
			}
		}
	}
	&__curriculum-vitae {
		@at-root .curriculum-vitae-form {
			&__item {
                margin-top: rem(96 / 2);
                @include bp(768px down) {
                    margin-top: 1.5rem;
                }

				&-header {
					@include informative-title;
				}
			}
			&__file-wrapper {
				display: flex;
				align-items: center;
				margin-top: rem(20);
				@include bp(sm down) {
					flex-direction: column;
				}
			}
			&__text {
				font-size: rem(14);
				color: $grey;
				font-weight: 300;
				text-align: left;
				margin-right: rem(163 / 2);
			}
		}
		.input-file {
			&__button {
				width: 9rem;
				padding: rem(10) 0;
				border-radius: rem(32);
				font-size: 0.85rem;
				font-weight: 500;
				color: #fff;
				background-color: $yellow;
				display: flex;
				justify-content: center;
				align-items: center;
				img {
					height: rem(14);
					position: relative;
					top: rem(3);
					margin-right: rem(4);
				}
				&:hover {
					cursor: pointer;
				}
			}
		}
	}

	&__next {
		@include main-button;
		&:disabled {
			@include main-button__disabled;
		}
	}

	// Modal
	.skylight {
		&-wrapper {
			display: flex;
		}

		&-close-button {
			right: calc(40rem / 32) !important;
			font-size: calc(130rem / 32) !important;
			line-height: 0.73;
		}
	}
	@include alert-modal;
	.curriculum-vitae-form {
		&__item {
			&-header {
				@include informative-title;
			}
		}
		&__text {
			font-size: rem(14);
			color: $grey;
			font-weight: 300;
			text-align: left;
			@include tabletFontSize(1rem);
			@include phoneFontSize(0.7rem);
		}
	}
	.input-file {
		&__button {
			width: 9rem;
			padding: rem(10) 0;
			border-radius: rem(32);
			font-size: 0.85rem;
			font-weight: 500;
			color: #fff;
			background-color: $yellow;
			display: flex;
			justify-content: center;
			align-items: center;
			@include tabletFontSize(0.8rem !important);
			@include phoneFontSize(0.65rem !important);
			@include bp(768px down) {
				background: #fff;
				border: 1px solid #e3e3e3;
				color: #5e5f61;
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			img {
				height: rem(14);
				position: relative;
				top: rem(3);
				margin-right: rem(4);
			}
			&:hover {
				cursor: pointer;
			}
		}
	}
}

.google-location-input {
	input {
		&::placeholder {
			opacity: 0;
		}
	}
}

#manager_location {
	&::placeholder {
		opacity: 0;
	}
}
