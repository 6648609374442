@import "../../vendor";

.ability-tree {
    font-family: inherit !important;
    font-weight: 300 !important;
    color: inherit !important;
    min-height: 48px !important;
    margin-top: 0.5rem !important;

    .ant-select-selection__rendered {
        margin: 0 !important;
    }

    .ant-select-selection {
        border: none !important;
        border-radius: 0 !important;
        border-bottom: 1px solid #c4c4c4 !important;

        box-shadow: none !important;
        -webkit-box-shadow: none !important;

        .anticon-down:before {
            content: unset;
        }
    }
}

.ability-tree-gig-feed {
    font-family: inherit !important;
    font-weight: 300 !important;
    color: inherit !important;
    min-height: 48px !important;
    margin-top: 2.5rem !important;

    .ant-select-selection__rendered {
        margin: 0 !important;
    }

    .ant-select-selection__placeholder {
        color: #000000 !important;
        font-size: 0.875rem;
    }

    .ant-select-selection {
        border: none !important;
        border-radius: 0 !important;
        border-bottom: 1px solid #c4c4c4 !important;

        box-shadow: none !important;
        -webkit-box-shadow: none !important;

        .anticon-down:before {
            content: unset;
        }
    }
}

.ability-tree-dropdown {
    font-family: inherit !important;
    font-weight: 400 !important;
    color: #a3a3a3 !important;

    padding-top: 5px !important;

    .ant-select-tree {
        font-family: inherit !important;
    }

    .ant-select-tree-node-content-wrapper {
        &:hover {
            background-color: #ffeeaa !important;
        }
        &.ant-select-tree-node-selected {
            background-color: #ffd210 !important;
        }
    }
    .anticon-caret-down:before {
        content: unset;
    }
}
