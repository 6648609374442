@import "../vendor";

.social-picture-list-wrapper {
    // @extend %EditMediaContent;
    margin: 1.3rem 1.5rem;
    border: none;
    @include bp(768px down){
        margin: 1.3rem 1.5rem;
        padding: .5rem;
        border: 1px solid;
    }
    .hover-description {
        opacity: 0;
        transition: .3s;
    }
    & .wrapper {
        overflow: inherit;
        &:hover .hover-description {
            margin-top: 20px;
            opacity: 1;
        }
    }
    .edit-picture-list-container:nth-child(4){
        .aspect-ratio-container {
            @include bp(768px down) {
                padding-top: calc(50%);
            }
        }
    }
}

.content-reposition {
    margin-bottom: calc(50rem / 32);
    @include bp(768px down){
        display: none;
    }
    .reposition-btn {
        height: calc(50rem / 32);
        padding: 0 calc(25rem / 32);
        border: 1px solid #a3a3a3;
        outline: none;
        border-radius: 50px;
        font-size: 10px;
        font-family: Montserrat, serif;
        font-weight: 500;
        background-color: white;
        color: black;
        vertical-align: middle;
        line-height: calc(50rem / 32);
        &::before {
            @include pseudo(inline-block, relative);
            @include size(13px);
            transform: translateY(-1px);
            margin-right: 10px;
            background: url(../../assets/reposition.png) center center no-repeat;
            background-size: cover;
            vertical-align: middle;
            line-height: calc(50rem / 32);
        }
        &.active {
            background: #ffd210;
        }
    }
}

.edit-picture-list-container {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .edit-overlay__wrapper {
        position: absolute;
        top: 0;
    }
}

.edit-picture-item-wrapper {
    position: relative;
    width: percentage(1 / 3);
    padding: 10px;
    @include bp(sm down) {
        width: 100%;
    }
    .edit-add-new {
        .wrapper {
            height: 100%;
        }
    }
    &.add-more-block {
        width: percentage(1 / 3);
        .aspect-ratio-container {
            padding-top: 142%;
        }
        @include bp(sm down) {
            width: 100%;
        }
    }
    .aspect-ratio-container {
        position: relative;
        width: 100%;
        padding-top: calc(142%);
    }


    .aspect-ratio-content {
        @include position(absolute, 0 null unll 0);
        @include size();
        display: flex;
        video {
            display: none;
        }
        .picture-item {
            &__image {
                @include size();
                padding: rem(20);
                background-size: cover;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
            }
            &__title {
                color: #fff;
                font-size: rem(16);
            }
            &__subtitle {
                color: $gray;
                font-size: rem(10);
            }
            &__button {
                color: #fff;
                background: none;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 0;
                span:first-of-type {
                    img {
                        width: rem(20);
                        height: rem(20);
                        margin-right: rem(5);
                    }
                }
                span {
                    #upload_svg {
                        fill: #fff;
                    }
                }
            }
        }
    }
}

.edit-add-new {
    display: flex;
    width: 100%;
    height: 100%;
    align-content: center;
    align-items: center;
    justify-content: center;
/*    @include bp(768px down){
        margin: 2rem 0;

    }*/
    .wrapper {
        min-height: auto;

        &.open {
            background-color: $light-gray;
            padding: rem(10) rem(15);
            box-shadow: rem(5) rem(5) rem(10) rem(-5) rgba(0, 0, 0, 0.5);
            margin: auto;
            height: 100%;
            .wrapper-input {
                width: 100%;
                input.name-input, input.hashtags-input {
                    @include bp(768px down){
                        font-size: 1rem;

                    }
                    @include bp(520px down){
                        font-size: .7rem;
                    }
                }
            }
            .name-input {
                width: 100%;
                color: #000;
                background: none;
                outline: none;
                border: none;
                border-bottom: 1px solid $gray;
                //margin-top: rem(10);
                &::placeholder {
                    color: #000;
                }
            }
            .hashtags-input {
                width: 100%;
                background: none;
                outline: none;
                color: #000;
                border: none;
                border-bottom: 1px solid $gray;
                margin-top: rem(10);
                &::placeholder {
                    color: #000;
                }
            }
            .subtitle {
                font-size: rem(10);
                color: $gray;
            }
            .upload-button {
                margin-top: 0;
                width: 100%;
                background: none;
                outline: none;
                border: none;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 0;
                z-index: 20;
                font-size: 0.9rem;
                span:first-of-type {
                    img {
                        width: rem(20);
                        height: rem(20);
                        margin-right: rem(5);
                    }
                }
                @include bp(768px down){
                    width: 70%;
                    margin: 0 auto;
                }
            }
            .wrapper-url {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                input {
                    width: 95%;
                    height: 100%;
                    margin: 0;
                    border: none;
                    border-bottom: 1px solid $gray;
                    border-radius: 0;
                    background: none;
                    &::placeholder {
                        color: #000;
                    }
                }
                label {
                    width: 90%;
                    margin-top: 0;
                    border: rem(2) dashed $gray;
                    position: relative;
                    overflow: hidden;
                    span {
                        position: absolute;
                        z-index: 0;
                        top: 4px;
                    }
                    .unfull-url {
                        left: calc(50% - 42px);
                    }
                    .full-url {
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .picture-url {
                        opacity: 0;
                        z-index: 10;
                    }
                }

            }
        }
    }
    .control {
        display: flex;
        align-items: center;
        margin-bottom: calc(25rem / 32);
    }
    .add-btn {
        width: 1.3rem;
        height: 1.3rem;
        border: none;
        border-radius: 0;
        outline: none;
        background: url(../../assets/plus-sign-orange.png) center center;
        background-size: cover;
        cursor: pointer;
    }
    .position-number {
        border-radius: 50%;
        background-color: #141726;
        color: #ffffff;
        width: rem(24);
        height: rem(24);
        text-align: center;
        line-height: rem(24);
        position: absolute;
        right: rem(10);
        bottom: rem(10);
    }
    .picture-url {
        margin-left: 10px;
        height: calc(41rem / 32);
        border-radius: 4px;
        border: 1px solid lightgray;
    }

    .description-title {
        font-size: 16px;
        margin-bottom: calc(16rem / 32);
        @include bp(768px down){
            display: inline-block;
            position: relative;
            bottom: .2rem;
            left: 1rem;

        }
    }
    .description-content {
        font-size: 10px;
        color: #a3a3a3;
    }
}
