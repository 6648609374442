@import "../vendor";

.custom-audio-player {
    position: relative;
    img {
        pointer-events: none;
    }
    &__wrapper {
        display: flex;
    }
    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: calc(100% - 10rem);
        iframe {
            position: absolute !important;
        }
    }
    &__edit-audio-block{
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
    }
    &--edit-audio{
        width: 100%;
        height: 100%;
    }
    &__linear{
        &-bottom {
            display: flex;
            justify-content: flex-end;
            padding: rem(2) 1rem 0;
            span {
                color: rgb(163, 163, 163);
                text-align: right;
                padding-top: rem(2);
                font-size: 14px;
            }
        }
        &-right {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            font-size: 14px;
        }
    }
    &__overlay-wrapper {
        width: 100%;
        .media-description {
            display: none;
        }
        .activity{
            cursor: pointer;
            &-wrapper {
                padding: 0 0.5rem 0.5rem;

                .SVGInline-svg {
                    width: 20px;
                    height: 20px;
                }
            }
            &-wrapper {
                display: flex;
            }
            &.comment, &.apploude {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                padding: 0 0.5rem;
                font-size: 14px;
            }
        }
    }
    &__image-block {
        width: 10rem;
        img {
            width: 100%;
            height: 100%;
        }
    }
    &__top {
        display: flex;
        align-items: flex-start;
        margin: 1rem;
        overflow: hidden;
    }
    &__title {
        line-height: rem(24);
        padding-left: 0.5rem;
        box-sizing: border-box;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: auto;
    }
    &__play-button {
        border: none;
        background: none;
        .fa {
            font-size: rem(24);
        }
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
}

@media screen and(max-width: 1360px) {
    .custom-audio-player {
        &__content {
            width: calc(100% - 10vw);
        }
        &__image-block {
            @include bp(sm up) {
                width: 10rem;
            }
        }
    }
}
