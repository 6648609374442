@import "../vendor";

.button {
    background-color: $yellow;
    border-radius: 15px;
    height: 30px;
    padding: 0 20px;
    color: white;

    &:hover, &:active {
        color: white;
    }
}
