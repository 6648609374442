@import "styles/vendor";

.UserPic {
    position: relative;
    background-size: contain;
    display: flex;
    justify-content: center;
    background-position: center;
    background-repeat: no-repeat;
    a {
        display: flex;
    }
    img {
        border-radius: 50%;
        //border: 1px solid white;
        align-self: center;

    }
    // theme
    &.light {
        color: black;
        //background-image: url(../../../assets/images/circles-abstract.png);
    }
    &.dark {
        color: white;
        background-image: url(/assets/images/circles-abstract-white.png);
    }
    &.primary {
        &.light {
            background-image: url(/assets/profile-bg-dark.png);
            background-position-y: 0;
        }
        &.dark {
            background-image: url(/assets/profile-bg.png);
            background-position-y: 0;
        }
    }
    //size
    &.default {
        min-width: sr(100, 1.5);
        width: sr(100, 1.5);
        height: sr(100, 1.5);
        // @include bp(768px down) {
            // min-width: 4.5rem;
        // }
        img {
            height: sr(60, 1.5);
            width: sr(60, 1.5);

            // @include bp(768px down) {
            //     height: 4.5rem;
            //     width: 4.5rem;
            // }
        }

    }
    &.small { //todo
        min-width: sr(60, 1.5);
        width: sr(60, 1.5);
        height: sr(60, 1.5);
        img {
            height: sr(20, 1.5);
            width: sr(20, 1.5);
        }
    }
    &.large {
        min-width: rem(96);
        width: rem(96);
        height: rem(96);
        img {
            height: rem(64);
            width: rem(64);
        }

        i {
            font-size: 1rem;
            bottom: -4px;
            position: absolute;
        }
    }
    &.noBg {
        background-image: none !important;
    }

    .fa-exclamation {
        position: absolute;
        top: 10px;
        right: 6px;
        color: #ffffff;
        z-index: 300;
        padding-top: 3px;
        font-size: 14px;
        width: 20px;
        height: 20px;
        text-align: center;
        border-radius: 50%;
        background-color: #ff0000;
    }
}
