@import "styles/vendor";

.SearchFilterBar {
    border-bottom: 1px solid rgba(200, 200, 200, 0.5);
    .container {
        @include bp(ss up) {
            justify-content: center;
        }
        @include bp(sm up) {
            justify-content: space-between;
        }
    }
    .search-type {
        &__button {
            height: rem(66);
            border: none;
            background-color: transparent;
            font-weight: $font-thin;
            @include bp(768px down){
                padding: 0 5px;
            }
            @include bp(sm up){
                padding: 0 5rem 0 0;
            }
            &.selected {
                font-weight: $font-medium;
            }
        }
    }
    .budget-filter {
        display: flex;
        flex-direction: row;
        .range-wrapper {
            width: 25rem;
        }
    }
}

.SortBy {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: flex-end;
    font-size: 0.9rem;
}

.PopoverMain {
    @include bp(1200px down) {
        left: 50% !important;
        transform: translate(-50%) !important;
        max-width: 90%;
    }
}
