@import "../vendor";

.register-page {

    margin: rem(119 * .5) rem(168 * .5);
    @include bp(sm down) {
        margin: rem(119 * .25) rem(168 * .25);
    }
    .login-btn {
        @include tabletFontSize(1rem);
        @include phoneFontSize(.8rem);
    }
    .input-field {
        @include bp(768px down){
            font-size: 1rem;
            text-align: center;
        }
    }
    .password-field {
        .toggle-password {
            @include bp(768px down){
                top: .5rem;
            }
        }
    }
    input.input-field {

        &::placeholder {
            @include bp(768px down){
                text-align: center;
                font-size: 1rem;
            }
        }
    }

    .type-of-user {
        margin-top: 2rem;
        text-transform: capitalize;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        padding: 1rem 0;
        p {
            margin-bottom: 0;
            text-align: center;
            font-weight: bold;
        }
    }
    .account-info-form {
        &__title {
            font-size: rem(16);
        }
        &__item {
            &-header {
                margin-top: 1rem;
                @include informative-title;

                .info-popup-window {
                    @include bp(768px down){
                        position: absolute !important;
                        left: -1.8rem !important;
                    }
                }
            }
        }
    }
    .radio-group-wrapper {
        margin-top: rem(50 * .5);
        display: flex;
        flex-direction: row;
        justify-content: center;
        @include bp(768px down){
            flex-direction: column;
        }
    }
    &__checkbox-wrapper {
        padding: 1rem 0;
        @include tabletFontSize(1rem);
        @include phoneFontSize(.8rem);
    }
    &__label{
        color: $gray-hue-2;
        @include tabletFontSize(1rem);
        @include phoneFontSize(.8rem);
    }
    &--link{
        border-bottom: 1px solid;
        font-weight: 500;
        &:hover{
            color: $gray-hue-2;
            text-decoration: none;
        }
    }
}
