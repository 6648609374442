$primary-color: #ffd210;
$error-color: red;

$small-font-size: 14px;
$medium-font-size: 15px;

.Coupon {
    height: 375px;
    border-radius: 12px;
    width: 320px;
    font-weight: 500;
    box-shadow: 0 19px 38px rgba(0,0,0,0.05), 0 15px 12px rgba(0,0,0,0.12);

    &__SmallText {
        font-size: $small-font-size;
    }

    &__MediumText {
        font-size: $medium-font-size;
    }

    &__Logo {
        height: 35px;
        width: 35px;
    }

    &__InnerWrapper {
        padding-left: 24px;
        padding-right: 24px;
    }

    &__Heading {
        height: 64px;
        font-size: 25px;
        font-weight: bold;

        &__Free {
            font-size: 34px;
        }

        &__Subheading {
            height: 23px;
            font-size: 24px;
            font-weight: bold;
        }
    }

    &__Type {
        text-align: center;
        background-color: $error-color;
        color: white;
    }

    &__Code {
        background-color: $primary-color;
        font-size: $medium-font-size;
        border-radius: 400px;
        color: black;
    }


    &.is-gig-coupon {
        .Coupon__InnerWrapper {
            padding: 3px;
        }

        .Coupon__ApplicableFor {
            b:not(:last-of-type):after {
                margin: 0 4px;
                content: '/';
            }
        }

        .Coupon__Heading {
            height: 50px;
        }
    }

    &.has-background-image {
        position: relative;
        color: white;
        background-size: 100%;
        background-repeat: no-repeat;
        overflow: hidden;

        * {
            z-index: 4;
        }

        &:after {
            z-index: 3;
            content: '';
            bottom: 0;
            left: 0;
            background: linear-gradient(to bottom, rgba(0,0,0,0) 32%, rgba(0,0,0,1) 55%, rgba(0,0,0,1) 100%);
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
}

