.SmolSpinner {
    background-color: rgba(255, 255, 255, 0);
    z-index: 998;
    height: 100%;  
    display: flex;
    align-items: center;
    justify-content: center;     

    &:after {
        content: " ";
        display: block;
        width: 16px;
        height: 16px;
        margin: 1px;
        border-radius: 50%;
        border: 3px solid #ffd210;
        border-color: #ffd210 transparent #ffd210 transparent;
        animation: spinner-keyframe 1.2s linear infinite;
    }
}