.NotificationsPopover {
    .MessageNotificationItem {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #e8e8e8;
        color: #3f3f3f;

        &:hover {
            background-color: #ebebeb;
        }

        &__Image {
            min-width: 60px;
            min-height: 60px;
            max-height: 60px;
        }

        &__Title {
            font-weight: 600;
        }

        &__Message {
            width: 300px;
            overflow:hidden;
            white-space:nowrap;
            text-overflow: ellipsis;
        }
    //
        //
    //
        //&__Title {
        //    //font-size: 14px;
        //    //color: #000;
        //}
    //
        //&__Message, &__Date {
        //    @include small-text;
        //}
        //
        //&__Date {
        //    @include small-text;
        //    white-space: nowrap;
        //    background-color: rgba(49.4%, 79.6%, 91.4%, 0.341);
        //}
    }
}
