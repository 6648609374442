@import "styles/vendor";

.events {
    &__title {
        font-size: .8rem;
        font-weight: bold;
        margin-bottom: 0;
        text-align: left;
        padding: 1rem;
        @include tabletFontSize(1.7rem);
        @include phoneFontSize(.9rem);
    }
    &__list-next-gigs {
        display: flex;
        flex-direction: column;
        padding: 0 1rem;
        &_item {
            display: flex;
            justify-content: space-between;
            padding: .5rem 0;
            border-bottom: 1px solid $gray;
            span {
                font-size: .7rem;
                @include tabletFontSize(1.4rem);
                @include phoneFontSize(.7rem);

            }
        }
    }
    &__end-block {
        padding: 1rem;
        &_view-button {
            color: $yellow;
            font-size: .7rem;
            font-weight: bold;
            cursor: pointer;
            @include tabletFontSize(1.4rem);
            @include phoneFontSize(.7rem);
        }
    }
    &__calendar {
        .rdr-Calendar {
            @include bp(768px down){
                width: 100% !important;
                margin-bottom: 2.7rem;
            }
            .rdr-MonthAndYear {
                .rdr-MonthAndYear-month, .rdr-MonthAndYear-year {
                    @include tabletFontSize(1.5rem);
                    @include phoneFontSize(.8rem);
                }
                .rdr-WeekDays {
                    @include bp(768px down){
                        margin-bottom: 1rem;
                    }
                    .rdr-WeekDay {
                        @include tabletFontSize(1.4em);
                        @include phoneFontSize(1rem);

                    }
                }
            }
            .rdr-Days {
                .rdr-Day {
                    @include tabletFontSize(1.2rem !important);
                    @include phoneFontSize(1rem !important);
                }
            }
        }

    }
}
