@import "styles/vendor";
@import '~gemini-scrollbar/gemini-scrollbar.css';

.RichPopover.ant-popover {
    position: fixed;
    z-index: 1000;
    border: 1px solid rgba(100, 100, 100, .4);
    border-radius: 0 0 2px 2px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, .25);
    color: #3f3f3f;

    .ant-popover-inner {
        width: 400px;
        height: 307px;

        & > div {
            display: flex;
            flex-direction: column;
            height: 100%;

            .ant-popover-title {
                color: #3f3f3f;
                font-size: 15px;
                letter-spacing: 0.4px;
                font-weight: 600;
            }

            .ant-popover-inner-content {
                flex-grow: 1;
                overflow-y: hidden;
                padding: 0;
            }
        }
    }
}