@import "styles/vendor";

.DataRangePicker {
    &__bottom {
        display: flex;
        padding: 15px 25px;
        background: $gray;
        .month {
            color: #333;
            font-weight: 400;
            font-size: 16px;
            flex-grow: 1;
            text-align: left;
        }
        .days {
            color: #333;
            font-weight: 500;
            font-size: 17px;
            flex-grow: 1;
            text-align: center;
        }
        .year {
            color: #333;
            font-weight: 400;
            font-size: 16px;
            flex-grow: 1;
            text-align: right;
        }
    }
    .rdr-Days {
        span {
            position: relative;
        }
    }
    .rdr-Day {
        height: 70% !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        display: flex !important;
        justify-content: center;
        align-items: center;
        width: 100% !important;
    }
    .is-selected {
        border-top: 1px solid $yellow !important;
        border-bottom: 1px solid $yellow !important;
    }
    .rdr-WeekDays {
        & > .rdr-WeekDay {
            width: calc(100% / 7) !important;
        }
    }
    .rdr-Days {
        & > span {
            width: calc(100% / 7) !important;
        }
    }
}

.DataRangePicker.isRange {
    .rdr-DateRange {
        .rdr-Calendar {
            .rdr-MonthAndYear {

            }
            .rdr-WeekDays {

            }
            .rdr-Days {
                .rdr-Day {
                    &.is-inRange {
                        border-top: 1px solid $yellow !important;
                        border-bottom: 1px solid $yellow !important;
                    }
                    &.is-selected {
                        &.is-inRange {
                            border-top-right-radius: 0 !important;
                            border-bottom-right-radius: 0 !important;
                            border-right: 0 !important;
                        }
                        &:not(.is-inRange) {
                            border-top-left-radius: 0 !important;
                            border-bottom-left-radius: 0 !important;
                            border-left: 0 !important;
                        }
                    }
                }
            }
        }
    }
}
