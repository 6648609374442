$primary-color: #ffd210;

$small-font-size: 13px;
$medium-font-size: 15px;

.PopoverWithList {
    &.PopoverWithList {
        min-width: 280px;
    }
    
    &__Element {
        border-bottom: 1px solid #ccc;

        &:hover {
            background-color: rgba($primary-color, .05);
        }

        &.active {
            background-color: $primary-color;
        }        

        &.cursor-pointer, &.cursor-pointer * {
            cursor: pointer;
        }

        &.cursor-default, &.cursor-default * {
            cursor: default;
        }

        &:first-of-type {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
        }

        &:last-of-type {
            border: none;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
        }
    }

    &__SmallText {
        font-size: $small-font-size;
    }

    &__MediumText {
        font-size: $medium-font-size;
    }
}

