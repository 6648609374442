@import '../../../../styles/vendor';

$transition-delay: .3s;
$grey: #a3a3a3;

.venue-info {
    i.fa.fa-plus, i.fa.fa-vertical-line {
        display: flex;
        align-items: center;
        
        position: absolute;        
        top: 0;
        color: $yellow;
        font-size: 28px;
        
        @include bp(sm down) {
            position: static;
            margin-right: .5rem;
        }
    }

    i.fa.fa-plus {        
        left: calc(-53rem / 32);
        -webkit-text-stroke: 2px #fff;
    }

    i.fa.fa-vertical-line {
        left: calc(-40rem / 32);
    }

    box-sizing: content-box;
    margin: rem(148 / 2) rem(198 / 2);
    text-align: center;
    font-size: calc(30rem / 32);
    font-weight: 300;
    &__info-big-title {
        position: relative;
        .info-popup-window {
            position: absolute;
            left: -42px;
        }
    }
    .filled {
        color: #000;
    }
    @include form-input-color;
    &__container {
        margin: rem(95 / 4) 0;
    }
    &__optional {
        font-size: rem(12);
        font-weight: 300;
        margin-left: rem(10);
    }
    &__fields {
        position: relative;
        text-align: left;

        .error {
            @include error;
        }

        .fa {
            &-stack {
                position: absolute;
                left: calc(-70rem / 32);
                top: 0;
                font-size: calc(20rem / 32);
            }
        }
    }

    &__info {
        @include informative-title;
        &-bigger {
            display: flex;
            font-size: 22px;
            font-weight: 500;
            cursor: pointer;

            .fa-stack {
                position: relative;
                left: 0;
                top: 0;
                font-size: calc(20rem / 32);
            }
        }
        &-additional-contact {
            color: #000;
            font-weight: 500;
            text-align: left;
            font-size: 1rem;
            margin-left: rem(95 / 4);
            cursor: pointer;
            i.fa.fa-plus {
                margin-left: 1.48438rem;
            }
            i.fa.fa-vertical-line {
                margin-left: 1rem;
            }
            &.open {
                margin-left: 1rem;
            }
        }
    }

    &__input {
        width: 100%;
        border: none;
        border-bottom: 1px solid $grey;
        outline: none;
        color: #000000;
        font-size: calc(50rem / 32);
        font-weight: 300;
        transition: border-color $transition-delay;

        //&:hover,
        &:focus,
        &:active,
        &.active {
            border-bottom-color: $yellow;
        }

        &::-webkit-input-placeholder {
            color: $grey;
        }
        &::-moz-placeholder {
            color: $grey;
        }
        &:-moz-placeholder {
            color: $grey;
        }
        &:-ms-input-placeholder {
            color: $grey;
        }
    }

    &__checkbox-wrapper {
        @extend %checkbox-wrapper;
    }

    &__calendar {
        height: calc(135rem / 32);
        width: calc(600rem / 32);
        margin-left: 0;
        margin-right: auto;
        background-color: $grey;
        align-items: center;
        color: #fff;
    }

    &__date {
        position: relative;
        text-align: left;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-end;
        &-of-birth {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: calc(46rem / 32);

            & > div:not(.venue-info__calendar) {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: row-reverse;
            }

            [type="checkbox"] {
                visibility: hidden;
            }

        }

        &-age {
            color: #000000;
            font-size: calc(22rem / 32);
            font-weight: 300;
        }

        .fa {
            &-stack {
                position: absolute;
                left: calc(-70rem / 32);
                top: 0;
                font-size: calc(20rem / 32);
            }
        }
    }
    &__contact-information {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    &__curriculum-vitae {
        @at-root .curriculum-vitae-form {
            &__item {
                &-header {
                    @include informative-title;
                }
            }
            &__text {
                font-size: rem(14);
                color: $grey;
                font-weight: 300;
                text-align: left;
            }
        }
        .input-file{
            &__button {
                width: 9rem;
                padding: rem(10) 0;
                border-radius: rem(32);
                font-size:0.85rem;
                font-weight: 500;
                color: #fff;
                background-color: $yellow;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    height: rem(14);
                    position: relative;
                    top: rem(3);
                    margin-right: rem(4);
                }
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    &__next {
        @include main-button;
        &:disabled {
            @include main-button__disabled;
        }
    }

    // Modal
    .skylight {
        &-wrapper {
            display: flex;
        }

        &-close-button {
            right: calc(40rem / 32) !important;
            font-size: calc(130rem / 32) !important;
            line-height: .73;
        }
    }
    @include alert-modal;
}
