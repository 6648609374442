@import "styles/vendor";

.HomeProfile {
    transform: rotate(45deg);
    margin-right: 2rem;
    .info-wrapper {
        height: 100px;
        margin-left: -15px;
        max-width: 130px;
        @include bp(768px down){
            margin-left: 0;
        }
        a {

            width: 15px;
            height: 15px;
            @include bp(768px down){
                width: 30px;
                height: 30px;
            }
            @include bp(520px down){
                width: 22px;
                height: 22px;
            }

            img {
                width: 15px;
                height: 15px;
                @include bp(768px down){
                    width: 30px;
                    height: 30px;
                }
                @include bp(520px down){
                    width: 22px;
                    height: 22px;
                }
            }
        }
    }

    h5 {
        font-size: .8rem;
        font-weight: 600;
        @include tabletFontSize(1.7rem);
        @include phoneFontSize(.9rem);

        &:after {
            content: ".";
            visibility: hidden;
        }
    }

    hr {
        max-width: 160px;
        margin-top: 0.5rem !important;
        @include bp(768px down){
            max-width: 100%;
        }

    }

    p {
        font-size: 10px;
        max-width: 160px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include bp(768px down){
            max-width: 100%;
            font-size: 1.3rem;
        }
        @include phoneFontSize(.7rem);
    }

    .image-mask {
        overflow: hidden;
        width: rem(120);
        height: rem(120);
        transform: rotate(45deg);
        img {
            transform: rotate(-45deg) scale(1.42);
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &.artist {
            border: 1px solid #db3236;
        }

        &.venue {
            border: 1px solid #17698d;
        }
    }
}
