@import "styles/vendor";

.GigDetailsPopupModal {
    .modal-body {
        position: static;
        //top: 0 !important;
        margin: 0 !important;
        padding: 0 !important;
        min-height: unset !important;

        //@include bp(768px down){
        //    width: 100% !important;
        //    height: 100% !important;
        //    left: 0 !important;

        //    overflow-y: scroll;
        //    .popup-loader-container {
        //        text-align: center;
        //        span {
        //            top: 45%;
        //            position: absolute;
        //            --popup-width: 10%;
        //            left: calc(50% - var(--popup-width));
        //        }
        //    }
        //}
        @include bp(768px up) {
            //top: 20vh !important;
            //transform: translate(-50%) !important;
            box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 30px, rgba(0, 0, 0, 0.23) 0px 6px 10px;
        }
        > a {
            z-index: 1;
            transform: scale(1.22);
            &:active {
                transform: scale(1.1);
            }
        }
        .gig-details {
            display: flex;
            overflow: hidden;
            color: #000;

            @include bp(768px down) {
                flex-direction: column;
            }
            .GigCard {
                margin: 0;
                box-shadow: none !important;
                width: 293px;
            }
            .GigCard-popup-description {
                width: 35rem;
                height: 378px; // same as GigCard
                overflow: hidden;
                padding: 0;
                background: $light-gray;
                @include bp(768px down){
                    width: 100%;
                }
                @include bp(768px up) {
                    border-left: 2px solid $yellow;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                }
                .capacity-request {
                    span.capacity-item {
                        margin-right: 1rem;
                        font-size: rem(11);
                    }
                }

                .gm-scroll-view {
                    padding-right: 1rem;
                    padding-left: 1rem;
                }
                
                .categories {
                    max-height: calc(100%);
                    width: 100%;

                    .thumb {
                        cursor: default;
                    }
                    //.gm-scrollbar.-vertical {
                    //    bottom: 0;
                    //    top: 5%;
                    //    height: 90%;
                    //}                    
                }
                h4 {
                    font-size: rem(13);
                    margin-top: 1rem;
                    margin-bottom: 0;
                }
                p {
                    font-size: rem(11);
                    margin-bottom: 1rem !important;
                    margin-top: .5rem;
                    word-break: break-word;
                    &.styles-list {
                        display: flex;
                        flex-wrap: wrap;
                        span.styles-item {
                            border: 1px solid $gray;
                            padding: rem(3) rem(10);
                            border-radius: rem(7);
                            margin-right: rem(5);
                            margin-bottom: rem(5);
                        }
                    }
                }
            }
        }
    }
}
