@import "styles/vendor";

.SortBy {
    .sort-title {
        white-space: nowrap;
        font-weight: $font-light;
        font-size: rem(12);
        color: #a3a3a3;
        padding-right: rem(20);
    }
    .dropdown {
        button {
            top: 0 !important;
            z-index: unset !important;
        }
        
        &>div{
            &>div:nth-child(2){
                padding-left: 0!important;
                padding-right: 34px!important;
                @include bp(lg up){
                    padding-right: 56px!important;
                }
            }
            button {
                width: 20px!important;
                @include bp(lg up){
                    width: 40px!important;
                }
            }
        }
    }
}
