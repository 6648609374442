@import "styles/vendor";

.OperationHours {
    font-weight: bold;
    padding: 0 1rem;

    &__head {
        display: flex;
        justify-content: flex-end;
        div {
            width: 25%;
        }
    }

    &__item {
        display: flex;
        justify-content: space-between;
        margin-top: .4rem;

        &:last-child {
            border-bottom: 1px solid $gray;
            padding-bottom: 1rem;
        }

        & > div {
            width: 50%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &.day {
                text-align: left;
                text-transform: capitalize;
            }

            &.value {
                width: 25%;
                text-overflow: clip;
            }
        }

        
    }

    .value {
        font-weight: 400;
    }
}