@import "styles/vendor";

.SharedPopUpSocialNetworks {
    position: absolute;
    right: 0;
    top: 0;
    width: 78%;
    height: 100%;
    background-color: rgba(0, 0, 0, .9);
    display: flex;
    flex-direction: column;
    color: #fff;
    overflow: hidden;
    z-index: 103;
    &__share {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid $gray-hue-2;
        font-weight: bold;
        height: calc(100% / 7);
        font-size: .8rem;
        @include bp(768px up) {
            font-size: .7rem;
        }
        @include bp(1200px up) {
            font-size: .8rem;
        }
    }
    &__main {
        padding: 0 .75rem .5rem;
        height: calc(100% - 100% / 7);
        &_mail-message {
            height: calc(100% / 6);
            border-bottom: 1px solid $gray-hue-2;
        }
        &_social-list {
            flex-wrap: wrap;
            height: calc(100% / 2);
            .checked-svg {
                path, rect {
                    fill: $yellow !important;
                }
            }
            .email {
                background-color: transparent;
                width: 100%;
                height: 2rem;
                border: none;
                border-bottom: 1px solid $gray-hue-2;
                color: $gray-hue-2;
            }
        }
        &_choose-platform {
            border-bottom: 1px solid $gray-hue-2;
            height: calc(100% / 6);
            display: flex;
            align-items: center;
            p {
                color: $gray;
                font-size: .65rem;
                margin-bottom: 0;
                text-align: center;
                @include bp(768px up) {
                    font-size: .5rem;
                }
                @include bp(1200px up) {
                    font-size: .65rem;
                }
            }
        }
        &_send {
            height: calc(100% / 6);
            display: flex;
            align-items: center;
            justify-content: center;
            .button-send {
                width: 100%;
                height: 90%;
                border: 1px solid $gray;
                color: $gray;
                border-radius: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                white-space: nowrap;
                cursor: pointer;
                background: transparent;
                &[disabled] {
                    border: 1px solid $gray-hue-2;
                    color: $gray-hue-2;
                    cursor: not-allowed;
                }
            }
        }
        span.SVGInline {
            width: calc(100% / 3);
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
                svg {
                    transform: scale(1.1);
                }
            }
            &:active {
                svg {
                    path, rect {
                        fill: $yellow !important;
                    }
                }
            }
            svg {
                transition: .12s;
                width: 1.5rem;
                @include bp(768px up) {
                    width: 1rem
                }
                @include bp(1200px up) {
                    width: 1.5rem;
                }
            }
        }
    }
    .closeCss {
        cursor: pointer;
        &:before, &:after {
            background-color: $gray-hue-2;
        }
    }
}
