@import "styles/vendor";

.HomeProfiles {
	margin-right: -55px;
	@include bp(768px down) {
        margin: 0;
        display: block;
        float: left;
        width: 100%;
		//transform: scale(0.75);
	}
	@include bp(575px down) {
		//transform: scale(0.5);
	}
	@include bp(768px up) {
		//transform: scale(0.82);
	}
	@include bp(md up) {
		margin-top: 0 !important;
		transform: scale(0.9);
	}
	@include bp(lg up) {
		margin-top: 140px !important;
		transform: scale(1);
		margin-right: -25px;
	}
	.HomeProfile + .HomeProfile {
		margin-left: 100px;
	}
	h5 {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	& > .profilesWrapper {
		right: -160px;
		position: absolute;
		transform: rotate(-45deg);
		white-space: nowrap;

		@include bp(768px down) {
			-webkit-transform: initial;
            -moz-transform: initial;
            -ms-transform: initial;
            -o-transform: initial;
            transform: initial;
            position: static !important;
            right: 0px !important;
            margin-top: 0;
            padding-top: 4rem;
            float: left;
            width: 100%;
            & > div:not(:first-child){
                display: none;
            }
			.HomeProfile {
				-webkit-transform: initial;
				-moz-transform: initial;
				-ms-transform: initial;
				-o-transform: initial;
				transform: initial;
				margin-right: 0px;
				width: 50%;
                float: left;
                margin-left: 0;

			}
		}

		.profilesWrapper__mobile {
			display: none;
            .HomeProfile:nth-child(even){
                @include bp(768px down){
                    bottom: 10rem;
                }
            }
            .HomeProfile:nth-child(odd){
                @include bp(768px down){
                    top: 5rem;
                }
            }
            .HomeProfile {
                .image-mask {
                    @include bp(768px down){
                        width: 30vw;
                        height: 30vw;
                        display: inline-block;
                    }
                }
                @include bp(768px down){
                    margin-right: 0;
                    margin-left: 0;
                    margin-bottom: 10rem;
                    width: 50%;
                    float: left;
                    position: relative;
                    padding: 2rem;
                }
                @include bp(520px down){
                    margin-bottom: 5rem;
                }
            }
            @include bp(768px down){
                display: block;
                float: left;
                width: 100%;
                margin: auto;
                padding: 0 2rem;
                margin-bottom: 6rem;
            }
		}

		.profilesWrapper__second-row {
			.HomeProfile {
				&:nth-child(2) {
					.pseudo-image-mask {
						position: absolute;
						width: rem(120);
						height: rem(120);
						transform: rotate(45deg);
						top: rem(-171);
						left: rem(171);
						@include bp(768px down) {
							display: none;
						}

						&.artist {
							border: 1px solid #db3236;
						}

						&.venue {
							border: 1px solid #17698d;
						}
					}




					.pseudo-after-line {
						position: relative;
						&:after {
							content: '';
							width: 100%;
							height: 100%;
							position: absolute;
							top: rem(-171);
							left: rem(171);
							border-bottom: 1px solid $gray;
							@include bp(768px down) {
								display: none;
							}
						}
					}
					.image-mask {
						position: relative;
					}
				}
			}
			margin-top: -25px;
		}
		.profilesWrapper__third-row {
			.HomeProfile {
				&:nth-child(1) {
					.pseudo-image-mask {
						position: absolute;
						width: rem(120);
						height: rem(120);
						border: 1px solid #f00;
						transform: rotate(45deg);
						top: rem(-171);
						left: rem(171);
						@include bp(768px down) {
							display: none;
						}
					}
					.pseudo-after-line {
						position: relative;
						&:after {
							content: '';
							width: 100%;
							height: 100%;
							position: absolute;
							top: rem(-171);
							left: rem(171);
							border-bottom: 1px solid $gray;
							@include bp(768px down) {
								display: none;
							}
						}
					}
					.image-mask {
						position: relative;
					}
				}
			}
			margin-top: -25px;
		}

		@media (max-width: 575px) {
			right: -300px;
		}

		@media (max-width: 767px) {
			margin-top: 130px;
			right: -300px;
		}

		@media (min-width: 767px) and (max-width: 991px) {
			right: -200px;
		}

		@media (min-width: 992px) and (max-width: 1250px) {
			right: -160px;
			margin-top: 160px;
		}
	}
}
