@import "../../../vendor";

.crop-image {
    .file-input {
        display: none;
    }

    .label {
        width: 100%;
        margin: 0;
    }

    .btn {
        text-decoration: none !important;
        box-shadow: none !important;
        overflow: hidden;
        box-sizing: border-box;
        width: 100%;
        height: rem(50);
        padding: 0 rem(10);
        margin: 0.5rem 0;
        font-size: rem(18);
        line-height: rem(50);
        cursor: pointer;
        font-weight: 600;
        text-align: center;
        border-radius: 8px;
        border: none;

        &__wrapper {
            display: flex;
            justify-content: space-between;

            button {
                width: calc(50% - 8px)
            }
        }

        &__done {
            background-color: #47b535;
            color: #fff;
        }

        &__cancel {
            background-color: #db3236;
            color: #fff;
        }

        &__switch,
        &__select {
            background-color: #fff;
            color: #000;
            border: 2px solid #ffd210;
        }
    }
    .ReactCrop__image {
        max-height: unset !important;
    }
}