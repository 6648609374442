@import "styles/vendor";

.subscription-window {
    background: #fff;
    width: 100%;
    z-index: 2;
    @include bp(md up) {
        position: absolute;
        top: -90px;
    }
    & > .container {
        flex-wrap: nowrap;
        align-items: center;
        position: relative;
        display: flex;
        justify-content: center;
        min-height: 90px;
        @include bp(sm down) {
            flex-direction: column;
            padding: 0 3rem;
        }
        @include bp(lg up) {
            padding: 0 7rem;
        }
    }
    &__fields {
        margin: 0 1rem;
        width: calc(25% - 2rem) !important;
        @include bp(sm down) {
            width: 100% !important;
        }
    }
    .subscribe-button {
        &:hover {
            cursor: pointer;
            border: 1px solid black !important;
            span {
                color: black !important;
                font-weight: bold !important;
            }
        }
        &:active {
            color: #fff;
            border: 1px solid $yellow !important;
            background-color: #ffd210 !important;
            span {
                color: #fff !important;
            }
        }
        @include bp(sm down) {
            margin-bottom: .7rem;
        }
        @include bp(sm up) {
            margin-left: 1rem;
            margin-right: 1rem;
            min-width: auto !important;
            width: calc(25% - 2rem);
        }
    }
    .close {
        cursor: pointer;
        position: absolute;
        right: 2rem;
        svg {
            path {
                fill: #000 !important;
            }
        }
    }
}
