@import "styles/vendor";

.CompareItem {
    max-width: rem(300);
    max-height: rem(250);
    @include bp(lg down) {
        max-width: 14.5rem;
    }
    display: flex;
    margin: 1rem 0;
    .UserPic {
        align-self: center;
    }
    &__info {
        padding: rem(10);
        & > div {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: inherit;
            max-width: 10rem;
            margin: 0;
            @include bp(lg down) {
                max-width: 7.5rem;
            }
        }
        &-title {
            font-size: rem(14);
            font-weight: $font-medium;
        }
        &-subtitle,
        &-location {
            font-size: rem(12);
            font-weight: $font-light;
        }
    }
    &__remove {
        position: absolute;
        right: 0;
        top: 0;
    }

}
