@import "../vendor";
@import "../utils";

.social-video-list-wrapper {
    // @extend %EditMediaContent;
    margin: rem(94 / 2) rem(112 / 2);
    border: none;
}

.content-reposition {
    margin-bottom: calc(50rem / 32);
    .reposition-btn {
        height: calc(50rem / 32);
        padding: 0 calc(25rem / 32);
        border: 1px solid #a3a3a3;
        outline: none;
        border-radius: 50px;
        font-size: 10px;
        font-family: Montserrat, serif;
        font-weight: 500;
        background-color: white;
        color: black;
        vertical-align: middle;
        line-height: calc(50rem / 32);
        &::before {
            @include pseudo(inline-block, null);
            @include size(13px);
            transform: translateY(-1px);
            margin-right: 10px;
            background: url(../../assets/reposition.png) center center no-repeat;
            background-size: cover;
            vertical-align: middle;
            line-height: calc(50rem / 32);
        }
        &.active {
            background: #ffd210;
        }
    }
}

.edit-video-list-container {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    &--artist-wrapper {
        margin: 10px -10px 0;
    }
    .aspect-ratio-container {
        position: relative;
        width: 100%;
        padding-top: calc(100% / 1.777777);
    }
    .edit-video-item-wrapper {
        position: relative;
        width: percentage(1 / 3);
        padding: 10px;
        &:nth-child(1) {
            width: 66.666667%;
        }
        &:nth-child(2) {
            height: 50%;

            .media-block .main-block .overlay .media-description.dark {
                height: 35px;
            }
            .media-block .main-block .overlay .activity-wrapper .activity {
            }
        }
        &:nth-child(3) {
            position: absolute;
            right: 0;
            height: 50%;
            transform: translateY(calc(102% - 8px));

            .media-block .main-block .overlay .media-description.dark {
                height: 35px;
            }
            .media-block .main-block .overlay .activity-wrapper .activity {
            }
        }
        &:nth-child(3):not(.add-more-block) {
            height: 33%;
        }

        &__alone {
            position: relative;
            padding: 10px;
            width: 66.666667%;
            margin: 0 auto;
            z-index: 0;
        }

        &__dual {
            position: relative;
            padding: 10px;
            width: 50%;
        }

        &.add-more-block {
            &:nth-child(1) {
                width: 66.666667%;
            }
            width: percentage(1 / 3);
            height: 50%;
            .aspect-ratio-container {
                padding-top: 56%;
            }
            .open {
                width: 100%;
            }
        }
    }
    .aspect-ratio-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .media-block {
            width: 100%;
            height: 100%;
        }
    }
}

.edit-add-new {
    display: flex;
    width: 100%;
    height: 100%;
    .wrapper {
        &-input {
            min-height: 243px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        &.open {
            background-color: $light-gray;
            margin: auto;
            .control {
                height: 100%;
                margin-bottom: 0;
                @include bp(sm up){
                    //min-height: 243px;
                    min-height: 100%;
                }
            }
            .name-input {
                color: #000;
                background: none;
                outline: none;
                border: none;
                border-bottom: 1px solid $gray;
                &::placeholder {
                    color: #000;
                }
            }
            .hashtags-input {
                background: none;
                outline: none;
                color: #000;
                border: none;
                border-bottom: 1px solid $gray;
                &::placeholder {
                    color: #000;
                }
            }
            .upload-button {
                background: none;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;
                font-size: 0.9rem;
                span:first-of-type {
                    img {
                        width: rem(20);
                        height: rem(20);
                        margin-right: rem(5);
                    }
                }
            }
            .wrapper-url{
                label {
                    width: 100% !important;
                    margin-bottom: 0;

                    & > input {
                        width: 100% !important
                    }
                }
            }
        }
    }
    .control {
        display: flex;
        align-items: center;
        margin-bottom: calc(25rem / 32);
    }
    .add-new-video {

    }
    .description-title {
        font-size: 16px;
        margin-bottom: calc(16rem / 32);
    }
    .description-content {
        font-size: 10px;
        color: #a3a3a3;
    }
    .required-error {
        @include error;
        margin-bottom: 0;
    }
    .wrapper-url{
        label {
            margin-bottom: 0;
        }
    }
}

.share-pop-up {
    cursor: pointer;
    position: relative;
    top: 8px;
    right: 0.5rem;
}

.wrapper-new-video-block {
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 56.25%;
}

.profile-edit {
    &__main{
        .container-fluid:first-of-type {
            @include bp(lg up) {
                display: flex;
                flex-direction: column;
                align-items: center;
                .row:first-of-type {
                    align-self: flex-start;
                }
            }
        }
        .video-block-main {
            width: 100%;

            .video-block-big {
                flex: 0 0 66.66666666%;
                max-width: 66.66666666%;
            }

            .video-block-small {
                flex: 0 0 33.333333333%;
                max-width: 33.333333333%;

                h3 {
                    font-size: 1rem !important;
                }

                .tags-list {
                    font-size: .8rem;
                }
            }

            .layout-item-addition {
                padding-left: 8px;
                padding-right: 8px;
                height: 390px;

                 h3 {
                    font-size: 1rem !important;
                }

                .tags-list {
                    font-size: .8rem;
                }
            }

            @include bp(lg up) {
                .layout-item-addition,.layout-item,.edit-add-new {
                    min-height: 320px;
                }
            }
        }
    }

}
