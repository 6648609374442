@import "../utils";

.share {
    &-header {
        background-color: #000;
        height: rem(60);
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: rem(1) solid $gray;
        &__button {
            border: none;
            background-color: #000;
            color: #fff;
            font-weight: 600;
            &-text {
                font-size: 1rem;
                margin-right: rem(10);
            }
            .SVGInline-svg {
                position: relative;
                top: rem(4);
            }
            &:focus {
                outline: none;
            }
        }
    }
    &-block {
        color: #FFFFFF;
        width: auto;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        &__content {
            position: relative;
            background-color: #000;
            opacity: 0.7;
            width: 60%;
            height: 100%;
            .content-title {
                width: 80%;
                margin: 0 auto rem(30);
                padding: rem(20) 0;
                border-bottom: 1px solid $gray;
                color: $gray;
                text-align: center;
                font-size: 0.85rem;
            }
        }
        &__button {
            &-open {
                width: auto;
            }
        }
        .button {
            &-wrapper {
                margin: 0 auto;
                display: flex;
                width: 80%;
                flex-direction: column;
                .SVGInline {
                    width: rem(36);
                    height: rem(36);
                    &-svg {
                        width: rem(36);
                        height: rem(36);
                    }
                }
                &__row {
                    width: 100%;
                    margin: rem(20) 0;
                    display: flex;
                    justify-content: space-around;
                }
            }
        }
        .publish {
            &-input {
                border: 0;
                background: none;
                outline: none;
                border-bottom: 1px solid $gray;
                position: absolute;
                margin: 0 auto;
                width: rem(240);
                bottom: rem(90);
                &[placeholder] {
                    text-align: center;
                    padding-bottom: rem(20);
                }
            }
            &-button {
                position: absolute;
                bottom: rem(20);
                padding: rem(5) 0;
                margin: 0;
                width: 80%;
                border-radius: rem(16);
                background-color: #ffd210;
                color: #fff;
                border: 0;
                &[disabled] {
                    background-color: #000;
                    cursor: default;
                    border: rem(1) solid #fff;
                }
                &-wrapper {
                    width: 100%;
                    margin: rem(30) 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
}