@import "./utils";
@import "./breakpoints";

// TYPOGRAPHY
%text-hint {
    font-size: 11px;
    font-family: Montserrat, serif;
    font-weight: 200;
    color: $font-gray;
}

// BUTTONS
%default-btn {
    margin: auto;
    border: none;
    border-radius: 0;
    outline: none;
}

%upload-file-btn {
    input[type="file"] {
        display: none;
    }
    label {
        margin-bottom: 10px;
        font-size: 16px;
        font-family: Montserrat, serif;
        font-weight: 700;
        text-align: center;
        color: black;
        cursor: pointer;
    }
    .fa {
        margin-right: 5px;
        font-size: 16px;
        color: #fee17c;
    }
}

%simple-btn {
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 50px;
    border: 1px solid #a3a3a3;
    outline: none;
    font-size: 13px;
    font-weight: 300;
    white-space: nowrap;
    color: black;
    background: white;
    &:focus,
    &:active {
        outline: none;
    }
}

%simple-btn--narrow {
    @extend %simple-btn;
    padding-top: 3px;
    padding-bottom: 3px;
}

%checkbox-wrapper {
    display: flex;
    justify-content: space-between;
    padding: rem(59 / 2) 0;
    @include bp(sm down) {
        flex-direction: column;
    }
}

%btn-menu {
    $btn-width: to-rem(50px);
    $btn-height: to-rem(30px);
    $btn-divide-height: to-rem(2px);
    @extend %default-btn;
    @include size($btn-width, $btn-height);
    position: relative;

    span {
        $divide-width: .7;
        $btn-divide-pos: $btn-height / 4 - $btn-divide-height / 2;
        @include position(absolute, $btn-height / 2 - $btn-divide-height / 2 null null 0);
        margin: 0 $btn-width * (1 - $divide-width) / 2;

        &, &:before, &:after {
            @include size($btn-width * $divide-width, $btn-divide-height);
            background: #000;
            transition: all .2s;
        }

        &:before, &:after {
            @include pseudo();
        }

        &:before {
            top: $btn-divide-pos;
        }

        &:after {
            top: $btn-divide-pos * -1;
        }
    }
}

// EDIT MEDIA PROFILE
%edit-media-grid-creator {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}

%EditMediaContent {
    width: 100%;
    margin: 0;
    padding: 0;
    border: 1px solid $border-color;
}

// IMAGES
%avatar-image {
    position: absolute;
    border-radius: 50%;
    background: #ffffff;
    &::before {
        @include position(absolute, -25% null null -25%);
        @include size(150%);
        @include pseudo($pos: null);
        background: url(/assets/images/circles-abstract-white.png) center no-repeat;
        background-size: cover;
    }
}
