.tax_id {
	display: grid;
	grid-template-columns: 1fr 16.6666%;
	margin-top: -55px;

	.body {
		// padding-top: 5rem;
		// padding-left: 5rem;
		// margin: 3.71875rem 5.25rem;
		margin-left: 1.5rem;
		margin-top: calc(55px + 0.8rem);

		.input {
			.confirmation {
				display: grid;
				grid-template-columns: 1fr 95%;
				max-width: 30rem;
				margin-top: 3rem;

				.confirmation_title {
					font-size: 0.8rem;
				}
			}
		}
	}
	.right_side {
		min-height: 20rem;
		border-left: 1px solid #c2c2c2;
		width: 100%;

		.info p {
			text-align: center;
			color: red;
			font-size: 0.75rem;
			margin: 0.75rem 0.75rem 0 0.75rem;
			padding-bottom: 0.75rem;
			border-bottom: 1px solid #aaa;
		}
	}
}
