.lang-button {
    min-width: 90px;
    height: 36px;
    line-height: 39px;
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
    border-radius: 50px;
    background: white;
    color: #000;
    i {
        margin-left: 0.3rem;
        font-size: 14px;
    }
}

.menu {
    height: 180px;
    position: absolute;
    z-index: 2100;
    width: 170px;
    padding: 24px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 10px 20px #000;
    margin-top: 20px;
    overflow:hidden; overflow-y:scroll;
    label {
        color: #000;
    }
}

.arrow-up {
    width: 30px;
    height: 20px;
    margin-left: 50px;
    z-index: 2101;
    border-left: 15px solid transparent;
    border-right: 14px solid transparent;
    border-bottom: 15px solid #fff;
    position: absolute;
}
