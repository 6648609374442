.NewBankAccountForm .Select {
    .ant-select-selection {
        height: 38px;
        
        .ant-select-selection__rendered {
            margin-top: 3px;
            margin-bottom: 3px;
        }

        .ant-select-arrow .ant-select-arrow-icon svg {
            display: none;
        }
    }
}