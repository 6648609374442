.Spinner {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 998;        
    width: 100%;
    height: 100%;  
    display: flex;
    align-items: center;
    justify-content: center;     

    &:after {
        content: " ";
        display: block;
        width: 46px;
        height: 46px;
        margin: 1px;
        border-radius: 50%;
        border: 5px solid #ffd210;
        border-color: #ffd210 transparent #ffd210 transparent;
        animation: spinner-keyframe 1.2s linear infinite;
    }
}