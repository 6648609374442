@import "styles/vendor";

.SearchBlock {
    display: flex;
    width: 100%;
    @include bp(768px up) {
        flex-direction: column;
    }
    @include bp(992px up) {
        flex-direction: row;
        max-height: rem(290);
    }
    @include bp(1440px up) {
        max-height: 21rem;
    }
    .filter {
        padding-top: rem(15);
        padding-left: 0;
        padding-right: 0;
        @include bp(ss up) {
            display: none;
        }
        @include bp(sm up){
            display: flex;
        }
        .col-6 {
            &:nth-child(1) {
                padding-left: 0;
            }
            &:nth-child(2) {
                padding-right: 0 !important;
            }
        }
        .filter-header {
            padding-bottom: rem(20);
            h3 {
                font-weight: bold;
                margin-bottom: 0;
                font-size: 2rem;
            }
            .title {
                font-size: .8rem;
                color: $gray;
            }
        }
        .categories {
            overflow-y: scroll;
            max-height: calc(100% - 100px);
            .gm-scroll-view {
                @include bp(992px down) {
                    max-height: 8rem;
                }
            }
            .categoryCheckBox {
                margin-right: rem(10);
                &__label {
                    width: auto !important;
                    color: #000;
                    font-size: .8rem;
                    span {
                        color: $gray;
                        font-size: .8rem;
                    }
                }
            }

        }
    }
    .thumbnail {
        position: relative;
        @include bp(768px down) {
            max-width: 100%;
            flex: 0 0 100%;
            padding-right: 0;
            padding-left: 0;
            max-height: rem(200);
        }
        @include bp(sm up){
            padding-right: 15px;
            padding-left: 15px;
            max-height: rem(400);
        }
        .suggestion {
            position: absolute;
            top: rem(15);
            right: rem(15);
            font-weight: $font-light;
            color: #ffffff;
            text-shadow: 1px 1px 1px #000;
            margin-right: rem(15);
        }
        img {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
        .label-img {
            position: absolute;
            color: #fff;
            white-space: nowrap;
            font-size: 1.4rem;

            &.bottomRight {
                transform: translate(110px, 20px);
                right: 20%;
                top: 80%;
            }

            &.bottomLeft {
                transform: translate(-60px, 20px);
                left: 13%;
                top: 80%;
            }

            &.topLeft {
                transform: translate(-60px, 10px);
                left: 13%;
                top: 0%;
            }

            &.center {
                transform: translate(-50%, -50%);
                left: 50%;
                top: 50%;
            }
        }
    }
}
