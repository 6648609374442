@import "styles/vendor";

.CancellationPolicy {
    padding: rem(20);
    font-weight: 400;
    color: #000;
    &__header {
        font-weight: 400;
        padding-bottom: 0;
        border-bottom: 1px solid $gray;
        @include bp(768px down){
            border-bottom: 0 !important;
            padding-bottom: 0 !important;
        }
        b {
            font-size: 1.2rem;
            @include bp(768px down){
                font-size: 1rem;
                font-weight: 400;

            }

        }
        p {
            font-size: .9rem;
            @include tabletFontSize(.8rem);

        }
    }
    &__content {
        display: flex;
        @include bp(768px down){

        }
        &_switcher {
            display: flex;
            align-items: center;
            button {
                border: 1px solid #000;
                border-radius: 20px;
                padding: .5rem 1.5rem;
                background-color: #fff;
                font-size: .8rem;
                font-weight: 500;
                &.selected {
                    background-color: $yellow;
                    color: #fff;
                    font-weight: bold;
                    border: 1px solid $yellow;
                }
            }
        }
        &_type {
            width: 80%;
            margin: 3rem;
            @include bp(768px down){
                width: 100% !important;
                margin: 0 3rem;
            }
            h3 {
                padding-top: 8px;
                font-weight: bold;
                font-size: 1.5rem;
                margin-bottom: 1rem;
                @include bp(768px down){
                    font-size: 1rem;
                    font-weight: 400;
                }
            }
            p {
                margin-bottom: .5rem;
                @include tabletFontSize(.7rem);
            }
            .content-value {

            }
        }
    }
    .height-250 {
        height: 25% !important;
        border-bottom: 1px solid $gray;
        @include bp(768px down){
            flex-direction: column-reverse;
            align-items: center;
            border: 1px solid #e3e3e3 !important;
            padding: 1rem;
            margin: .5rem;
            border-radius: 1.5rem;
        }
    }
}
