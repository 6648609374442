@import "styles/vendor";

$grey-light: #e1e1e1;
.Navbar__Wrapper + .RegisterWizard {
	margin-top: 114px;
    @include bp(768px down){
        margin-top: 117px;
    }
    @include bp(520px down){
        margin-top: 69px;
    }
	@include bp(sm up) {
		margin-top: 65px;
		min-height: calc(100vh - 65px);
	}
	@include bp(md up) {
		margin-top: 75px;
		min-height: calc(100vh - 75px);
	}
	.profile-edit {
		margin-top: 55px;

		@include bp(sm up) {
			min-height: calc(100vh - 65px);
		}
		@include bp(md up) {
			min-height: calc(100vh - 75px);
		}
		#content-wrapper {
			#wizard-content {
				.layout-item {
					.open {
						height: 100%;
						min-height: 243px;
						padding: rem(8) rem(15) !important;
					}
				}
			}
		}
	}
}

.RegisterWizard {
	background-color: #ffffff;
	height: 100%;
	.profile-edit__main {
		.CancellationPolicy {
			padding: 4.625rem 6.1875rem;
            @include bp(768px down){
                padding: 0.625rem .5rem !important;
                border: 1px solid #969696;
                margin: 1.5rem;
            }
			.copyright {
				display: none;
			}
		}
	}
    .radio-group-wrapper {
        @include bp(768px down){
            margin-top: 0;
        }

        label.radio-group {
            span {
                @include tabletFontSize(1rem);
                @include phoneFontSize(.8rem);
                @include bp(768px down){
                    padding: .6rem 8px;
                }
            }
            @include bp(768px down){
                margin-right: 0;
                margin-left: 0;
            }
        }

    }


	.profile-edit {
		flex-direction: column;
		height: 100%;
		min-height: calc(100vh - 6.25rem);
		display: flex;

		.wrapper {
			.wrapper-url {
				.add-btn {
				}
			}
		}
		.type-switcher {
			border-bottom: 1px #c4c4c4 solid;
			display: flex;
			flex-direction: row;
			justify-content: center;
			flex: 0 0 0%;
			button {
				height: 4.5rem;
				background: none;
				border-radius: 0;
				border: none;
				@include bp(sm down) {
					height: rem(150 * 0.4);
				}
                @include bp(768px down){
                    width: 50%;
                }

				&.selected {
					color: $yellow;
					box-shadow: inset 0 -4px 0 0 $yellow;
				}
			}
		}
		.content-wrapper {
			display: flex;
			height: 100%;
			flex: 1 1 0%;
            @include bp(768px down){
                flex-direction: column;

            }
			.nav-menu,
			.side-panel {
				width: 100%;
                @include bp(768px down){
                    width: 85%;
                    margin: 1rem auto 2rem;
                }
				@include bp(768px up) {
					flex: 0 0 16.666667%;
					max-width: 16.666667%;
				}
			}
			.nav-menu {
                 @include bp(768px down){
                     z-index: 2;
                     margin-bottom: -1rem;
                 }
				.profile-edit__info {
					@include bp(768px down) {
						border: 1px solid #e1e1e1;
					}
				}
				button, a {
					background-color: #ffffff;
					&.active {
						background-color: $yellow;
						&:hover {
							color: #ffffff;
						}
					}
					&:hover {
						color: $yellow;
					}
				}
			}
			.content-main {
				border-right: 1px solid $grey-light;
				border-left: 1px solid $grey-light;

				@include bp(768px up) {
					flex: 0 0 66.666667%;
					max-width: 66.666667%;
				}
				@include bp(768px down) {
					flex: none;
					max-width: unset;
					flex-direction: column;
					width: 90%;
					margin: 0 auto;
				}
                @include bp(768px down){
                    width: 100%;
                    border: none;
                }
				.content-scrolled {
					&::-webkit-scrollbar {
						display: none;
					}
				}
			}
			.nav-menu {
				.btn-menu {
					@extend %btn-menu;
					margin: to-rem(10px);
				}
			}
		}
	}
}
