
$primary-color: #ffd210;
$succcess-color: #47b535;
$danger-color: #a50104;
$grey-button-color: #ccc;
$dark-button-color: #4a4a4a;
$black-button-color: #000;


.g-Button {
    background-color: $primary-color;
    border: 2px solid $primary-color;
    color: white;
    padding: 12px 32px;
    font-weight: 600;
    font-size: .95rem;
    white-space: nowrap;

    &.small {
        padding: 4px 8px;
        font-size: .75rem;
        font-weight: 500
    }

    &.primary {
        background-color: $primary-color;
        border: 2px solid $primary-color;

        &.outlined {
            color: $dark-button-color;
        }
    }
    
    &.success {
        background-color: $succcess-color;
        border: 2px solid $succcess-color;

        &.outlined {
            color: $succcess-color;
        }
    }

    &.danger {
        background-color: $danger-color;
        border: 2px solid $danger-color;

        &.outlined {
            color: $danger-color;
        }
    }

    &.dark {
        background-color: $dark-button-color;
        border: 2px solid $dark-button-color;

        &.outlined {
            color: $dark-button-color;
        }
    }

    &.grey {
        background-color: $grey-button-color;
        border: 2px solid $grey-button-color;

        &.outlined {
            color: $grey-button-color;
        }
    }

    &.black {
        background-color: $black-button-color;
        border: 2px solid $black-button-color;

        &.outlined {
            color: $black-button-color;
        }
    }

    &.flat { 
        background-color: transparent;
        border: 2px solid transparent;
        color: black;
    }

    &.disabled {
        border: 1px solid #ccc;
        color: #ccc;
        background-color: transparent;
    }
        
    &.outlined {
        background-color: transparent;
    }

    &.simpleBorder {
        border: 1px solid #ccc;
    }

    &.gRounded {
        border-radius: 25px;
    }

    &.fullWidth {
        width: 100%;
    }
}