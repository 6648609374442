@import "../vendor";

.review-form {
    @include position(absolute, 0 0 null null);
    z-index: 10;
    width: 100%;
    padding: 20px;
    border: 2px solid $yellow;
    background-color: #fff;
    @include bp(sm up) {
        width: 70%;
    }

    &__header {
        display: flex;
        width: 100%;
        @include bp(sm down) {
            flex-direction: column-reverse;
        }
        input {
            flex-grow: 1;
            padding-bottom: 5px;
            font-size: 1rem;
        }
    }
    &__stars {
        background-color: #fff;
        margin: auto 20px auto auto;
        @include bp(sm down) {
            margin: 0;
        }
        .fa-star,
        .fa-star-o {
            color: $yellow;
        }
    }
    .close {
        cursor: pointer;
        img {
            width: 0.875rem;
            height: 0.7rem;
            position: absolute;
            margin: 10px;
            top: 0;
            right: 0;
        }
    }
    .input-wrapper {
        input {
            border: none;
            outline: none;
            resize: none;
            width: 100%;
        }
        span {
            @include error;
        }
    }
    .textarea-wrapper {
        @include error;

        textarea {
            border: none;
            outline: none;
            resize: none;
            width: 100%;
            height: 140px;
            font-size: 0.875rem;
        }
    }
    .review-submit-btn {
        position: absolute;
        padding: 10px 60px;
        bottom: -22px;
        right: 20px;
        z-index: 11;
        background-color: $yellow;
        opacity: 1;
        font-size: 0.875rem;
        font-weight: 500;
        border-radius: 0;
        &[disabled] {
            background-color: #fff;
            border: 1px solid #b8b8b8;
        }
    }
}
