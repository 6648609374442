@import "../vendor";

.social-audio-list-wrapper {
    margin: 1.3rem 1.5rem;
    border: none;
    @include bp(768px down){
        margin: 1.3rem 1.5rem;
        border: 1px solid;
    }
    .spotify {
        padding-top: 2rem;
        i {
            margin-left: 1rem;
            cursor: pointer;
        }
        @include bp(768px down){
            text-align: center;
            padding: .5rem;
        }
    }
}

.content-reposition {
    margin-bottom: calc(50rem / 32);
    .reposition-btn {
        height: calc(50rem / 32);
        padding: 0 calc(25rem / 32);
        border: 1px solid #a3a3a3;
        outline: none;
        border-radius: 50px;
        font-size: 10px;
        font-family: Montserrat, serif;
        font-weight: 500;
        background-color: #ffffff;
        color: #000000;
        vertical-align: middle;
        line-height: calc(50rem / 32);
        &:before {
            @include pseudo(inline-block, relative);
            @include size(13px);
            transform: translateY(-1px);
            margin-right: 10px;
            background: url(../../assets/reposition.png) center center no-repeat;
            background-size: cover;
            vertical-align: middle;
            line-height: calc(50rem / 32);
        }
        &.active {
            background: #ffd210;
        }
    }
}
body > .edit-audio-item-wrapper {
    .position-number {
        right: 1.25rem;
        bottom: 1.25rem;
    }
}
.edit-audio-item-wrapper {
    position: relative;
    padding: 10px;
    width: percentage(1 / 3);

    @include bp(768px down){
        padding: 0;
    }
    @include bp(sm down) {
        width: 100%;
    }
    &:nth-child(1).add-more-block {
        width: percentage(1 / 3);
        @include bp(sm down) {
            width: 100%;
        }
    }
    &.add-more-block {
        width: percentage(1 / 3);
        @include bp(sm down) {
            width: 100%;
        }
        @include bp(768px down){
            padding: 0;
            margin-bottom: 1rem !important;
        }
        .aspect-ratio-container {
            padding-top: 13.2vw;
            .aspect-ratio-content {
                @include bp(768px down){
                    height: initial !important;
                }
                .edit-add-new {
                    @include bp(768px down){
                        margin: 0;

                    }
                }
            }
        }
    }
    .audio-item {
        &__image {
            @include size();
            padding: rem(20);
        }
        &__title {
            color: #fff;
            font-size: rem(16);
        }
        &__subtitle {
            color: $gray;
            font-size: rem(10);
        }
        &__button {
            color: #fff;
            background: none;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 0;
            margin-top: 20%;
            span:first-of-type {
                img {
                    width: rem(20);
                    height: rem(20);
                    margin-right: rem(5);
                }
            }
            span {
                #upload_svg {
                    fill: #fff;
                }
            }
        }
    }
}

.edit-audio-list-container {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @include bp(sm down) {
        flex-direction: column;
    }
    .aspect-ratio-container {
        position: relative;
        width: 100%;
        padding-top: 13.2vw;
        @include bp(sm down) {
            min-height: rem(142);
            padding: 13.2vw 0;
        }
        @include bp(768px down){
            padding: 35vw;
        }
    }
    .aspect-ratio-content {
        @include position(absolute, 0 null null 0);
        @include size();
        @include bp(768px down){
            min-height: 65vw;
        }
        h3 {
            @include tabletFontSize(1.2rem !important);
            @include phoneFontSize(.7rem !important);
        }
        .edit-overlay {
            .edit-add-new {
                @include bp(768px down){
                    margin: 0 !important;
                }
            }
            &__wrapper{
                .upload-button {
                    svg {
                        @include bp(768px down){
                            fill: #fed232;
                        }
                    }
                    @include bp(768px down){
                        width: initial;
                        left: 25%;
                    }
                }
                h3 {
                    @include tabletFontSize(1.2rem !important);
                    @include phoneFontSize(.7rem !important);
                }
            }
        }
        min-height: rem(142);
        display: flex;
        video {
            display: none;
        }
    }

}

.edit-add-new {
    display: flex;
    width: 100%;
    height: 100%;
    .wrapper {
        &.open {
            width: 100%;
            background-color: $light-gray;
            padding: rem(10) rem(15);
            box-shadow: rem(5) rem(5) rem(10) rem(-5) rgba(0,0,0,0.5);
            opacity: 0.99;
            .wrapper-input {
                width: 100%;
            }
            .name-input {
                width: 100%;
                color: #000;
                background: none;
                outline: none;
                border: none;
                border-bottom: 1px solid $gray;
                //margin-top: rem(10);
                &::placeholder {
                    color: #000;
                }
            }
            .hashtags-input {
                width: 100%;
                background: none;
                outline: none;
                color: #000;
                border: none;
                border-bottom: 1px solid $gray;
                margin-top: rem(10);
                &::placeholder {
                    color: #000;
                }
            }
            .subtitle {
                font-size: rem(10);
                color: $gray;
            }
            .upload-button {
                margin-top: 10%;
                width: 100%;
                background: none;
                outline: none;
                border: none;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 0;
                z-index: 20;
                font-size: 0.9rem;
                span:first-of-type {
                    img {
                        width: rem(20);
                        height: rem(20);
                        margin-right: rem(5);
                    }
                }
            }
            .wrapper-url {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                .add-button {
                    flex-grow: 1;
                }
            }
        }
    }
    &__close {
        position: absolute;
        right: 2%;
        top: 2%;
        cursor: pointer;
    }
    .control {
        display: flex;
        align-items: center;
        margin-bottom: calc(25rem / 32);
    }
    .audio-url {
        margin-left: 10px;
        border-radius: 4px;
        border: 1px solid lightgray;
    }
    .description-title {
        font-size: 16px;
        margin-bottom: calc(16rem / 32);
    }
    .description-content {
        font-size: 10px;
        color: #a3a3a3;
    }
}
