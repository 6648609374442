@import "styles/vendor";

.RegisterHeader {
    width: 100%;
    position: relative;
    height: rem(100);
    display: flex;
    align-items: center;
    padding: 0 rem(37);
    justify-content: space-between;
    @include bp(520px down){
        height: 3rem;
    }
    &__logo {
        position: absolute;
        .logo {
            @include size(rem(35));
            display: inline-block;
            flex: 0 0 rem(35);
            cursor: pointer;
        }
    }
    &__notifications {
        font-size: rem(24);
        font-weight: 300;
        width: 100%;
        text-align: center;
        @include bp(768px down){
            display: none;
        }
    }
    &__close {
        top: 2.5rem;
        right: 2.5rem;
        position: absolute;
        background: transparent;
        border-radius: 50%;
        @include size(3rem);
        @include bp(520px down){
            img {
                width: 11px !important;
                height: 11px !important;
            }
        }
        @include bp(520px down){
            top: 0.1rem;
            right: -0.1rem;

        }
        img {
            @include size(rem(15));
            display: block;
            cursor: pointer;
        }
    }
    &__progress {
    }
    .AlertPopUp,
    .ConfirmPopUp {
        position: absolute;
    }
}
