@import "./utils";
@import "./breakpoints";
@import "./placeholders";

@mixin adaptive-height-element($column) {
    // only if root element have
    // display: block;
    // and elements inside have
    // position: absolute; top: 0; left: 0; height: 100%; width: 100%;
    $width: calc(100% / #{$column} - 1%);
    width: $width;
    padding-top: calc(#{$width} * 1.366);
    margin: 0 0.5% 1%;
    position: relative;
    height: 0;
    float: left;
}

@mixin adaptive-height-element-second($column) {
    // only if root element have
    // display: block;
    // and elements inside have
    // position: absolute; top: 0; left: 0; height: 100%; width: 100%;
    $width: calc(100% / #{$column} - 1%);
    width: $width;
    padding-top: calc(#{$width} * 1.366);
    margin: 0 0.5% 1% 0%;
    position: relative;
    height: 0;
    float: left;
}

@mixin alert-modal {
    .skylight-close-button {
        right: calc(40rem / 32) !important;
        font-size: calc(130rem / 32) !important;
        line-height: .73;
    }
    .skylight-dialog {
        @include bp(sm down) {
            width: 80% !important;
            left: unset !important;
            margin-left: unset !important;
        }
    }
    .modal {
        &__header {
            box-sizing: border-box;
            height: rem(50);
            background-color: $red;
            padding-top: rem(7);
            padding-left: rem(10);
            padding-right: rem(10);
            color: #ffffff;
            line-height: 1.25;
            font-size: rem(30);
            font-weight: 400;
            text-transform: uppercase;
            text-align: center;
            cursor: default;
        }

        &__main {
            padding: rem(65) rem(68);
            color: $gray;
            text-align: justify;
            font-size: rem(18);
            font-weight: 300;
            @include bp(sm down) {
                padding: rem(65 * .3) rem(68 * .3);
            }
        }

        &__next {
            box-sizing: border-box;
            width: 100%;
            height: rem(50);
            padding: 0 rem(10);
            margin: auto;
            border: none;
            background-color: $yellow;
            color: #ffffff;
            font-size: rem(18);
            line-height: rem(50);
            cursor: pointer;
            font-weight: 600;
            text-align: center;
        }
    }
}

@mixin error {
    color: $red;
    font-size: rem(12);
}
@mixin form-input-color {
    input {
        color: #000!important;
        font-weight: 300!important;
    }
}

@mixin main-button {
    box-sizing: border-box;
    width: 100%;
    height: rem(52.5);
    padding: 0 rem(10);
    margin: rem(100) auto 0;
    background-color: #ffd210;
    border: none;
    color: #ffffff;
    font-size: rem(18);
    font-weight: 600;
    letter-spacing: .7px;
    opacity: 1;
    transition: opacity 0.3s;
}

@mixin main-button__disabled {
    opacity: .5;
    cursor: default;
}

@mixin subtitle {
    color: $gray-hue-2;
    font-size: rem(12);
    font-weight: 300;
    display: flex;
    position: relative;
}
@mixin title-description {
    .description {
        font-size: rem(12);
        font-weight: 300;
        color: gray;
    }
}

@mixin informative-title {
    color: #000;
    font-size: rem(16);
    font-weight: 500;
    display: flex;
    position: relative;
    width: 100%;

    .fa {
        &-stack {
            position: absolute;
            left: calc(-70rem / 32);
            top: 0;
            //font-size: calc(20rem / 32);

            @include bp(sm down){
                position: relative;
                left: 0;
                margin-right: .25rem;
            }
        }
    }



    &.not-pulled {
        .fa {
            &-stack {
                position: relative;
                left: 0;
                top: 0;
            }
        }
    }
    @include title-description;


}

@mixin radio-chips-button {
    overflow: hidden;

    @include bp(sm down) {
        margin-left: 0;
        margin-right: 0;
    }

    .radio-group {
        float: left;
        width: calc(100% * (1 / 3) - 34px);
        margin: rem(13) rem(16);
        text-align: center;

        @include bp(sm down) {
            width: 100%;
        }

        span {
            display: inline-block;
            width: 100%;
            border-radius: rem(50);
            padding: rem(15) 1rem;
            border: rem(1) solid $gray;
        }
        input[type="radio"]:checked + span {
            font-weight: $font-medium;
            background: $yellow;
            border-color: $yellow;
            color: #fff;
        }
    }
}

@mixin radio-chips-button-small {
    overflow: hidden;

    .radio-group {
        float: left;
        width: rem(165);
        @include bp(sm down) {
            width: 100%;
        }
        height: rem(50);
        margin: rem(13) rem(16);
        margin-left: 0;
        text-align: center;

        @include bp (sm down) {
            margin-left: 0;
            margin-right: 0;
        }

        span {
            display: inline-block;
            width: 100%;
            border-radius: rem(50);
            padding: rem(15) 0;
            border: rem(1) solid $gray;
        }
        input[type="radio"]:checked + span {
            background: $yellow;
            border-color: $yellow;
            color: #fff;
        }
    }
}

@mixin checkbox-chips-button-small {
    overflow: hidden;
    margin-left: rem(-16);
    margin-right: (-16);
    .radio-group {
        float: left;
        width: rem(165);
        height: rem(50);
        margin: rem(13) rem(16);
        text-align: center;
        span {
            display: inline-block;
            width: 100%;
            border-radius: rem(50);
            padding: rem(15) 0;
            border: rem(1) solid $gray;
        }
        input[type="checkbox"]:checked + span {
            background: $yellow;
            border-color: $yellow;
            color: #fff;
        }
    }
}

// IMAGES
@mixin AvatarImage($width: 146px, $height: 146px, $top: "", $left: "", $bottom: "", $right: "") {
    @extend %avatar-image;
    width: rem($width);
    height: rem($height);
    @if ($top != "") {
        top: $top;
    }
    @if ($left != "") {
        left: $left;
    }
    @if ($bottom != "") {
        bottom: $bottom;
    }
    @if ($right != "") {
        right: $right;
    }
}


@mixin spinner {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 998;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
        content: " ";
        display: block;
        width: 46px;
        height: 46px;
        margin: 1px;
        border-radius: 50%;
        border: 5px solid #ffd210;
        border-color: #ffd210 transparent #ffd210 transparent;
        animation: spinner-keyframe 1.2s linear infinite;
    }
}

@keyframes spinner-keyframe {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
