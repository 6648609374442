@import '../../../../styles/vendor';
$gray: #eee;
$cell_padding: 10px 5px;

.plans-mobile {
    width: 85%;
    margin: 0 auto;

    .plans-switcher {
        display: flex;
        border: 1px solid black;

        .plan-header {
            flex: calc(100% / 3) 0 0;
            padding: $cell_padding;
            text-align: center;

            &.selected {
                background-color: #ffd210;
                color: white;
                font-weight: 600;
            }
        }
        .plan-header + .plan-header {
            border-left: 1px solid black;
        }
    }

    .plans-content {
        border: 1px solid black;
        border-top: none;
        min-height: 1rem;

        .content-header {
            background-color: $gray;
            padding: $cell_padding;
            text-align: center;
        }

        .content-row {
            border-top: 1px solid $gray;
            display: flex;
            font-size: 0.8rem;

            .row-header, .row-content {
                flex: 50% 0 0;
                padding: $cell_padding;
                display: flex;
                justify-content: center;
            }
            .row-header {
                flex-direction: column;
                border-right: 1px solid $gray;

                .header-description {
                    color: #888;
                }
            }
            .row-content {
                align-items: center;
                text-align: center;
                font-weight: 500;

                &.font-size-biggest {
                    font-size: 1rem;
                }
            }
        }
    }
}