@import "styles/vendor";
.edit-overlay {
    height: 100%;
    width: 100%;
    box-shadow: 0.3125rem 0.3125rem 0.625rem -0.3125rem rgba(0, 0, 0, 0.5);
    .videoBlock {
        height: 100%;
        .media {
            height: 100%;
            .media-player {
                height: 100%;
            }
        }
    }
    &__wrapper {
        width: 100%;
        height: 100%;
        padding: 1rem;
        .tags-list {
            max-height: 3rem;
            overflow: hidden;
        }
        .upload-button {
            //margin-top: 10%;
            //position: absolute;
            //bottom: 0.5rem;
            margin-bottom: 0;
            margin-top: auto;
            width: 100%;
            color: #fff;
            background: none;
            outline: none;
            border: none;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0;
            z-index: 20;
            span:first-of-type {
                img {
                    width: rem(20);
                    height: rem(20);
                    margin-right: rem(5);
                }
            }
        }

        h3 {
            color: #fff !important;
            overflow: hidden;
            margin-bottom: 0.5rem;
        }

        &.video-overlay{
            h3, .tags-list, .upload-button {
                color: grey !important;
            }
        }
    }
}
