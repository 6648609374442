@import '../vendor';
@import '../breakpoints';

$form-width: calc(1050rem / 32);
$transition-delay: 0.3s;
$grey: #a3a3a3;
$grey-light: #e1e1e1;

.test {
	color: #333;
}

.service-payments-form {
	margin: 4.625rem 6.1875rem;
	display: flex;
	flex-direction: column;
	align-items: center;

    @include bp(768px down){
        margin: 1.3125rem 1.5rem !important;
        padding: 1rem;
        border: 1px solid #969696;
    }
	@include bp(sm down) {
		margin: (4.625rem / 2) (6.1875rem / 2);
	}
	&__wrapper {
		width: 100%;
		margin: 0 0 1.75rem;
        @include bp(768px down){
            margin: 0 0 0.75rem;
        }

		&--wo-heading {
			margin: -2.25rem 0 -0.5rem;
		}
	}
}


.wizard-plans-payments {
    background-color: $light-gray;
    padding-top: rem(376 / 2);
    &--header{
        margin-bottom: rem(60 / 2);
        color: #000000;
        font-size: rem(94 / 2);
        font-weight: bold;
    }
    &--subheader {
        font-size: 22px;
        margin-bottom: rem(60 / 2);
    }
    &--header-description{
        margin-bottom: rem(60 / 2);
        color: #000000;
        font-size: rem(24 / 2);
        line-height: rem(34.7 / 2);
    }
    &--header-button{
        margin-bottom: rem(60 / 2);
        color: #000000;
        font-size: rem(24 / 2);
        line-height: rem(34.7 / 2);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
    &--item {
        font-weight: bold;
        font-size: 25px;
        padding-top: 2rem;
        padding-bottom: 2rem;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        p {
            float: right;
            margin-right: 1rem;
        }
        &-icon {
            text-align: left;
            margin-left: 1rem;
        }
        i {
            -webkit-text-stroke: 2px $light-gray;
        }
    }
}
