@import "styles/vendor";

.FreeQuote {
    position: relative;
    padding: 0 20px;

    &__title {
        font-size: 0.875rem;
        font-weight: bold;
        color: #ffd210;
        margin-bottom: 0;
        border-bottom: 1px solid $gray;
        padding: 15px 0;
        cursor: pointer;
        @include tabletFontSize(1.4rem);
        @include phoneFontSize(.9rem);
        i.fa {
            border: 1.5px solid $yellow;
            padding: 4px 8px;
            border-radius: 3px;
        }
    }
    &__popup {
        margin: 0 -20px;
        padding: 0 20px 20px;
        border: 1.5px solid $yellow;
        border-top: 0;
        border-bottom: 1px solid $gray;
    }
}
