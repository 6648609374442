@import '../vendor';
@import '../breakpoints';

$form-width: calc(1050rem / 32);
$transition-delay: 0.3s;
$grey: #a3a3a3;
$grey-light: #e1e1e1;

.test {
	color: #333;
}


.profile-edit {
	display: flex;
	justify-content: space-between;
	border-top: 1px solid $grey-light;
	&__main {
        .service-info-form__item {
            textarea {
                @include tabletFontSize(1rem);
                @include phoneFontSize(.8rem);
            }
            div:nth-child(2){
                @include tabletFontSize(1rem);
                @include phoneFontSize(.8rem);
            }
        }
		.container-fluid {
			padding: 1.3rem 1.5rem;
			@include bp(sm down) {
				padding: 1.3rem 1.5rem;
			}
			.video-block-main {
                @include bp(768px down){
                    border: 1px solid black;
                }
                .row {
                    &>div {
                        @include bp(768px down){
                            padding: 0 !important;
                        }
                    }
                    &>div:first-child{
                        padding-bottom: .7rem !important;
                    }
                    @include bp(768px down){
                        margin: 0 !important;
                    }
                }
				.upload-instructions {
					padding-top: 2rem;
					i {
						margin-left: 1rem;
						cursor: pointer;
					}
                    .steps {
                        @include bp(768px down){
                            padding: .5rem !important;
                        }
                    }
                    @include bp(768px down){
                        justify-content: center !important;
                    }
				}
				.media-block {
					//@include bp(sm up) {
						//max-height: 295px;
					//}
					@include bp(lg up) {
						min-height: 140px;
					}
                    .edit-overlay__wrapper {

                        @include bp(768px down){
                            &>div {
                                font-size: .7rem;
                                h3 {
                                    font-size: .7rem !important;
                                }
                            }
                            button.upload-button {
                                justify-content: center !important;
                                font-weight: 500 !important;
                                .SVGInline {
                                    display: none;
                                }
                            }
                        }
                    }
				}
				.position-number {
					display: none;
				}
				.items-additions {
                    min-height: 390px;
					margin-top: 5px;
                    @include bp(768px down){
                        margin-top: .7rem !important;
                    }
					.layout-item {
						min-height: 390px;
                        @include bp(768px down){
                            height: initial !important;
                        }
					}
					.layout-item-addition {
						margin-bottom: 14px;
					}
				}
			}
			.row {
				.layout-item {
					&:first-of-type {
						margin-bottom: 14px;
					}
					position: relative;
					.edit-add-new {
						.name-input {
							margin-top: 0;
						}
						.hashtags-input {
							margin-top: 0.2rem;
						}
						.video-url {
							margin-top: 0;
						}
						.media-video-link {
							margin-top: -5px;
						}
					}
					&-full {
						padding: 8px;
						@include bp(sm up) {
							& > div:first-child {
								height: 100%;
								& > div:first-child {
									height: 100%;
									.edit-add-new {
										align-items: flex-start;
										.wrapper.open {
											max-height: 100%;
											@include bp(lg up) {
												height: 500px;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		.custom-audio-player__wrapper {
			display: none;
		}
		.skip-button {
			margin: 3.625rem 5.1875rem;
		}
	}
	&__info {
		//min-height: rem(1570 / 2);
		//border-right: 1px solid $grey-light;
		@include bp(sm down) {
			min-height: unset;
			display: flex;
			flex-direction: column;
		}

		button, a {
			@include size(100%, rem(100 / 2));
			position: relative;
			display: block;
			padding-left: 20%;
			background-color: transparent;
			border: none;
			color: #000;
			text-align: left;
			font-size: rem(28 / 2);
			font-weight: 400;
			transition: background-color $transition-delay, color $transition-delay;
			outline: none;

			@include bp(992px down) {
				min-height: unset;
			}

			@include bp(768px down) {
				padding-left: 5%;
			}

			&.active,
			&.focus,
			&:hover {
				color: #fff;
				background-color: $yellow;
			}

			&.done {
				.check-mark {
					display: block;
				}
			}
		}
	}

	.check-mark {
		display: none; // ???
		@include position(absolute, rem(30 / 2) rem(40 / 2) null null);
		@include size(rem(44 / 2) rem(31 / 2));
	}

	&__missing {
		//min-height: rem(1200 / 2);
		//border-left: 1px solid $grey-light;
		text-align: center;
		// @include bp(sm down) {
		// 	min-height: auto;
		// }
	}
}

.missing {
	&__title {
		margin-bottom: -12px;
		color: #000000;
		font-size: rem(28 / 2);
		font-weight: 300;
		line-height: rem(64 / 2);
		&--photo-history {
			padding: sr(53px) 0;
			display: inline-block;
		}
	}

	&__info {
		color: $grey;
		border-bottom: 1px solid $grey-light;
		padding-bottom: rem(44 / 2);
		font-size: rem(18 / 2);
		line-height: rem(64 / 2);
		font-weight: 300;
	}

	&__save {
		width: calc(100% + 1px);
		margin-left: -1px;
		height: rem(105 / 2);
		padding: 0 20px;
		margin-top: auto;
		margin-bottom: 0;
		border: none;
		background-color: $yellow;
		color: #ffffff;
		font-size: rem(36 / 2);
		font-weight: 600;
		line-height: rem(63 / 2);
		letter-spacing: 0.7px;
        @include tabletFontSize(1rem);
        @include phoneFontSize(1.7rem);
		&:disabled {
			//border: 1px solid #a3a3a3;
			//background-color: transparent;
			//color: #a3a3a3;
			background-color: #a3a3a3;
			cursor: not-allowed;
		}
		img {
			height: 100%;
		}
	}

	&__validation {
		padding-left: rem(50 / 2);
		padding-right: rem(50 / 2);

		li {
			padding-top: rem(40 / 2);
			padding-bottom: rem(30 / 2);
			border-bottom: 1px solid $grey-light;
			color: $red;
			font-size: rem(24 / 2);
			font-weight: 300;
		}
	}
}

.service-detail-form {
	margin: 4.625rem 6.1875rem;
	display: flex;
	flex-direction: column;
	align-items: center;

    @include bp(768px down){
        margin: 1.3125rem 1.5rem !important;
        padding: 1rem;
        border: 1px solid #969696;
    }
	@include bp(sm down) {
		margin: (4.625rem / 2) (6.1875rem / 2);
	}
	&__wrapper {
		width: 100%;
		margin: 0 0 1.75rem;
        @include bp(768px down){
            margin: 0 0 0.75rem;
        }

		&--wo-heading {
			margin: -2.25rem 0 -0.5rem;
		}
	}
	&__subtitle {
		@include subtitle;
		margin: rem(22.5) 0 rem(2);
        @include bp(768px down){
            margin: .4rem 0 .125rem;
        }
	}
	&__price {
		margin-bottom: 1rem;
		&-wrapper {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
            @include bp(768px down){
                flex-direction: column;
            }


            div {
                @include bp(768px down){
                    width: 100% !important;
                    margin: 0 !important;
                    font-size: 1rem !important;
                }
                @include bp(520px down){
                    font-size: .7rem !important;
                }
                div {
                    div:first-child {
                        @include tabletFontSize(1rem !important);
                        @include phoneFontSize(.8rem !important);

                    }
                }
            }
            input {
                @include tabletFontSize(1rem !important);
                @include phoneFontSize(.8rem !important);

            }
			.first-line {
				width: 100%;
			}
			.selectForm {
				margin-top: 0;
				margin-bottom: rem(6);
			}

			@at-root .service-detail-form .price {
				&-name {
					width: 40%;
					margin-right: 7%;
					flex-shrink: 0;
					textarea {
						width: rem(320);
					}
				}

				&-container {
					width: 53%;
				}

				&-count {
					width: 25%;
				}

				&-currency {
					width: 20%;
					div {
						div:nth-child(2) {
							div:first-child {
								div:nth-child(2){
									@include tabletFontSize(1rem !important);
									@include phoneFontSize(.8rem !important);
									@include bp(768px down) {
										padding-left: 1rem !important;
									}
								}
							}
						}
					}
				}

				&-period {
					width: calc(50% - 1.3125rem);
				}

				&__price-description {
					width: calc(98.3333% - 1.3125rem);
				}

				&__control-wrappper {
					display: flex;
					justify-content: space-between;
					align-items: flex-start;
					margin-top: 1.5rem;
                    @include bp(768px down){
                        justify-content: center;
                    }
				}

				&__more-button {
					background-color: transparent;
					flex-grow: 0;
					border: none;
					font-size: rem(14);
					color: $grey;
					display: flex;
					justify-content: center;
					align-items: center;
                    span {
                        @include bp(768px down){
                            font-size: .6rem;
                            text-transform: lowercase;
                            text-decoration: underline;

                        }
                    }
					.fa {
						width: rem(21);
						height: rem(21);
						border: 2px solid $grey;
						color: $grey;
						border-radius: 50%;
						margin-right: 0.5rem;
                        @include bp(768px down){
                            display: none;
                        }
						&::before {
							position: relative;
							top: rem(2);
                            @include bp(768px down){

                            }
						}
					}
				}
			}
		}
		&-wrapper.price-list {
            .first-line {
                @include bp(768px down){
                    flex-direction: column;
                }
                & > div:last-child {
                    @include bp(768px down){
                        text-align: center;
                        i {
                            border: 0 !important;
                            width: initial !important;
                            &:before {
                                content: 'remove';
                                font-size: .7rem;
                                text-decoration: underline;
                                top: initial;
                                left: initial;
                            }
                        }
                    }
                }
                @include bp(768px down){

                }
            }
			flex-direction: column;
			align-items: flex-end;
            @include bp(768px down){

            }
		}
	}
	&__item {
        &>div:nth-child(2){
            div:first-child {
                div:first-child, input {
                    @include tabletFontSize(1rem !important);
                    @include phoneFontSize(.8rem !important);


                }
            }
        }
		.expirience-error {
			position: relative;
			bottom: 2px;
			font-size: 12px;
			line-height: 12px;
			color: rgb(244, 67, 54);
			transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
		}
		margin-bottom: 1rem;
		.multiple {
			.selectForm {
				.Select {
					&-value,
					&-clear-zone {
						display: none;
					}
				}
			}
		}
		.selectForm {
			margin-top: rem(20);
			position: relative;
			&__wrapper {
				position: relative;
			}
			&__arrow {
				position: absolute;
				width: rem(20);
				height: rem(30);
				top: rem(4);
				right: 0;
				i {
					font-size: rem(30);
				}
			}
			&:focus {
				outline: none;
				box-shadow: none;
			}
			.Select {
				&-control {
					order: 1;
					width: 100%;
					border: none;
					border-radius: 0;
					border-bottom: 1px solid $gray;
					box-shadow: none;
					outline: none;
					font-size: 1.5rem;
					&:focus {
						outline: none;
						border-color: $yellow;
					}
				}
				&-clear-zone {
					display: none;
				}
				&-arrow {
					display: none;
				}
			}
		}
		&-header {
			@include informative-title;
            .fa-stack {
                @include bp(768px down){
                    display: none;
                }
            }
		}
	}
	&__item-content {
		@at-root .talent-tags-controller {
			%control-row {
				flex-grow: 0;
				padding: 0 15px;
			}

			&__control {
				display: flex;
				justify-content: space-between;
				align-items: baseline;
				margin: 20px -15px 0;
			}

			&__control-desc {
				@extend %control-row;
				font-size: 12px;
				font-weight: 300;
				color: #a1a1a1;
			}

			&__control-form {
				@extend %control-row;
			}

			&__control-add {
				@extend %control-row;
				button {
					@extend %simple-btn--narrow;
				}
			}

			&__preview {
				display: flex;
				flex-wrap: wrap;
				margin-top: 20px;
			}
		}
	}
	&__input-option {
		width: percentage(1 / 3);
		display: inline-block;
		label {
			display: flex;
			align-items: center;
		}
		.checkbox {
			@include size(rem(16));
			display: inline-block;
			margin-right: 0.5rem;
			background-color: $light-gray;
		}
		input {
			display: none;
		}
	}
	&__input-subtitle {
		margin-top: (1.5rem / 2);
		@include subtitle;
        @include bp(768px down){
            display: block;
        }
	}
	&__custom-chips {
		@at-root .custom-chips {
			&__item {
				background-color: $light-gray;
				font-size: rem(14);
				border-radius: rem(20);
				padding: rem(2) rem(10);
				display: flex;
				justify-content: center;
				align-items: center;
				color: $grey;
				margin: 0.4rem;
			}

			&__wrapper {
				display: flex;
				flex-wrap: wrap;
			}

			&__top-chips {
				min-width: rem(80);
				min-height: rem(20);
				background-color: #fff;
				border: 1px solid $gray;
			}
		}
	}
	.radio-group-wrapper {
		margin-top: rem(14);
		@include radio-chips-button;

        @include bp(768px down){
            margin-left: 0;
            margin-right: 0;
        }
	}
	.radio-group-wrapper__small {
		margin-top: rem(14);

        @include bp(768px down){
            display: flex;
            flex-direction: column;
            justify-content: center;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            margin-left: 1rem;
            margin-right: 1rem;
        }
		@include radio-chips-button-small;
        .radio-group {
            @include bp(768px down){
                width: initial !important;
                margin: .4rem 0 !important;
            }
            span {
                @include tabletFontSize(1rem);
                @include phoneFontSize(.8rem);
                @include bp(768px down){
                    padding: .6rem 0 !important;
                }
            }
        }

	}
	.number-input {
		textarea {
			resize: none;
			border: 0;
			border-bottom: 1px solid $gray;
			outline: none;
			max-height: rem(40) !important;
			font-size: 1.5rem;
			&:focus {
				border-color: $yellow;
			}
			&::placeholder {
				font-size: 1.5rem;
				font-weight: 300;
			}
		}
	}
}
