@import "styles/vendor";
@import '~gemini-scrollbar/gemini-scrollbar.css';

.FeedCategory {
    background-color: #ffffff;
    padding: 0;
    position: relative;
    .option {
        display: flex;
    }
    &_main {
        .FeedCategoryContentContainer {
            .option{
                &-title {
                    @include phoneFontSize(1.3rem);
                }
            }
        }
    }
    @include bp(768px down) {
        width: calc(50% - 2rem);
        margin: .5rem .3rem .5rem 1rem;
        box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3);
        &__main {
            padding-top: 100%;
            height: 0;
            .FeedCategoryContentContainer {
                position: absolute;
                top: 120px;
                left: 0;
                right: 0;
                padding: 0;
                display: flex;
                flex-direction: column;
                align-items: center;

                .option {
                    background: #fff;
                    opacity: .7;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    color: #000 !important;
                    font-weight: bold;
                }
            }
        }
        &.expanded {
            margin: 0;
            .cover {
                background-image: none !important;
            }
            .FeedCategory {
                &__main {
                    padding-top: 0;
                    height: auto;
                    .FeedCategoryContentContainer {
                        .option {
                            .option-top {
                                display: none;
                            }
                        }
                        .feed-view-more {
                            //height: calc(100vh - 120px);
                            .gm-scroll-view {
                                @include bp(520px down){
                                    overflow-x: hidden;
                                }
                                & > div {
                                    display: flex;
                                    align-items: center;
                                    flex-direction: column;
                                    background: #f3f3f3;

                                }
                                .GigCard {
                                    @include bp(768px down) {
                                        margin-left: 0 !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .buttonNewsToggle {
        z-index: 999;
    }
    .cover {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        overflow: hidden;
        height: 100%;
        background-repeat: repeat-x;
        background-size: cover;
        background-position-y: 50%;
    }
    .FeedCategoryName {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 1.2rem 0;
        background: rgba(255, 255, 255, 0.7);
        font-size: 1rem;
        font-weight: 600;
        color: black;
        text-align: center;

        @include bp(768px down) {
            padding: 0.8rem 0;
            font-size: 0.9rem;
        }
    }
    .gradient {
        background: linear-gradient(to right, black 0%, rgba(0, 0, 0, .4) 20%, transparent 40%, transparent 100%);
        transition: all .3s;
        width: 100%;
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        @include bp(768px down) {
            display: none;
        }
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: .5;
            background: #000;
            z-index: 2;
        }
    }
    &:hover {
        .gradient:before {
            content: none;
        }
    }
    &ContentContainer {
        @include bp(768px down) {
            flex-direction: column;
        }
        @include bp(1440px up) {
            max-width: 1310px;
            padding: 0;
        }
        .option {
            z-index: 0;
            flex-shrink: 0;
            color: #ffffff;
            justify-content: center;
            @include bp(768px down) {
                width: 100%;
            }
            @include bp(768px up) {
                width: 20%;
                flex-direction: column;
                min-height: 442px;
            }
            &-title {
            }
            &-description {
                font-size: rem(12);
                font-weight: $font-light;
                line-height: 1.25;
            }
            &-button {
                background: transparent;
                height: 3.7rem;
                width: 100%;
                color: #ffffff;
                border: 1px solid #ffffff;
                @include bp(768px down) {
                    max-height: 40px;
                }
            }
        }
        .feed-view-more {
            @include bp(768px down) {

            }
            @include bp(sm up) {
                margin-left: 0;
                padding-bottom: 50px;
                height: 100%;
            }
            .gm-scroll-view {
                @include bp(ss up) {
                    flex-wrap: nowrap;
                    overflow-x: scroll;
                    & > div {
                        display: flex;
                        width: 100%;
                        flex-wrap: wrap;
                    }
                }
                @include bp(sm up) {
                    flex-wrap: wrap;
                    overflow-x: hidden;
                }
            }
        }
        .list-wrapper {
            display: flex;
            flex-wrap: nowrap;
            @include bp(768px down) {
                .GigCard {
                    display: none;
                }
            }
            @include bp(768px up) {
                flex-grow: 3;
                width: 80%;
            }
            @include bp(1320px down) {
                .GigCard {
                    &:nth-child(3) {
                        display: none;
                    }
                }
            }
            @include bp(962px down) {
                .GigCard {
                    &:nth-child(2) {
                        display: none;
                    }
                }
            }
        }
    }
    &.expanded {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        padding-top: 120px;
        margin-bottom: 50px;
        z-index: 98;
        .FeedCategoryContentContainer {
            @include bp(768px down) {
                .option {

                }
            }
            @include bp(768px up) {
                //height: calc(100vh - 120px);
            }
            .option{
                &-title {
                    @include bp(520px down){
                        font-size: 1.3rem;
                    }
                }
            }

        }
        .FeedCategory__GigsList .GigCard {
            margin: 8px !important;
        }
        .cover {
            display: block;
        }
        .swipe-mobile {
            position: absolute;
            @include bp(ss up){
                display: block;
                bottom: 41px;
                right: 7px;
            }
            @include bp(sm up){
                display: none;
            }
        }
        .option {
            justify-content: space-between;
            min-width: auto;
            .option-title{
                color: #ffffff;

                @include bp(520px down) {
                    font-size: 1.3rem;
                }
            }
            @include bp(sm up){
                justify-content: flex-start;
                padding-top: 0;
                width: 20%;
            }
            &-top{
                .option-title {
                    @include bp(ss up){
                        font-size: rem(28);
                    }
                    @include bp(sm up){
                        width: 100%;
                        position: relative;
                        padding-left: 0;
                    }

                }
                .option-description{
                    @include bp(ss up){
                        display: none;
                    }
                    @include bp(sm up){
                        display: block;
                    }
                }
            }
            &-button {
                background: transparent;
                @include bp(768px down){
                    width: 100%;
                    color: black;
                    border: 1px solid black !important;

                    svg {
                        fill: black;
                        height: 10px;
                    }
                }
                @include bp(768px up){
                    max-width: none;
                    width: 100%;
                    color: #ffffff;
                    border: 1px solid #ffffff !important;
                    text-align: center;
                    margin-left: 0;

                    svg {
                        height: 10px;
                        display: none;
                    }
                }
            }
        }
        .gradient {
            display: block;
            @include bp(768px down) {
                display: none;
            }
            &:before {
                content: none;
            }
        }
    }
    // &:hover {
    //     .option {
    //         color: #ffffff;
    //         &-button {
    //             background: transparent;
    //             color: #ffffff;
    //             border: 1px solid #ffffff;
    //         }
    //     }
    // }
}
