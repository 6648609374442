@import "styles/vendor";

.ComparePopUp {
    left: 0;
    width: 100%;
    z-index: 1;
    bottom: 20px;
    position: fixed;
    &__paper {
        display: flex;
        flex-wrap: wrap;
        min-width: 16.25rem;
        .row {
            align-items: center;
            align-content: center;
            justify-content: flex-start;

        }
    }
    &__close-popup {
        position: absolute;
        right: -1rem;
        top: 0;
    }
    .compareBtn-wrapper {
        justify-self: flex-end;
        align-self: center;
        flex: 1 1 0;
        padding: 0 1rem;
        position: relative;
        .error {
            position: absolute;
            bottom: -1rem;
            color: #db3236;
            font-size: .7rem;
            white-space: nowrap;
        }
        .compareBtn {
            & > button {
                border: 1px solid #000000 !important;
                border-radius: 0 !important;
                & > div {
                    display: flex;
                    align-items: center;
                    align-content: center;
                    & > div {
                        display: flex;
                        align-items: center;
                        align-content: center;

                        & > span {
                            color: #000000 !important;
                        }
                    }

                }
            }
            &.disabled {
                & > button {
                    cursor: not-allowed;
                    border: 1px solid #c4c4c4 !important;
                }
            }
        }
    }
}
