@import '../vendor';

.review_stars{
    clear: both;
    color: #FED134;
    font-size: 1.5rem;
    @include bp(768px down){
        margin-bottom: 2.2rem;
    }
    span.overall {
        color: #000;
        font-size: 12px;
        font-weight: 100;
    }

    .reviews-qnt{
        font-size: 0.875rem;
        font-weight: 300;
        color: #000000;
        margin-left: 10px;
    }
    .average-rating{
        font-weight: 300;
        color: #000000;
        font-size: 1rem;
        // @include tabletFontSize(1rem);
        // @include phoneFontSize(.6rem);
    }
    .fa-fw {
        // @include bp(768px down){
        //     width: 2.284rem;
        //     -webkit-transform: scale(2);
        //     -moz-transform: scale(2);
        //     -ms-transform: scale(2);
        //     -o-transform: scale(2);
        //     transform: scale(2);
        // }
        // @include bp(520px down){
        //     width: 1.284rem;
        //     -webkit-transform: scale(1);
        //     -moz-transform: scale(1);
        //     -o-transform: scale(1);
        //     transform: scale(1);
        // }
    }
}
