@import "styles/vendor";

.FilterInput {
    margin: rem(20) 0 rem(20) rem(20);
    position: relative;
    .fa {
        position: absolute;
        left: 16px;
        top: 8px;
        color: #000000;
    }
    input {
        border: 1px solid rgb(196, 196, 196);
        background-color: rgb(255, 255, 255);
        width: 100%;
        height: sr(48, 1.5);
        padding-left: sr(65, 1.5);
        font-family: Montserrat, serif;
        font-size: sr(24, 1.5);
        transition: all .3s;
        box-shadow: 0 6px 8px 0 rgba(26, 24, 25, 0.07);
        &:focus {
            box-shadow: 0 rem(8) rem(10) 0 rgba(26, 24, 25, 0.1);
        }
    }
}
