@import '../../node_modules/bootstrap/dist/css/bootstrap';
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700');
@import './vendor';
@import './login';
@import './register';

html {
	overflow-x: hidden;
	@include bp(md up) {
		font-size: 112% !important;
	}
	@include bp(md down) {
		font-size: 112% !important;
	}
	@include bp(sm down) {
		font-size: 100% !important;
	}
	body {
		div::-webkit-scrollbar {
			width: 5px;
			height: 0;
		}
		div::-webkit-scrollbar-thumb {
			background-color: #a9a8a8;
		}
		input[type='number'] {
			-moz-appearance: textfield;
		}
		font-family: Montserrat, serif !important;

		* {
			&:focus {
				outline: none !important; //???
			}
		}
	}
	b {
		font-weight: 700;
	}

	strong {
		font-weight: 500;
	}

	button {
		cursor: pointer;
	}

	a:hover {
		text-decoration: none;
	}
}

.upload-btn-wrapper {
	@extend %upload-file-btn;
	label {
		text-align: center;
		width: 100%;
        border-radius: 20px;
        padding: 10px 20px;
        cursor: pointer;
        border-color: $yellow;
        background-color: $yellow;
        color: white;
	}
}

.upload-hint {
	&.crop {
		width: 50%;
		margin: auto;
	}
	p {
		@extend %text-hint;
		text-align: center;
	}
}

.flex-stretch {
	flex-grow: 1;
}

.video-container {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.embed-responsive-item {
	width: 100% !important;
	height: 100% !important;
}

.video-stream.html5-main-video {
	width: 100% !important;
	height: 100% !important;
}

.hidden {
	opacity: 0 !important;
	visibility: hidden !important;
}

input {
	&:focus {
		outline: none;
	}
}

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
	/* Non-prefixed version, currently
									 supported by Chrome and Opera */
}

.ant-notification-notice-with-icon {
	i {
		svg {
			display: none;
		}
	}
}

.ant-notification-notice-close {
	i {
		svg {
			display: none;
		}
	}
}

.modal-content {
	border: none;
	border-radius: 16px;
}

.errors-popup {
	@include alert-modal;
  }

.spinner {
	@include spinner;
}

.modal-lg {
	@include bp(lg up) {
		max-width: 1100px;
	}
}

.embed-responsive-4by3::before {
    border: solid 1px #e1e1e1;
    padding-top: 75%;
}

img.mfp-img {
	max-height: unset !important;
	min-width: 30vw;

	@include bp(992px down) {
		min-width: 50vw;
	}
	@include bp(520px down) {
		min-width: 95vw;
	}
}
