.password-change {
  h1 {
    font-size: 1rem;
  }

  .btn-container {
    margin-top: 15px;
    display: flex;
    flex-direction: row-reverse;
  }
}
