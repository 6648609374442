@import "styles/vendor";

.KYC_container {
    margin: 4.625rem 6.1875rem;
    color: black;
    font-size: 0.875rem;

    .title {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
    }

    .indicator {
        font-size: 0.7rem;
        color: white;
        background-color: black;
        text-transform: uppercase;
        padding: 4px 12px;
        border-radius: 20px;
        margin-left: 0.4rem;

        &.small {
            font-size: 0.6rem;
            text-overflow: ellipsis;
        }

        &.red {
            background-color: red;
        }
        &.grey {
            background-color: #bbb;
        }
        &.green {
            background-color: green;
        }

        .info-popup-window:after {
            white-space: pre-wrap;
        }
    }

    .section {
        padding: 1rem 0;

        .section_header {
            font-weight: 500;
        }
    }

    .person_type_title {
        display: flex;
        @include bp(520px down) {
            flex-direction: column;
        }

        .big_header {
            font-size: 1.2rem;
            font-weight: 600;
            margin-bottom: 9px;
            margin-top: -6px;

            @include bp(520px up) {
                width: 170px;
                flex-grow: 0;
                flex-shrink: 0;
            }
        }
    }


    .divide {
        display: flex;
        flex-wrap: wrap;

        .button_column {
            width: 120px;
            flex-grow: 0;
            flex-shrink: 0;
            // margin-top: 17px;

            &.entity {
                width: 200px;
                flex-grow: 0;
                flex-shrink: 0;
                margin-top: 0;
                margin-bottom: 1rem;

                @include bp(992px down) {
                    width: 140px;
                }

            }

            &.skip_kyc {
                width: 250px;
                flex-grow: 0;
                flex-shrink: 0;
                margin-top: 0;
                margin-bottom: 1rem;

                @include bp(992px down) {
                    //width: 140px;
                }

                &.disable {
                    cursor: not-allowed;
                }

            }
        }

        .content_column {
            width: calc(100% - 120px);
            flex-grow: 0;
            flex-shrink: 0;

            &.entity {
                width: calc(100% - 200px);
                flex-grow: 0;
                flex-shrink: 0;
                margin-top: 10px;
                margin-bottom: 1rem;
                padding-left: 2rem;

                @include bp(992px down) {
                    width: calc(100% - 140px);
                    padding-left: 30px;
                }

            }

            .mobile-full-width {
                width: 100%;

                @include bp(520px down) {
                    width: calc(100% + 120px);
                    margin-left: -120px;
                }
            }
        }

        .content_column_skip_kyc {
            width: calc(100% - 250px);
            flex-grow: 0;
            flex-shrink: 0;

            &.entity {
                width: calc(100% - 200px);
                flex-grow: 0;
                flex-shrink: 0;
                margin-top: 10px;
                margin-bottom: 1rem;
                padding-left: 2rem;

                @include bp(992px down) {
                    width: calc(100% - 270px);
                    padding-left: 30px;
                }

            }

            .mobile-full-width {
                width: 100%;

                @include bp(520px down) {
                    width: calc(100% + 250px);
                    margin-left: -250px;
                }
            }
        }
    }

    .switch_button {
        border: 1px solid black;
        border-radius: 20px;
        padding: 10px 20px;
        text-align: center;
        cursor: pointer;

        &.selected {
            border-color: $yellow;
            background-color: $yellow;
            color: white;
        }

        &.selected {
            border-color: $yellow;
            background-color: $yellow;
            color: white;
        }

        &.disable {
            cursor: not-allowed;
            color: #ffffff;
            border-color: #a3a3a3;
            background-color: #a3a3a3;
        }
    }

    .link {
        color: #0056b3;
        text-decoration: underline;
    }

    .horizontal_line {
        width: 100%;
        height: 2px;
        background-color: #e1e1e1;
        margin-bottom: 0.7rem;

        &.extension_left {
            width: calc(100% + 2rem);
            margin: 2rem auto 2rem -2rem;

            @include bp(520px down) {
                width: 100%;
                margin: 2rem auto;
            }
        }
    }


    .form-wrapper {
        padding-top: 1rem;

        .form-section {
            padding: 1rem 0;

            .section-header {
                font-weight: 500;
            }

            .birth-date>div {
                flex-direction: row-reverse;
            }

            .doc-input {
                padding-top: 2rem;
            }

            .select-nationality.filled div{
                color: black !important;
            }
            input {
                color: black !important;
            }
        }
    }

    .input-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        padding-top: 1.2rem;

        .files {
            width: calc(100% - 310px);
            max-width: 520px;
            display: flex;

            @include bp(1300px down) {
                width: 100%;
                max-width: unset;
                padding-bottom: 0.5rem;
            }

            .files-wrapper {
                display: flex;
                flex-wrap: wrap;

                .indicator {
                    margin-bottom: 2px;
                    height: 24px;
                }
            }
        }
        .status {
            width: 160px;
        }
        .upload {
            width: 150px;
            text-align: center;

            .info {
                font-size: 0.8rem;
            }
        }
    }

    .input-file-button {
        background-color: $yellow;
        color: black;
        border-radius: 20px;
        padding: 6px 20px;
        margin-top: -4px;

        .upload-icon>img {
            height: 0.8rem;
            margin-top: -0.125rem;
        }
    }

    .footer-notice {
        font-size: 0.65rem;
        padding-top: 1rem;
    }

    .skip-kyc-notice {
        font-size: 0.65rem;
        color: rgb(163, 163, 163);
    }

    .form-wrapper-kyc {
        padding-top: 0.5rem;
    }


    @include bp(1200px down) {
        margin: 7.5%;
    }
}
