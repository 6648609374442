@import "styles/vendor";

.cancellation-policy-link {
    padding: 1rem;
    border-bottom: 1px solid #e1e1e1;
    justify-content: space-between;
    font-size: .875rem;
    font-weight: bold;
    // @include tabletFontSize(1.7rem);
    // @include phoneFontSize(.9rem !important);

    &__type {
        color: $yellow;
        cursor: pointer;
    }
    // &__title {
    //     font-size: 0.875rem;
    // }
    .cancellation-policy-popup {
        top: 0;
        left: 0;
        position: fixed;
        display: flex;
        align-items: center;
        width: 100vw;
        height: 100vh;
        z-index: 10;
        background: rgba(0, 0, 0, .8);
        overflow: auto;
        flex-direction: column;
        @include bp(768px up) {
            padding: 5rem 0;
        }
        &__window {
            border-top: 3px solid $yellow;
            z-index: 9999;
            width: 90vw;
            background-color: #fff;
            position: relative;
            padding: 1.5rem 1.5rem 0;
            overflow: auto;
            @include bp(768px up) {
                width: 80vw;
            }
            @include bp(768px down){
                width: 100%;
                margin-top: 7.4rem;
                padding: 0px;
            }
            @include bp(520px down){
                margin-top: 4.4rem;
            }
        }
    }
}