@import '../../../styles/vendor';

.loader-container {
    text-align: center;
    width: 100%;
  & > div {
      margin: 30px 0;
      @include bp(768px down){
          margin: 25% 0;
      }
  }
}
