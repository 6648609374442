@import "../utils";

.share-bar {
    padding: rem(15);
    .buttons-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .share-button {
            margin: 0 rem(10);
        }
    }
}

.publish {
    &-input {
        border: 0;
        background: none;
        outline: none;
        border-bottom: 1px solid $gray;
        &[placeholder] {
            text-align: center;
        }
    }
}
    .share {
        &-last {
            &__button {
                font-size: 0.85rem;
                font-weight: 600;
                border-radius: rem(14);
                margin-left: rem(40);
                padding: 0 rem(20);
                &-text {
                    margin-right: rem(10);
                }
                .SVGInline-svg {
                    position: relative;
                    top: rem(4);
                }
                &:focus {
                    outline: none;
                }
            }
        }
    }