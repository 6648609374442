@import "styles/vendor";

.HomeEvent {
    @include bp(768px down){
        &:nth-child(2n + 1){
            .hover-image {
                &:before {
                    display: none;
                }
            }
            .line-before-left {

                &:before {
                    display: none;
                }
            }
        }
    }
    @include bp(992px down) {
        &:nth-child(2n + 1){
            .hover-image {
                &:before {
                    width: 100%;
                    height: 100%;
                    border: 1px solid $blue;
                    position: absolute;
                    left: calc(-100% - 30px);
                    top: -1px;
                    content: '';
                    cursor: default;
                }
            }
            .line-before-left {
                position: relative;
                &:before {
                    width: 100%;
                    height: 100%;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    position: absolute;
                    left: calc(-100% - 30px);
                    top: -1px;
                    content: '';
                }
            }
        }
    }
    @include bp(md up) {
        &:nth-child(3n + 1){
            .hover-image {
                &:before {
                    width: 100%;
                    height: 100%;
                    border: 1px solid $blue;
                    position: absolute;
                    left: calc(-100% - 30px);
                    top: -1px;
                    content: '';
                    cursor: default;
                }
            }
            .line-before-left {
                position: relative;
                &:before {
                    width: 100%;
                    height: 100%;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    position: absolute;
                    left: calc(-100% - 30px);
                    top: -1px;
                    content: '';
                }
            }
        }
    }
    > div {
        .image-size {

            padding-top: 100%;
            position: relative;
            @include bp(768px down){
                margin-bottom: .6rem;
            }
            .hover-image {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 1px solid $blue;
                box-sizing: content-box;
                &:hover {
                    background-color: rgba(0,0,0,0.3);
                    cursor: pointer;
                    .hover-image__background {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background-color: rgba(0,0,0,0.3);
                        z-index: 1;
                    }
                }
                img.d-block {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        h5 {
            font-weight: 600;
            font-size: 0.8rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            @include bp(768px down){
                margin-bottom: 1rem;
            }
            @include tabletFontSize(1.6rem);
            @include phoneFontSize(1.3rem);
        }

        > img {
            max-width: 100%;

        }

        p {
            font-size: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @include tabletFontSize(1rem);
        }

        a img {
            width: 15px;
            height: 15px;
            @include bp(768px down){
                width: 30px;
                height: 30px;
            }
        }
    }
}

.HomeEventMain {
    max-width: 500px;



    @include bp(992px down) {
        .HomeEvent {
            max-width: 155px;
        }
    }
    @include bp(768px down) {
        justify-content: center;
        max-width: 100%;
        transform: translateX(4%);
        /*  margin-left: 50%;*/
        /*   transform: translateX(-50%);*/
        width: 100%;
        margin-top: 5.7rem !important;
        .HomeEvent {
            max-width: 50%;
            padding-right: 2rem;
            margin-bottom: 3rem !important;
            .line-before-leftline {
                border-top: 1px solid #000;
            }
        }
    }
    @include bp(520px down) {

        .HomeEvent {
            min-width: 100%;
            padding-right: 1rem;
        }
    }
/*    @include bp(520px down){
        .HomeEvent {
            max-width: 100%;
        }
    }*/
    @media (min-width: 830px) and (max-width: 992px) {
        margin-left: -60px;
    }
    .HomeEvent {
        .line-before-left {
            margin-top: 0.5rem !important;
        }
    }
}
