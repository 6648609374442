@import '../vendor';

.reviews-ratings{
    // @include bp(768px donw){
    //     margin-bottom: 2.5rem;
    // }
    .review-item{
        align-items: baseline;
        margin: 0.4375rem 0;
        display: flex;
        flex-direction: row;
        font-size: 0.875rem;
        justify-content: space-between;
        font-weight: 300;
        cursor: pointer;
        .review-bar{
            position: relative;
            background-color: #feebac;
            width: 60%;
            height: 0.5937rem;
            div{
                background-color: #fcd75f;
                height: 0.5937rem;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        span:first-child{
            width: 22%;
            // @include tabletFontSize(1.8rem);
            // @include phoneFontSize(1rem);

        }
        span:last-child{
            width: 12%;
            text-align: end;
            // @include tabletFontSize(1.8rem);
            // @include phoneFontSize(1rem);
        }
    }
    .review-item.selected{
        font-weight: 600;
        // @include tabletFontSize(1.8rem);
        // @include phoneFontSize(1rem);

    }
}
