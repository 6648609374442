.contract_preview {
	height: 100vh;
	// color: gray;
	/* margin-top: 100px; */
	padding-top: 75px;
	background-color: #000;
	overflow: auto;
	padding-bottom: 75px;

	.contract_main {
		position: relative;
		background-color: #fff;

		display: grid;
		grid-template-columns: 1fr 1fr;
		min-width: 1150px;
  		max-width: 1150px;
		margin: auto;
		margin-top: 16px;

		.contract_logo {
			padding-top: 16px;
			padding-left: 42px;

			img {
				height: 42px;
			}

			.site {
				font-size: 0.6rem;
				font-weight: 700;
			}
		}
		.contract_number {
			padding-top: 16px;
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			// justify-content: flex-end;
			align-items: flex-end;
			padding-right: 42px;

			.contract_number_title {
				font-size: 1.3rem;
				font-weight: 500;
			}
			.contract_number_description {
				font-size: 0.6rem;
				font-weight: 700;
			}
		}

		.contract_parties {
			padding-left: 42px;
			font-size: 1.4rem;
			font-weight: 500;
		}

		.main_form {
			grid-column: 1 / span 2;
			padding-left: 42px;
			padding-right: 42px;

			display: grid;
			grid-template-columns: 1fr 1fr;

			.client_info {
				padding-right: 42px;

				// .textFieldRenderedMaterial {
				//   label: {
				//     top: 24px !important;
				//   }
				//   input {
				//     margin-top: 0 !important;
				//   }
				//   div > hr {
				//     bottom: 24px !important;
				//   }
				// }
			}
			.vendor_info {
				padding-left: 42px;
			}

			h4 {
				grid-column: 1 / span 2;
			}

			.contract_data {
				grid-column: 1 / span 2;

				display: grid;
				grid-template-columns: 1fr 1fr;
				font-size: 0.7rem;
				font-weight: 500;

				.dates_row {
					display: grid;
					grid-template-columns: 2fr 1fr;
					margin-bottom: 8px;

					.value {
						justify-self: right;
						padding-right: 42px;
					}
				}
				.names_row {
					display: grid;
					grid-template-columns: 2fr 1fr;
					margin-bottom: 8px;

					.title {
						padding-left: 42px;
					}
					.value {
						justify-self: right;
					}
				}
			}

			.gig_description_header {
                grid-column: 1 / span 2;
                font-size: 1.1rem;
			}

			.gig_description {
                word-break: break-all;
				grid-column: 1 / span 2;
				font-size: 0.7rem;
			}

			.addon {
				grid-column: 1 / span 2;
				display: grid;
				grid-template-columns: 1fr 1fr;
				margin-bottom: 8px;

				.addon_title {
					font-size: 1.1rem;
					font-weight: 500;
				}
				.addon_price {
					font-size: 1.1rem;
					justify-self: end;
				}

				.addon_subtotal_title {
					font-size: 1.1rem;
					font-weight: 500;
				}
				.addon_subtotal_price {
					font-size: 1.1rem;
					justify-self: end;
				}
			}

			.addon_subtotal {
				grid-column: 1 / span 2;
				display: grid;
				grid-template-columns: 1fr 1fr;
				margin-top: 8px;
				margin-bottom: 8px;

				.addon_subtotal_title {
					font-size: 1.1rem;
					font-weight: 500;
				}
				.addon_subtotal_price {
					font-size: 1.1rem;
					justify-self: end;
				}
			}

			.hr {
				grid-column: 1 / span 2;

				hr {
					margin-top: 8px;
					margin-bottom: 0px;
				}
			}
		}

		.addTermBtnLi {
			grid-column: 1 / span 2;
			width: 100%;

			.addTermBtn {
				width: 100%;
				-moz-user-select: none;
				-ms-user-select: none;
				background-color: #fff;
				border-radius: 5px;
				-moz-border-radius: 5px;
				// color: #000;
				color: rgb(11, 108, 153);
				font-size: 14px;
				text-decoration: none;
				// border: 1px solid #aaa;
				border: 1px solid rgb(11, 108, 153);
				width: 100%;
				text-align: center;
				padding-bottom: 15px;
				padding-top: 15px;
				margin-right: 10px;
				cursor: pointer;
			}
		}
		.add_term {
			-moz-user-select: none;
			-ms-user-select: none;
			background-color: #ffd210;
			-moz-border-radius: 5px;
			border-radius: 5px;
			color: #fff;
			font-size: 14px;
			text-decoration: none;
			// border: 1px solid #aaa;
			width: 8rem;
			text-align: center;
			padding-bottom: 10px;
			padding-top: 10px;
			// margin-right: 10px;
			cursor: pointer;
			justify-self: right;
			margin-right: 16px;
			margin-top: 1rem;
			margin-bottom: 1rem;
		}
		.cancel_term {
			-moz-user-select: none;
			-ms-user-select: none;
			background-color: #fff;
			-moz-border-radius: 5px;
			border-radius: 5px;
			color: rgb(11, 108, 153);
			font-size: 14px;
			text-decoration: none;
			border: 1px solid rgb(11, 108, 153);
			width: 8rem;
			text-align: center;
			padding-bottom: 10px;
			padding-top: 10px;
			// margin-right: 10px;
			cursor: pointer;
			justify-self: left;
			margin-left: 16px;
			margin-top: 1rem;
			margin-bottom: 1rem;
		}
		.guest_list {
			grid-column: 1 / span 2;
			font-size: 0.7rem;
			font-weight: 600;

			.input_guest_list {
				border: none;
				border-bottom: 1px solid #929292;
				margin-left: 5px;
				width: 1.5rem;
				font-weight: 600;
			}

			span {
				color: grey;
				font-weight: 100;
			}
		}
		.cancelation_policy {
			grid-column: 1 / span 2;
			font-size: 0.7rem;
			font-weight: 400;

			a {
				text-decoration: underline;
				color: rgb(11, 108, 153);
			}

			span {
				color: grey;
				font-weight: 100;
			}
		}

		// .additional_terms_list {
		//   grid-column: 1 / span 2;
		//   list-style-type: none;
		//   padding-left: 0;

		//   display: grid;
		//   grid-template-columns: 1fr 1fr;

		//   .addTermBtnLi {
		//     grid-column: 1 / span 2;
		//     width: 100%;

		//     button {
		//       width: 100%;
		//       -moz-user-select: none;
		//       -ms-user-select: none;
		//       background-color: #fff;
		//       -moz-border-radius: 5px;
		//       color: #000;
		//       font-size: 14px;
		//       text-decoration: none;
		//       border: 1px solid #aaa;
		//       width: 100%;
		//       text-align: center;
		//       padding-bottom: 15px;
		//       padding-top: 15px;
		//       margin-right: 10px;
		//     }
		//   }
		// }

		.additional_term {
			grid-column: 1 / span 2;

			display: grid;
			grid-template-columns: 1fr 1fr;

			.additional_term_title {
				padding-right: 42px;
			}
			.additional_term_description {
                word-break: break-all;
				padding-left: 42px;
			}
		}

		.terms_agreement {
			display: grid;
			grid-template-columns: 45px 1fr;
			padding-right: 42px;

			.list_of_terms_agreement {
				font-size: 0.7rem;

				a {
					text-decoration: underline;
					color: rgb(11, 108, 153);
				}
			}
		}
		.contract_agreement {
			display: grid;
			grid-template-columns: 45px 1fr;
			padding-left: 42px;

			.list_of_contract_agreement {
				font-size: 0.7rem;

				a {
					text-decoration: underline;
					color: rgb(11, 108, 153);
				}
			}
		}

		.send_contract {
			justify-self: center;
			grid-column: 1 / span 2;

			.sendContractBtn {
				-moz-user-select: none;
				-ms-user-select: none;
				background-color: #fff;
				-moz-border-radius: 5px;
				border-radius: 5px;
				color: rgb(11, 108, 153);
				font-size: 14px;
				text-decoration: none;
				border: 1px solid rgb(11, 108, 153);
				width: 8rem;
				text-align: center;
				padding-bottom: 10px;
				padding-top: 10px;
				// margin-right: 10px;
				cursor: pointer;
				justify-self: left;
				margin-left: 16px;
				margin-top: 1rem;
				margin-bottom: 1rem;
			}
			.sendContractBtn:disabled {
				cursor: not-allowed;
				color: grey;
				border: 1px solid grey;
			}
		}

		.signature_row {
			grid-column: 1 / span 2;
			font-size: 0.7rem;
			font-weight: 500;

			display: grid;
			grid-template-columns: 1fr 1fr;

			.client_signature {

			}

			.vendor_signature {
				padding-left: 42px;
			}
		}

		.terms_row {
			grid-column: 1 / span 2;
            word-break: break-all;
			margin-top: 32px;
			// margin-bottom: 32px;

			.term_title {
                font-size: 1.1rem;
				margin-bottom: 24px;
			}
			.term_description {
				font-size: 0.7rem;
				font-weight: 100;
				margin-bottom: 24px;
			}
		}

		.blank_row_48 {
			grid-column: 1 / span 2;
			height: 48px;
		}
		.blank_row_16 {
			grid-column: 1 / span 2;
			height: 16px;
		}

		.blank_row_24 {
			grid-column: 1 / span 2;
			height: 24px;
		}

		.blank_row_32 {
			grid-column: 1 / span 2;
			height: 32px;
        }

        .close-button {
            position: absolute;
            top: 2px;
            right: 10px;
            font-size: 1.5rem;
        }
	}
}

// #root-wrapper {
//   display: none;
// }
