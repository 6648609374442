@import "styles/vendor";
$fs-title: 16;
$fs-main: 13;
$fs-small: 12;

@mixin font-size-gig-card($font-size) {
    @include bp(768px down) {
        font-size: rem($font-size);
    }
    @include bp(768px up) {
        font-size: rem($font-size - 2);
    }
    // @include bp(1200px up) {
    //     font-size: rem($font-size);
    // }
}

.GigCard {
    display: block;
    padding-top: 16px;
    width: 293px;
    height: 378px;
    flex-shrink: 0;

    // @include bp(768px up) {
    //     width: 293px;
    //     height: 378px;
    //     flex-shrink: 0;
    // }

    // @include bp(768px down) {
    //     width: 293px;
    // }
    position: relative;
    border: 1px solid $gray;
    margin: 2rem 0 2rem 2rem;
    color: black;
    border-radius: 0!important;
    // @include bp(768px down) {
    //     width: 100% !important;
    //     box-shadow: none !important;
    //     padding-top: 2.4rem;
    //     padding-left: 1.2rem;
    //     padding-right: 1.2rem;
    //     padding-bottom: 2.4rem;
    // }

    &.GigCard--no-margins {
        margin: 0;
    }

    &__paper-header {
        display: flex;
        flex-direction: column;
        &_head {
            padding: 0 1rem;
            display: flex;
            flex-direction: row;
            position: relative;
            height: auto;
            .signature {
                position: absolute;
            }
            .artist {
                width: 10px;
                height: 10px;
                transform: rotate(45deg);
                background: $red;
                top: -5px;
                left: calc(1rem - 5px);
            }
            .venue {
                display: flex;
                height: 10px;
                top: -5px;
                left: 0;
                .rectangle {
                    background: $blue;
                    &:first-child {
                        width: 1rem;
                        margin-right: rem(4);
                    }
                    &:nth-child(2) {
                        width: rem(4);
                    }
                }
            }
            .brand-logo {
                width: rem(50);
                height: rem(50);
            }
            .name-head {
                width: calc(100% - 85px);
                height: rem(50);
                padding: 0 rem(5) 0 rem(2);
                margin-left: rem(10);
                display: inline;

                .info-popup-window:after {
                    left: 5px;
                    top: 8px;
                    bottom: unset;
                    z-index: 99;
                    max-width: 210px;
                }
            }
            .GigCard-title {
                font-weight: $font-semi-bold;
                color: #000;
                margin-bottom: 0;
                @include font-size-gig-card($fs-title);
                //@include phoneFontSize(1rem);
                line-height: 1.1;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
            .GigCard-category-name {
                margin-bottom: 0;
                color: #000;
                @include font-size-gig-card($fs-main);
                // @include phoneFontSize(.9rem);
                font-weight: 500;
            }
            .shared-button {
                position: absolute;
                top: -3px;
                right: 8px;
                z-index: 1;
                cursor: pointer;
                .shared-button-svg {
                    width: 29px !important;
                    height: 29px !important;
                    // @include bp(520px down){
                    //     width: 20px !important;
                    //     height: 20px !important;
                    // }
                }
            }
        }
        &_location {
            display: flex;
            @include font-size-gig-card($fs-main);
            // @include phoneFontSize(.9rem);
            z-index: 1;
            background-color: #fff;
            border-bottom: 1px solid $gray;
            padding: 16px 16px 6px 16px;

            .item-location {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: #000;
                city {
                    font-weight: $font-semi-bold;
                }
            }
        }

    }
    &__paper-content {
        z-index: 1;
        background: white;

        &_content-wrapper {
            padding: 5px 16px;
            border-bottom: 1px solid $gray;
            display: flex;
            flex-direction: column;
            line-height: 1.7;
            color: #000;
            
            @include font-size-gig-card($fs-main);
            // @include phoneFontSize(.7rem);
            p {
                margin-bottom: 0;
            }
            .item-date {

            }
            .item-hours {
                margin-top: -5px;
            }
            .range-time {
                color: #000;
            }
        }
        &_budget {
            padding: 5px 23px 5px 16px;
            // @include bp(768px down) {
            //     padding: 1rem 1rem;
            // }
            border-bottom: 1px solid $gray;
            justify-content: space-between;
            
            .flexibility {
                @include font-size-gig-card($fs-small);
                font-weight: $font-semi-bold;
                // @include phoneFontSize(.7rem);

            }
            .currency {
                color: #000000;
                @include font-size-gig-card($fs-small);
                font-weight: $font-semi-bold;
                // @include phoneFontSize(.7rem);
            }
        }
        &_deadline {
            padding: 5px 23px 5px 16px;
            border-bottom: 1px solid $gray;
            justify-content: space-between;

            // @include bp(768px down) {
            //     padding: 1rem 1rem;
            // }
            .application {
                @include font-size-gig-card($fs-small);
                // @include phoneFontSize(.7rem);
                font-weight: $font-semi-bold;
            }
            .date {
                color: #000000;
                @include font-size-gig-card($fs-small);
                // @include phoneFontSize(.7rem);
                font-weight: $font-semi-bold;
            }
        }
        &_more-info {
            padding: 5px 16px 7px;
            display: flex;
            justify-content: center;
            border-bottom: 1px solid $gray;
            // @include bp(768px down) {
            //     padding: 1rem 1rem;
            // }
            .view-info-button {
                color: $yellow;
                @include font-size-gig-card($fs-small);
                // @include bp(768px down) {
                //     border-bottom: none;
                // }
                // @include phoneFontSize(.7rem);
                border-bottom: 1px solid $yellow;
                cursor: pointer;
            }
        }
        &_author {
            padding: 3px 10px 3px 9px;
            border-bottom: 1px solid $gray;
            justify-content: flex-start;
            align-items: center;
            position: relative;
            width: 100%;

            //@include bp(768px down) {
            //    border-bottom: none;
            //    padding: 3.4rem 3.5rem;
            //}
            //@include bp(520px down){
            //    padding: 3.4rem 1.5rem;
            //}
            .UserPic {
                img {
                    width: 1.5rem;
                    height: 1.5rem;
                    //@include bp(768px down) {
                    //    width: 4rem;
                    //    height: 4rem;
                    //}
                }
                &.small {
                    // @include bp(768px down) {
                    //     min-width: 4rem;
                    //     width: 4rem;
                    //     height: 4rem;
                    //     margin-right: .75rem;
                    //     img {
                    //         width: 64px;
                    //         height: 64px;
                    //     }
                    // }
                }
            }
            .gig-user {
                flex: 1;
                align-items: flex-start;
                justify-content: center;
                .name {
                    @include font-size-gig-card($fs-main);
                    // @include phoneFontSize(1rem);
                    //font-weight: $font-semi-bold;
                    //line-height: 0.9;
                    color: #000;
                }
                .events-manager {
                    @include font-size-gig-card($fs-small);
                    // @include phoneFontSize(1rem);
                    font-weight: $font-regular;
                    color: #000;
                }
            }
            .rating-stars {
                color: #fed135;
                // align-self: flex-start;
                margin-top: -6px;
                width: 3.7rem;
                .fa {
                    width: 0.7rem;
                    font-size: 0.6rem;
                }
                // @include bp(768px down) {
                //     width: 1.5rem;
                //     font-size: 1.2rem;
                // }
                & > span {
                    white-space: nowrap;
                }
            }
        }
        &_people-applied {
            padding: 5px 16px;
            font-weight: $font-light;
            height: 26px;
            @include font-size-gig-card($fs-small);
            // @include bp(768px down) {
            //     justify-content: center;
            //     text-align: center;
            // }
            //@include phoneFontSize(.6rem);
        }
    }
    &__buttons-toolbar {
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        // @include bp(768px down) {
        //     button  {
        //         border: none !important;
        //         span {
        //             font-size: 1.8rem !important;
        //             text-transform: capitalize !important;
        //             @include phoneFontSize(.8rem !important);
        //         }
        //     }
        //     & > div {
        //         padding: 1.5rem;
        //         border: 1px solid #e6e6e6;
        //         margin: 1rem 1rem;
        //         -webkit-box-shadow: none !important;
        //         -moz-box-shadow: none !important;
        //         box-shadow: none !important;
        //     }
        // }
        // @include bp(520px down){
        //     & > div {
        //         padding: .5rem;
        //     }
        // }


        &_save-button {
            flex: 1;
            width: 50%;
        }
        &_apply-button {
            flex: 1;
            width: 50%;
        }
    }
}
