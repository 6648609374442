@import "styles/vendor";

.ArtistCategoryBlock.category-block {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border-bottom: 1px solid #d3d3d3;
    margin: 0 auto;
    @include bp(768px down){
        border-bottom: 0 !important;
    }
    .SortBy {
        display: none;
    }
    .category-description {
        display: flex;
        margin-right: 0;
        width: 100%;
        flex-direction: row;
        justify-content: space-around;
        @include bp(768px up){
            padding-right: rem(15);
            width: 18%;
            flex-direction: column;
            justify-content: center;
            min-width: rem(200);
        }
        h1 {
            font-weight: bold;
            font-size: 1.9rem;
        }
        .credits {
            @include bp(768px up) {
                display: none;
            }
            color: white;
            font-size: .6rem;
            padding: 5px;
        }
        .description {
            color: #000;
            font-size: .8rem;
            padding-bottom: rem(10);
        }
        .view-all-button {
            position: relative;
            box-shadow: none !important;
            width: 100%;
            max-width: 100% !important;
        }
        button {
            font-size: .9rem !important;
            font-weight: bold !important;
            border-radius: 0 !important;
            box-shadow: none !important;
            @include bp(ss up){
                border: none;
            }
            @include bp(sm up){
                border: 1px solid #cecece !important;
            }
            &>div {
                @include bp(768px down){
                    border-top: 1px solid #fff;
                }
            }
        }
    }
    .category-artists {
        display: flex;
        flex-direction: row;
        @include bp(ss up){
            width: 100%;
            overflow-x: scroll;
        }
        @include bp(sm up) {
            width: 82%;
            overflow-x: hidden;
            min-height: 353px;
            display: block;
        }
        .ProfileCard {
            @include bp(768px down){
                margin-right: 1%;
                min-width: 49.5%;
                height: calc(50vw * 1.366);
            }
            @include bp(xs up) {
                @include adaptive-height-element(3);
                margin: 1% 1% 1% 0;
                @include bp(sm down) {
                    &:nth-child(4) {
                        display: none;
                    }
                }
            }
            @include bp(992px up) {
                @include adaptive-height-element(4);
            }
        }
    }
}
