@import "styles/vendor";

.SelectCardForm {
    display: flex;
    flex-direction: column;
    //margin-left: 2rem;
    @include bp(520px down) {
        margin-left: 0;
    }

    .SelectCardForm__Item{
        text-align: left;
        margin-bottom: 8px;
        border-radius: 5px;
    }
}
