@import '../../vendor';

$grey: #a3a3a3;

.booking-info-form {
    margin-top: rem(65);
    @include bp(768px down) {
        margin-top: 0;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    &__title {
        font-size: rem(16);
    }
    &__wrapper {
        width: 80%;
        margin: rem(30) 0;
        @include bp(768px down) {
            margin: rem(16) 0;
        }
    }
    &__subtitle {
        @include subtitle;
        margin: rem(22.5) 0 rem(2);
    }
    &__price {
        margin-bottom: 1rem;
        &-wrapper {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            .first-line{
                width: 100%;
            }
            .selectForm {
                margin-top: 0;
                margin-bottom: rem(6);
            }
            @at-root .booking-info-form .price {
                &-count {
                    width: 25%;
                }
                &-name {
                    width: 45%;
                    margin-right: 10%;
                    flex-shrink: 0;
                    textarea {
                        width: rem(320);
                    }
                }
                &__control-wrappper {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-top: 0.5rem;
                }
                &__price-description {
                    width: calc(98.3333% - 1.3125rem);
                }
                &__more-button {
                    background-color: transparent;
                    flex-grow: 0;
                    border: none;
                    font-size: rem(14);
                    color: $grey;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .fa {
                        width: rem(21);
                        height: rem(21);
                        border: 2px solid $grey;
                        color: $grey;
                        border-radius: 50%;
                        margin-right: 0.5rem;
                        &::before {
                            position: relative;
                            top: rem(2);
                        }
                    }
                }
                &-currency {
                    width: 25%;
                }

                &-period {
                    width: calc(45% - 1.3125rem);
                }
            }
        }
        &-wrapper.price-list{
            flex-direction: column;
            align-items: flex-end;
        }
    }
    &__item {
        .multiple {
            .selectForm {
                .Select {
                    &-value, &-clear-zone {
                        display: none;
                    }
                }
            }
        }
        .selectForm {
            margin-top: rem(20);
            position: relative;
            &__wrapper {
                position: relative;
            }
            &__arrow {
                position: absolute;
                width: rem(20);
                height: rem(30);
                top: rem(4);
                right: 0;
                i {
                    font-size: rem(30);
                }
            }
            &:focus {
                outline: none;
                box-shadow: none;
            }
            .Select {
                &-control {
                    order: 1;
                    width: 100%;
                    border: none;
                    border-radius: 0;
                    border-bottom: 1px solid $gray;
                    box-shadow: none;
                    outline: none;
                    font-size: 1.5rem;
                    &:focus {
                        outline: none;
                        border-color: $yellow;
                    }
                }
                &-clear-zone {
                    display: none;
                }
                &-arrow {
                    display: none;
                }
            }

        }
        &-header {
            @include informative-title;
            margin-bottom: 10px;
        }
    }
    &__item-content {
        @at-root .talent-tags-controller {

            %control-row {
                flex-grow: 0;
                padding: 0 15px;
            }
            &__control {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                margin: 20px -15px 0;
                @include bp(768px down){
                    margin: 0 -15px 0 !important;
                }
            }
            &__control-desc {
                @extend %control-row;
                font-size: 12px;
                font-weight: 300;
                color: #a1a1a1;
            }
            &__control-form {
                width: 100%;
                position: relative;
                div:first-child {
                    @include bp(768 px down){
                        border: 1px solid #cacaca;
                        padding-left: 1rem;
                    }
                    div:first-child {
                        div:nth-child(3) {
                            hr {
                                @include bp(768px down);
                            }
                        }
                    }

                }
            }
            &__control-add {
                @extend %control-row;
                display: flex;
                align-items: center;
                @include bp(sm down) {
                    @include position(relative, 0 null 0 null);
                }
                .fa {
                    margin-left: 1rem;
                }
                button {
                    @extend %simple-btn--narrow;
                }
            }
            &__subtitle {
                @include subtitle;
                @include bp(768px down){
                    margin-top: 1rem;

                }
                &-big {
                    @include subtitle;
                    font-size: rem(14);
                    margin-top: rem(44 / 2);
                }
            }
            &__preview {
                display: flex;
                flex-wrap: wrap;
                margin-top: 20px;
                &>div {
                     @include bp(768px down){
                         height: 1.5rem !important;
                         border: 0 !important;
                     }
                    div {
                       @include bp(768px down){
                           width: 100% !important;
                           height: 1.5rem !important;
                           border: 0 !important;
                           span {
                               color: #000 !important;
                               height: 1.5rem;
                               background: #fff !important;
                               border-radius: 30px !important;
                               border: 1px solid #2e2e31 !important;
                               line-height: 23px !important;
                               text-transform: capitalize;
                               width: 100%;
                           }
                       }
                    }
                }
            }
        }
    }
    &__input-option {
        width: percentage(1 / 3);
        display: inline-block;
        label {
            display: flex;
            align-items: center;
        }
        .checkbox {
            @include size(rem(16));
            display: inline-block;
            margin-right: 0.5rem;
            background-color: $light-gray;
        }
        input {
            display: none;
        }
    }
    &__input-subtitle {
        margin-top: 1.5rem;
        @include subtitle;
    }
    &__custom-chips {
        @at-root .custom-chips {
            &__item {
                background-color: $light-gray;
                font-size: rem(14);
                border-radius: rem(20);
                padding: rem(2) rem(10);
                display: flex;
                justify-content: center;
                align-items: center;
                color: $grey;
                margin: 0.4rem;
            }

            &__wrapper {
                display: flex;
                flex-wrap: wrap;
            }

            &__top-chips {
                min-width: rem(80);
                min-height: rem(20);
                background-color: #fff;
                border: 1px solid $gray;
            }
        }
    }
    .radio-group-wrapper {
        margin-top: rem(14);
        @include radio-chips-button;
    }
    .radio-group-wrapper__small {
        margin-top: rem(14);
        @include radio-chips-button-small;
    }
    .input-file{
        &__button {
            width: rem(343 / 2);
            padding: rem(40 / 2) 0;
            border-radius: rem(32);
            font-size:0.85rem;
            font-weight: 500;
            color: #fff;
            background-color: $yellow;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                height: rem(14);
                position: relative;
                top: rem(3);
                margin-right: rem(4);
            }
            &:hover {
                cursor: pointer;
            }
        }
    }
    .number-input {
        textarea {
            resize: none;
            border: 0;
            border-bottom: 1px solid $gray;
            outline: none;
            max-height: rem(40) !important;
            font-size: 1.5rem;
            &:focus {
                border-color: $yellow;
            }
            &::placeholder {
                font-size: 1.5rem;
                font-weight: 300;
            }
        }
    }
    .capacity-label {
        width: 100px;
    }
    .capacity-input {
        width: 100px;
        margin-right: 10px;
    }
}
