@import "styles/vendor";

.gig-feed-primary {
    border-top: 1px solid $gray;
    .SortBar {
        border-top: 0;
        .SortBy {
            display: none;
        }
    }
    .ArtistCategory,.VenueCategory {
        display: flex;
        background-repeat: no-repeat;
        background-size: 100vw;
        @include bp(768px down) {
            margin-bottom: 0;
        }
        &:hover {
            .category-item {
                &__card {
                    .full-width {
                        background: rgba(255,255,255,.25);
                        transition: .22s;
                    }
                }
            }
        }
        @include bp(768px up) {
            border-bottom: 1px solid $gray;
        }
        .information {
            width: calc(100% / 5);
            display: flex;
            flex-direction: column;
            justify-content: center;
            @include bp(768px down) {
                width: 100%;
                padding: 24px 0.5rem 19px 0.5rem;
                & > h3 {
                    font-size: 1.7rem;
                }
                .description {
                    display: none;
                }
            }
            @include bp(520px down) {
                padding: 18px 0.25rem 10px 0.25rem;
                & > h3 {
                    font-size: 1.7rem;
                }
            }

            .description {
                font-size: rem(14);
            }
        }
        .category-item {
            padding: 0.875rem 0.5rem;
            @include bp(520px down) {
                padding: 0.5rem 0.25rem;
            }

            &__card {
                position: relative;
                cursor: pointer;
                box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3);
                .full-width,.photo {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                }
                .full-width {
                    z-index: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    &:hover {
                        background: transparent;
                    }
                    @include bp(768px down) {
                        flex-direction: column-reverse;
                        justify-content: space-between;
                    }
                    h5 {
                        background-color: rgba(255,255,255,0.7);
                        text-align: center;
                        @include bp(768px down) {
                            margin-bottom: 0;
                            padding: 0.8rem rem(3);
                            font-size: 0.9rem;
                            font-weight: bold;
                        }
                        @include bp(768px up) {
                            font-size: 0.85rem;
                            padding: rem(10) rem(3);
                        }
                        @include bp(992px up) {
                            font-size: 1rem;
                        }
                        @include bp(1200px up) {
                            font-size: 1.25rem;
                        }
                    }
                    p {
                        color: #fff;
                        padding: 0 rem(5);
                        text-align: right;
                        margin-bottom: rem(5);
                        font-size: .7rem;
                        @include bp(768px down) {
                            overflow: hidden;
                            padding: rem(4) rem(4);
                            font-size: 0.8rem;

                            white-space: nowrap;
                        }
                    }
                }
                .photo {
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }

        .container {
            @include bp(520px down) {
                padding: 0 3%;
            }

        }
    }
    .ArtistCategory {
        @include bp(768px up) {
            background-image: url(../../../../assets/images/gigfeed/artist-Background-Default.png);
            &:hover {
                background-image: url(../../../../assets/images/gigfeed/artist-Background-Diamonds-Over.png);
            }
        }
        .category-item {
            width: calc(100% / 5);
            @include bp(768px down) {
                width: 50%;
            }
            //&:nth-child(2) {
            //    order: 5;
            //}
            //&:nth-child(3) {
            //    order: 3;
            //}
            //&:nth-child(4) {
            //    order: 3;
            //}
            //&:nth-child(5) {
            //    order: 4;
            //}
            &__card {
                padding-top: 100% * 1.375;
                @include bp(768px down) {
                    padding-top: 100%;
                }
            }
        }

        .arrow-icon-button {
            transform: translateY(45%)
        }
    }
    .VenueCategory {
        @include bp(768px up) {
            background-image: url(../../../../assets/images/gigfeed/venue-Background-Default.png);
            &:hover {
                background-image: url(../../../../assets/images/gigfeed/venue-Background-Diamonds-Over.png);
            }
        }
        .category-item {
            width: calc(100% / 5 * 2);
            @include bp(768px down) {
                margin-bottom: 0;
                width: 100%;
            }
            //&:nth-child(1) {
            //    order: 1;
            //}
            //&:nth-child(1) {
            //    order: 2;
            //}
            &__card {
                padding-top: 100% * 0.654;
                box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.3);
                @include bp(768px down) {
                    padding-top: 50%;
                }
                .full-width {
                    h5 {
                        @include bp(768px down) {
                            width: 100%;
                            padding: 0.8rem rem(3);
                        }
                        width: 50%;
                    }
                }
            }
        }
    }
}
