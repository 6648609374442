@import "styles/vendor";

.Footer {
    background: #000;
    color: black;
    position: relative;
    & > .container {
        background: black url(../../assets/red-diamonds-footer.png);
        background-size: contain;
        background-position: 0 90%;
        background-repeat: no-repeat;
    }
    a {
        &:hover {
            font-weight: 500;
        }
    }
    .mobile-video {
        margin-top: 1rem;
        display: none;
    }
    .media-block .activity-wrapper {
        display: none;
    }
    &__top {
        @include bp(768px down) {
            padding-top: 15px;
        }
        @include bp(sm up) {
            padding-top: 30px;
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
        &_right {
            display: flex;
            width: 60%;
            align-items: center;
            @include bp(768px down) {
                display: none;
            }
            @include bp(sm up) {
                width: 60%;
            }
            .Footer__subscription {
                width: 40%;
            }
            .Footer__options {
                width: 40%;
                display: flex;
                justify-content: space-around;
            }
            .subscription {
                border: 1px solid #fff;
                max-width: 100%;
                width: 100%;
                span {
                    @include bp(lg down) {
                        font-size: .75rem !important;
                    }
                    @include bp(md down) {
                        font-size: .55rem !important;
                    }
                }
                & > a {
                    @include bp(768px down) {
                        height: 25px !important;
                        line-height: 25px !important;
                    }
                }
                &:hover {
                    span {
                        color: #fff !important;
                    }
                    background-color: black !important;
                }
                &:active {
                    span {
                        color: #fff !important;
                    }
                    background-color: $yellow !important;
                    border: 1px solid $yellow;
                }
                span {
                    white-space: nowrap;
                    @include bp(768px down) {
                        font-size: 14px!important;
                    }
                    @include bp(sm up) {
                        font-size: 16px;
                    }
                }
            }
        }
    }
    &__logo {
        @include bp(sm up) {
            padding-left: 1rem;
            width: auto;
        }
        img {
            @include bp(sm up) {
                height: 50px;
            }
            @include bp(lg up) {
                height: 67px;
            }
            height: 35px;
        }
    }
    &__options {
        font-size: 0.9375rem;
        width: 45%;
        @include bp(768px down) {
            margin-left: 10px;
        }
        @include bp(sm up) {
            font-size: 1.25rem;
            width: auto;
        }
        @include bp(lg up) {
            font-size: 1.5rem;
        }
        a {
            transition: all .22s;
            &:hover {
                color: $light-gray;
                i {
                    transform: scale(1.2);
                    transition: .3s;
                }
            }
            &:active {
                color: $yellow;
            }
        }
    }
    &__middle {
        padding-bottom: 50px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        @include bp(sm up) {
            margin: 25px auto 0;
        }
    }
    &__video {
        display: none;
        @include bp(sm up) {
            display: block;
            width: 42%;
            padding-right: 3rem;
        }
        @include bp(1200px up) {
            padding-right: 7rem;
        }
    }
    &__play {
        display: block;
        color: white;
        position: relative;
        transition: all .22s;
        &::after {
            content: '\f04b';
            font-family: 'FontAwesome';
            font-size: 2.8125rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            @include bp(lg up) {
                font-size: 4.0625rem;
            }
        }
        &:hover {
            color: #dd4b39;
        }
    }
    &__poster {
        max-width: none;
        width: 100%;
        display: block;
    }
    &__bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 25px;
        font-size: 0.9375rem;
        color: #676767;
        padding-top: 20px;
        @include bp(sm up) {
            padding-top: 50px;
            background-position: center center;
        }
    }
    &__links {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        a {
            text-decoration: none;
            margin-right: 5px;
            font-size: 0.75rem;
            transition: all .22s;
            @include bp(sm up) {
                margin-right: 20px;
                font-size: 0.8rem;
            }
            &:hover {
                color: $light-gray;
            }
        }
        .andSpan {
            margin-right: 5px;
            font-size: 0.8rem;
            margin-left: -15px;
        }
        .manage_privacy_preferences {
            margin-right: 10px;
            font-size: 0.8rem;
            margin-left: 5px;
            cursor: pointer;

            &:hover {
                color: $light-gray;
            }
        }
    }
    &__copyright {
        font-size: 0.75rem;
        @include bp(ss up) {
            margin-bottom: 0;
        }
        @include bp(sm up) {
            font-size: 0.8rem;
            margin-bottom: 1rem;
        }
    }
}

.Footer__home {
    @extend .Footer;
    background-size: 100%;
    color: white;
    &__options a:hover {
        color: $light-gray;
    }
    &__play:hover {
        color: #ffd210;
        transition: .3s;
        transition-timing-function: ease-in-out;
    }
    a:active {
        color: $yellow;
    }
    @include bp(768px down) {
        background: black;
    }
}


