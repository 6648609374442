/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import url('https://fonts.googleapis.com/css?family=Cinzel:400,700');
@import '../vendor';
@import '../components/general/header';
@import '../components/profile/splash';
@import '../components/profile/profile';
@import '../components/profile/review';

.fa-vertical-line:after {
    content: "\2759"
};

h1, h2, h3, h4, h5, h6 {
    color: unset;
}

.closeCss {
    position: absolute;
    right: 10px;
    top: 8px;
    width: 22px;
    height: 22px;
    opacity: 0.3;
    cursor: pointer;
    &:hover {
        opacity: 1;
    }
    &:before, &:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 17px;
        width: 2px;
        background-color: #333;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
}

.style-item-no-select {
    border: 1px solid #000;
    border-radius: 5rem;
    padding: .2rem 1rem;
    font-size: .8rem;
    margin: .5rem .5rem 0 0;
}

.info-popup-window {
    position: relative;
    text-align: justify;
    i {
        line-height: 22px;
        &:first-child {
            font-size: 22.4px;
        }
        &:nth-child(2) {
            font-size: 11.2px;
        }
    }
    &:hover{
        i {
            &:before {
                color: $yellow;
            }
        }
        &:after {
            content: attr(data-title);
            position: absolute;
            left: 5px;
            bottom: 30px;
            z-index: 1;
            color: black;
            background-color: #fff;
            border: 1px solid $gray;
            padding: 10px 20px;
            width: 300px;
            font-size: 12px;
            line-height: 22px;
        }
    }
}

.faq-section {
    display: flex;
    justify-content: center;
    width: 100%;
    .loader-container {
        width: 100%;
    }
    & > div {
        width: 50%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
}

.faq-component {
    min-width: 800px;
}

.infinite-scroll-main {
    display: flex;
    justify-content: center;
    .loader-container {
        width: 100%;
    }
    & > div {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        .infinite-scroll-component {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .infinite-map {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                @include bp(768px down) {
                    max-width: none;
                    padding: 0;
                }
                .width-items {
                    width: 100%;
                    flex-wrap: wrap;
                    display: flex;
                    @include bp(xs down) {
                        padding: 0 1rem;
                        flex-direction: column;
                        align-items: center;
                    }
                    @include bp(xs up) {
                        margin: 0 -0.5%;
                    }
                    .ProfileCard {
                        @include bp(xs down){
                            @include adaptive-height-element(1);
                        }
                        @include bp(xs up){
                            @include adaptive-height-element(2);
                        }
                        @include bp(sm up){
                            @include adaptive-height-element(3);
                        }
                        @include bp(md up){
                            @include adaptive-height-element(4);
                        }
                        @include bp(lg up){
                            @include adaptive-height-element(4);
                        }
                    }
                }
                .search-width-items {
                    width: 100%;
                    flex-wrap: wrap;
                    display: flex;
                    @include bp(xs down) {
                        padding: 0 1rem;
                        flex-direction: column;
                        align-items: center;
                    }
                    @include bp(xs up) {
                        margin: 0 0.5%;
                    }
                    .ProfileCard {
                        @include bp(xs down){
                            @include adaptive-height-element(1);
                        }
                        @include bp(xs up){
                            @include adaptive-height-element(2);
                        }
                        @include bp(sm up){
                            @include adaptive-height-element(3);
                        }
                        @include bp(md up){
                            @include adaptive-height-element(4);
                        }
                        @include bp(lg up){
                            @include adaptive-height-element(4);
                        }
                    }
                }
            }
            .ProfileCard {
                @include bp(768px down) {
                    margin-right: 0;
                    margin-bottom: 0;
                }
            }
            footer {
                width: 100%;
            }
        }
    }
}

footer {
    width: 100%;
}

input {
    &::-webkit-input-placeholder {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &::-moz-placeholder {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &:-ms-input-placeholder {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &:-moz-placeholder {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.site-header__notifications, .site-header__menu, .button, .button-icon, .videos__play, .musics__play {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    font-family: "Montserrat", sans-serif;
    background: transparent;
    outline: none;
    border-radius: 0;
    box-shadow: none;
    border: none;
    cursor: pointer;
    padding: 0;
    color: inherit;
    display: inline-block;
    text-decoration: none;
}

.photos::before, .videos::before, .musics::before, .photos::after, .videos::after, .musics::after {
    content: " ";
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 2;
}

.photos::before, .videos::before, .musics::before {
    background: linear-gradient(to left, rgba(243, 243, 243, 0), #f3f3f3 25%);
    width: calc(100vw - (1356px * .7337925446));
    right: 100%;
}

.photos::after, .videos::after, .musics::after {
    background: linear-gradient(to right, rgba(243, 243, 243, 0), #f3f3f3);
    width: calc((100vw - 1356px) / 2 + 60px);
    left: 100%;
}

html {
    box-sizing: border-box;
}

*, *::before, *::after {
    box-sizing: inherit;
}

::-moz-selection {
    background: #dd4b39;
    color: white;
    text-shadow: none;
}

::selection {
    background: #dd4b39;
    color: white;
    text-shadow: none;
}

html,
body {
    //height: 100%;
    width: 100%;
    padding-right: 0 !important;
}

img {
    max-width: 100%;
}

body {
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
    color: black;
    line-height: 1.4;
}

a {
    color: inherit;
    text-decoration: none;
    transition: .22s;

    &.default-color {
        color: #007bff;
    }
}

.visuallyhidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}
.container {
    width: 100%;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    @include bp(1200px up) {
        max-width: 1300px;
    }
    @include bp(1440px up) {
        padding: 0 60px;
        max-width: 1500px;
    }
    @media (max-width: $screen-sm) {
        width: 100%;
        max-width: 100%;
    }
}

.container--flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.container--end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

@media (min-width: 70em) {
    .container--full {
        max-width: none;
    }
}

.articles-container {
    max-width: 1900px !important;
}

.detail {
    background: #f3f3f3;
    //padding: 25px;
    // .profile {
    //     @include bp(768px down){
    //         z-index: 2;
    //     }
    // }
    // .artist-profile-content {
    //     @include bp(768px down){
    //         z-index: 1;
    //     }
    // }
    @include bp(768px down){
        margin-top: 4.3rem;
        background: #f3f3f3;
        //margin-bottom: 2rem;
        // z-index: 2;
    }
}

.wrapper {
    overflow: hidden;
    min-height: 100vh;
    position: relative;
}
.dropdown{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    &-item {
        text-align: right;
        font-weight: 300;
        &:hover {
            color: $yellow;
            background: none;
            font-weight: 400;
        }
    }
}

//<editor-fold desc='button'>
button {
    transition: .22s;
    &:disabled {
        cursor: not-allowed;
    }
}
.button {
    display: inline-block;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    font-weight: 600;
    padding: 5px;
    border: 1px solid white;
    border-radius: 2px;
    transition: all .22s;
}

@media (min-width: 37.5em) {
    .button {
        padding: 12px 30px;
    }
}

.button i:last-child {
    margin-left: 10px;
}

.button i:first-child {
    margin-left: 0;
    margin-right: 10px;
}

.button--default {
    border-color: black;
    background: white;
    color: black;
    border-radius: 0;
    height: 3rem;
    @include tabletFontSize(1.7rem);
    @include phoneFontSize(.9rem);
    @include bp(768px down){
        margin: 0px !important;
        padding: 2rem;
        height: initial !important;
    }
}

.button--default:hover {
    background: black;
    color: white;
}

.button--transparent {
    color: white;
}

.button--transparent:hover {
    background: white;
    color: black;
}

.button--action {
    padding: 18px 30px;
    border-color: #d5d5d5;
}

.button--action:hover {
    background: #d5d5d5;
}

.button--filled {
    padding: 18px 30px;
    border-color: #d5d5d5;
    background: #d5d5d5;
}

.button--filled:hover {
    background: white;
}

.button--large {
    padding: 10px 50px;
}

@media (min-width: 48em) {
    .button--large {
        padding: 15px 50px;
    }
}

@media (min-width: 70em) {
    .button--large {
        padding: 18px 75px;
    }
}

.button--alert {
    font-weight: 700;
    font-size: 0.75rem;
    color: white;
    background: $green;
    border-color: $green;
    border-radius: 0;
    height: 3rem;
    @include tabletFontSize(1.7rem);
    @include phoneFontSize(.9rem);
    @include bp(768px down){
        padding: 2rem 30px;
        height: inherit;
    }
}

.button--alert:hover {
    background: white;
    color: $green;
}

.button--active {
    font-size: 0.6875rem;
    padding: 8px 15px;
    border-color: #ffd210;
}

.button--active i {
    color: #ffd210;
    transition: .22s;
}

.button--active:hover {
    background: #ffd210;
}

.button--active:hover i {
    color: black;
}

.button-icon {
    transition: .22s;
}

.button-icon:hover {
    color: #ffd210;
}
//</editor-fold>


.nav {
    overflow: hidden;
    max-height: 0;
    justify-content: flex-end;
    width: 100%;
    text-align: right;
    transition: all .22s;
}

.nav--open {
    display: flex;
    max-height: 1000px;
    padding: 20px 0;
}

.nav__item {
    margin-bottom: 10px;
}

.nav__link {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: normal;
    color: rgba(255, 255, 255, 0.5);
    padding-bottom: 5px;
    text-decoration: none;
    position: relative;
}

.nav__link:hover {
    color: #ffd210;
}

.nav__link:hover::after {
    background: #ffd210;
    opacity: 1;
    transform: scale(1) translateX(-50%);
}

.nav__link::after {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 1px;
    width: 100%;
    background: rgba(255, 255, 255, 0.5);
    transform: scale(0, 1) translateX(-50%);
    opacity: 0;
    transition: all .22s;
}

.actions {
    position: absolute;
    bottom: 0;
    left: 0;
    width: -webkit-calc(100% - 40px);
    width: calc(100% - 40px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
}

.actions__container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.actions__item {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
}

.actions__item:first-of-type {
    margin-left: 0;
}

.actions__item:last-of-type {
    margin-right: 0;
}

.actions__item i {
    font-size: 1.5625rem;
    margin-top: 5px;
}

.actions__item a:hover {
    color: #e4222e;
}

.actions__count {
    font-size: 0.6875rem;
}

.all.all {
    background: black url(../../assets/images/all-in-all-win.png) no-repeat center top/50% auto;
    padding-top: 100px;
    color: white;
    position: relative;
    margin-top: 50px;
    & > div:first-of-type {
        display: flex;
        flex-wrap: wrap;
        @include bp(768px down) {
            justify-content: center;
        }
    }
    @include bp(768px down){
        padding-top: 130px;
    }
    @include bp(520px down){
        padding-top: 3rem;
    }
}

@media (min-width: 48em) {
    .all.all {
        padding-top: 240px;
        margin-top: 75px;
        background: black url(../../assets/images/all-in-all-win.png) no-repeat center top/80% auto;
    }
}

@media (min-width: 70em) {
    .all.all {
        padding-top: 320px;
        margin-top: 100px;
    }
}

.all.all .container {
    padding-bottom: 150px;
}

.all.all .container::after {
    clear: both;
    content: "";
    display: table;
}

.all__title {
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 2.5rem;
    line-height: normal;
    margin-bottom: 90px;
    @include bp(768px down){
        margin-bottom: 8rem;
    }
    @include tabletFontSize(4.5rem);
    @include phoneFontSize(2rem);
}

@media (min-width: 48em) {
    .all__title {
        font-size: 3.75rem;
        margin-bottom: 140px;
    }
}

@media (min-width: 70em) {
    .all__title {
        font-size: 5rem;
        margin-bottom: 190px;
    }
}

.all-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    z-index: 2;
    position: relative;
    @include bp(sm up) {
        float: left;
        width: 50%;
        &:nth-of-type(even) {
            transform: translateY(80px);
        }
    }
}

@media (min-width: 70em) {
    .all-item:nth-of-type(even) {
        transform: translateY(150px);
    }
}

.all-item--artists {
    background: no-repeat center center/cover;
}

.all-item--managers {
    background: no-repeat center center/cover;
}

.all-item--venues {
    background: no-repeat center center/cover;
}

.all-item--production {
    background: no-repeat center center/cover;
}

.all-item__content {
    width: 90%;
    padding: 0 20px;
}

@media (min-width: 48em) {
    .all-item__content {
        padding: 0 30px;
    }
}

.all-item__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.all-item__logo {
    height: 26px;
    margin-right: 5px;
}

@media (min-width: 48em) {
    .all-item__logo {
        height: 38px;
        margin-right: 15px;
    }
}

@media (min-width: 70em) {
    .all-item__logo {
        height: 50px;
        margin-right: 20px;
    }
}

.all-item__title {
    font-size: 0.875rem;
    color: inherit;
    line-height: normal;
}

@media (min-width: 48em) {
    .all-item__title {
        font-size: 1em;
    }
}

@media (min-width: 70em) {
    .all-item__title {
        font-size: 1.5rem;
    }
}

.all-item__subtitle {
    margin: .5em 0 1em;
    font-family: "Cinzel", serif;
    font-size: 1.25rem;
    color: inherit;
    line-height: normal;
}

@media (min-width: 48em) {
    .all-item__subtitle {
        font-size: 1.4rem;
    }
}

@media (min-width: 70em) {
    .all-item__subtitle {
        font-size: 2rem;
    }
}

.all-item__copy {
    margin-bottom: 1em;
}

@media (min-width: 70em) {
    .all-item__copy {
        margin-bottom: 2em;
    }
}

/* Use only in ProfileSideBar.js */
.band-members {
    padding: 0 20px 20px;
    text-align: left;
    border-bottom: 1px solid #e1e1e1;
}

.band-members__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0 10px;
}

.band-members__title {
    font-size: 0.875rem;
    font-weight: $font-semi-bold;
    text-transform: capitalize;
}

.band-members__content {
    a {
        color: #000 !important;
    }
    .member-wrapper {
        display: flex;
        flex-direction: row;
        min-height: 70px;
        align-items: center;
        padding-right: 1.5rem;
        position: relative;
        .member-avatar {
            display: block;
            border-radius: 50%;
            overflow: hidden;
            height: 100%;
            &-wrapper {
                width: rem(25); //15%;
                height: rem(25); // 60%;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                flex: none;
                margin-left: rem(10);
                margin-right: rem(20);
            }
        }
        .member-avatar-wrapper::after {
            content: " ";
            height: rem(55); //200%;
            width: rem(55); //200%;
            background: url(../../assets/black-avatar-contur.svg) no-repeat center top/75% auto;
            position: absolute;
            top: 25%;
            left: 50%;
            transform: translate(-50%, -25%);
        }
        .member-avatar img {
            display: block;
            height: 100%;
            width: 100%;
            object-fit: cover;
            -webkit-backface-visibility: hidden;
        }
        .member-name {
            font-size: 0.875rem;
            font-weight: $font-semi-bold;
        }
        .member-description {
            font-size: 0.6rem;
            font-weight: $font-light;
        }
        .member-like {
            font-size: 1.5rem;
            position: absolute;
            right: 0;
            top: 16%;
        }
    }
}

.business-info {
    padding: 0 20px 20px;
    text-align: left;
    border-bottom: 1px solid #e1e1e1;
}

.business-info__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0 10px;
}

.business-info__title {
    font-size: 0.875rem;
    font-weight: 500;
}

.business-info__content {
    text-overflow: ellipsis;
    word-break: break-all;
    overflow: hidden;
    font-size: 0.75rem;
    font-weight: 300;
    .item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

.about__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.about__title {
    font-size: 0.875rem;
    font-weight: bold;
    @include tabletFontSize(1.7rem);
    @include phoneFontSize(.9rem );
}

.about__link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.625rem;
    font-weight: 300;
    color: #a3a3a3;
    text-decoration: none;
    cursor: pointer;
}

.about__link:hover {
    color: #e4222e;
}

.about__link i {
    margin-left: 5px;
    font-size: 150%;
}

.viewed {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    @include bp(768px down){
        margin-left: 0;
        margin-right: 0;
    }
    padding-top: 25px; // remove this when enable .viewed__header
}

.viewed__header {
    width: 100%;
    text-align: left;
    padding: 25px;
    //display: flex;
    display: none;
    justify-content: space-around;
    align-items: center;
    @include bp(768px down){
        padding: 2rem;
    }
    p {
        margin-bottom: 0;
    }
}

.viewed__header i {
    font-size: 150%;
    @include tabletFontSize(3rem);
    @include phoneFontSize(2rem);

}

.viewed__copy {
    font-weight: 600;
    font-size: 0.6875rem;
    color: #a3a3a3;
    margin-left: 10px;
    @include tabletFontSize(1rem);
    @include phoneFontSize(.7rem);

}

.viewed__copy b {
    color: black;
}

.viewed__book {
    margin: 0 20px 10px;
    @include bp(768px down){
        margin: 0px;

    }
    width: inherit;
}

.viewed__fav {
    margin: 10px 20px 20px;
    position: relative;
}

.viewed__fav i {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
}

.photos {
    color: white;
    width: 100%;
    position: relative;
}

.photos .swiper-container {
    overflow: visible;
}

.photos__item {
    position: relative;
}

.photos__pic {
    width: 100%;
    display: block;
}

.photos__title {
    position: absolute;
    top: 0;
    left: 0;
    width: -webkit-calc(100% - 40px);
    width: calc(100% - 40px);
    padding: 25px 20px;
    font-size: 1.125rem;
}

.photos__title b {
    font-weight: 600;
}

.photos__title a {
    text-decoration: none;
}

.photos__title a:hover {
    color: #e4222e;
}

.videos {
    color: white;
    width: 100%;
    position: relative;
    margin-bottom: 15px;
}

.videos .swiper-container {
    overflow: visible;
}

.videos__content {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}

.videos__column {
    width: 32.24737714%;
}

.videos__column--bigger {
    width: 67.75262286%;
    width: calc(67.75262286% - 15px);
}

.videos__column--bigger .videos__item {
    height: 100%;
}

.videos__column--bigger .videos__title {
    top: auto;
    bottom: 80px;
    padding: 0 20px;
}

.videos__item {
    position: relative;
    margin-bottom: 15px;
}

.videos__item:last-of-type {
    margin-bottom: 0;
}

.videos__play {
    font-size: 3.125rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    transition: .22s;
}

.videos__play:hover {
    color: #dd4b39;
}

.videos__pic {
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: block;
}

.videos__title {
    position: absolute;
    top: 0;
    left: 0;
    width: -webkit-calc(100% - 40px);
    width: calc(100% - 40px);
    padding: 20px;
    font-size: 1.125rem;
}

.videos__title b {
    font-weight: 600;
}

.videos__title a {
    text-decoration: none;
}

.videos__title a:hover {
    color: #e4222e;
}

.musics {
    color: black;
    width: 100%;
    position: relative;
    margin-bottom: 15px;
}

.musics .swiper-container {
    overflow: visible;
}

.musics__item {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: stretch;
    width: 32.24737714%;
    height: auto;
    background: white;
}

.musics__item--bigger {
    width: 65.98564329%;
    flex-direction: row;
}

.musics__item--bigger .musics__column--pic {
    max-width: 32.9707113%;
    position: relative;
    top: auto;
    right: auto;
}

.musics__item--bigger .musics__pic {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.musics__item--bigger .musics__header {
    padding-right: 0;
}

.musics__column {
    flex-grow: 1;
    padding: 20px;
    position: relative;
}

.musics__column--pic {
    padding: 0;
    max-width: 38.83161512%;
    position: absolute;
    top: 0;
    right: 0;
}

.musics__pic {
    display: block;
}

.musics__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 45%;
}

.musics__play {
    font-size: 1.5625rem;
    margin-right: 25px;
    transition: .22s;
}

.musics__play:hover {
    color: #dd4b39;
}

.musics__title {
    font-weight: 500;
    font-size: 1.125rem;
}
.underline {
    width: 100%;
    border-bottom: 1px solid $gray;
}
