@import "../../vendor";

.business-info-form {
    box-sizing: content-box;
    margin: rem(148 / 2) rem(198 / 2);

    @include bp(sm down) {
        margin-left: rem(50 / 2);
        margin-right: rem(50 / 2);
        margin-top: rem(20);
    }

    text-align: left;
    font-size: calc(30rem / 32);
    font-weight: 300;
    &__content {
        margin: rem(60 / 4) 0;
    }
    &__capacity {
        display: flex;
        flex-direction: column;
    }
    &__item-header {
        @include informative-title;
    }
    &__checkbox{
        &-wrapper {
            display: flex;
            align-items: stretch;
            flex-wrap: wrap;
        }
        &-title {
            @include subtitle;
            color: #000;
            margin-bottom: rem((45 - (30 / 4)) / 2);
            font-weight: 500;
        }
    }
    &__container {
        width: percentage(1 / 3);

        @include bp(sm down) {
            width: 100%;
        }

        margin: rem(30 / 4) 0;
        &--full {
            width: auto;
            display: flex;
            align-items: center;
            margin: 0;
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            .number-input {
                position: relative;
            }
            .unit {
                position: absolute;
                top: 1rem;
                right: 0;
            }
        }
    }
    .operation-day {
        width: 180px;
    }
    .hour-wrapper {
        width: calc(100% - 180px);
        min-width: 290px;

        @include bp(520px down) {
            width: 100%;
            margin-top: -16px;   
        }

        .operation-hour {
            width: 120px;
            padding-right: 20px;
        }
    }
}
