@import '../../../styles/vendor.scss';

.add-member-popup {
    &-header {
        border-left: none;
        .fa.fa-plus {
            color: #ffd210;
            margin-left: -1.55rem;
            font-size: 32px;
            -webkit-text-stroke: 3px #fff;
        }
    }
    .add-member-popup-button {
        text-align: center;
        button {
            margin-top: 1rem;
            background: #ffd210;
            color: #fff;
            border: none;
            border-radius: 50px;
            padding: 1rem 1.5rem;
            i {
                margin-right: 0.5rem;
            }
            &:disabled {
                background: #fdfdfd;
                color: #ddd;
                border: 1px solid;
            }
        }
    }
    .add-member-popup-proposition {
        position: absolute;
        background: #fff;
        z-index: 2;
        width: 100%;
        margin-top: -13px;
        margin-left: 20px;
        &-element {
            display: flex;
            border: 1px solid #c4c4c4;
            padding: 25px;
            @include bp(520px down) {
                padding: 25px 15px;
            }
            .avatar {
                display: block;
                border-radius: 50%;
                overflow: hidden;
                height: 50px;
                margin-top: -1rem;
                margin-right: 1rem;
                width: 50px;
                border: 1px solid #c3c3c3;
                float: left;
                flex-shrink: 0;
            }
            p {
                display: inline;
                font-weight: 500;
                color: #000;
                margin-right: 10px;
            }
            span {
                color: #878787;
                margin-right: 10px;
                &.profession {
                    text-transform: uppercase;
                }
            }
            &.field {
                border: none;
                border-bottom: 2px solid #ffd210;
                padding-bottom: 5px;
                padding-left: 10px;
            }
            &.dropdown-field {
                border: none;
                border-bottom: 2px solid #ffd210;
                padding-bottom: 0px;
                padding-left: 10px;
                padding-top: 13px;
                p {
                    font-size: 1.5rem;
                    font-weight: 300;
                    margin-bottom: 0rem;
                }
                .close {
                    line-height: 1.5rem;
                }
            }

        }
        &-dropdown-element {
            padding: 10px;
            border-bottom: 1px solid #c4c4c4;
            width: 96.5%;
            p {
                display: inline;
                color: #000;
                font-weight: 500;
                margin-bottom: 0rem;
            }
        }
        &:first-child {
            border-bottom: none;
        }
        &.field {
            position: relative;
            margin-top: 0px;
            margin: 40px 0px -5px 20px;
        }
        &.dropdown {
            margin-left: 0px;
            margin-top: -8px;
            border: 1px solid #c4c4c4;
            border-width: 0px 1px 1px 1px;
        }
        &.dropdown-select-field {
            position: relative;
            background: #fff;
            line-height: 1.5rem;
            width: 100%;
            margin-top: initial;
            margin-left: initial;
        }
    }
}

.close {
    position: absolute;
    right: 0;
}
