@import '../vendor';

$transition-delay: .3s;
$grey: #a3a3a3;

.plans-and-talents {
    box-sizing: content-box;
    margin: rem(148 / 2) rem(198 / 2);
    text-align: center;
    font-size: calc(30rem / 32);
    font-weight: 300;
    &-form {
        &__item {
            &-header {
                @include informative-title;
            }
        }
    }
    .plans-selected-menu {
        width: 71.7%;
        .plans-selected-menu__item-wrapper {
            padding: rem(33) 0;
            margin-left: 0;
            margin-right: 0;
            display: flex;
            .plans-selected-menu__item {
                width: calc(100% * (1 / 3) - (16px + 1px) * 2);
                margin: 0.8125rem 16px;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: stretch;
                float: none;
                span {
                    display: inline-block;
                    width: rem(100);
                    border-radius: 50px;
                    padding: 0.9375rem 0;
                    border: 1px solid #000000;
                    cursor: pointer;
                }
                input[type="radio"]:checked + span {
                    background: #ffd210;
                    border: 1px solid #ffd210!important;
                    color: #fff;
                }
                &.top {
                    justify-content: flex-end;
                    .plans-item {
                        &__title {
                            margin-bottom: 14%;
                        }
                    }
                }
                &.bottom {
                    justify-content: flex-start;
                }
                .plans-item {
                    &__title {
                        color: $gray-hue-2;
                        font-size: rem(34 / 2);
                        font-weight: 500;
                    }
                    &__price {
                        color: $gray-hue-2;
                        font-size: rem(28 / 2);
                        font-weight: 300;
                        padding-bottom: rem(12);
                        border-bottom: 1px solid $gray;
                    }
                    &__description {
                        color: $gray-hue-2;
                        font-size: rem(20 / 2);
                        font-weight: 300;
                        padding: rem(12) 0;
                    }
                }
            }
            &.bottom {
                label {
                    margin-bottom: rem(10);
                }
            }
        }
        &-wrapper {
            display: flex;
            justify-content: flex-end;
        }
    }
}

.plans-selected-menu__item {
    .unchecked {
        background-color: #ffffff;
        cursor: default!important;
        color: $gray-hue-2!important;
        border: 1px solid $gray-hue-2!important;
    }
}
