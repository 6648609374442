@import "styles/vendor";
$className: SiteMap;

.#{$className} {
    width: 100%;
    @include bp(sm up) {
        width: 58%;
        padding-left: 3rem;
    }
    @include bp(1200px up) {
        padding-left: 7rem;
    }
    max-width: 900px;
    &__list {
        .mobile-subscribe {
            @include bp(768px down) {
                .subscription {
                    width: 100%;
                    & > a {
                        height: 25px !important;
                        line-height: 25px !important;
                    }
                    &:active {
                        span {
                            color: #fff !important;
                        }
                        background-color: $yellow !important;
                    }
                    span {
                        white-space: nowrap;
                        font-size: .7rem !important;
                    }
                }
            }
            @include bp(sm up) {
                display: none;
            }
        }
        display: flex;
        justify-content: space-between;
        list-style: none;
        padding: 0;
        @include bp(768px down) {
            .list-gigworks {
                order: 1;
            }
            .list-company {
                order: 3;
            }
            .list-learn-more {
                order: 2;
            }
            .mobile-subscribe {
                order: 4;
            }
            flex-wrap: wrap;
            .SiteMap__item {
                padding-bottom: 25px;
            }
        }
        @include bp(lg only) {
            justify-content: flex-start;
        }

        &_nested {
            display: block;
            min-width: 0;
            padding-left: 0;
            .#{$className}__item {
                width: 100%;
                margin-top: 20px;
                padding-bottom: 0;
            }
        }
    }
    &__item {
        font-size: 0.75rem;
        width: 50%;
        padding: 0 5px 0 0;
        &_title {
            color: #fff;
            font-weight: 600;
            font-size: .9rem;
        }
        @include bp(sm up) {
            font-size: 0.7rem;
            width: calc(100% / 3);
            //white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        @include bp(1200px up) {
            font-size: .7rem;
        }
    }
    &__link {
        color: #A6A6A6;
        text-decoration: none;
        display: inline-block;
        font-weight: 200;
        transition: all .22s;
        &:hover {
            color: $light-gray;
        }
        &:active {
            color: $yellow;
        }
    }
    &__options {
        margin-top: 40px;
        a {
            margin-right: 15px;
            i.fa {
                font-size: 20px;
            }
        }
    }
}
